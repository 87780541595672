import React, { Component } from "react";
import { Button, Col, Divider, Form, Input, Row, Tabs } from "antd";
import FloatLabel from "../FloatLabel";

import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";

import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.fr";

class FormSociete extends Component {
  state = {
    nomCourt: "",
    raisonSociale: "",
    telephone1: "",
    telephone2: "",
    mail1: "",
    siteWeb: "",
    zoneLibre1: "",
    zoneLibre2: "",
    zoneLibre3: "",
    zoneLibre4: "",
    zoneLibre5: "",
    adresse: "",
    numero: "",
    voie: "",
    complement: "",
    cp: "",
    ville: "",
    departement: "",
    region: "",
    pays: "",
  };

  // Référence du formulaire
  formRef = React.createRef();

  UNSAFE_componentWillReceiveProps(props) {
    props.societe ? this.initSociete(props.societe) : this.resetSociete();
  }

  initSociete = (data) => {
    this.resetSociete();

    const societe = data;
    const adresse = data.societe_adresse;

    this.formRef.current.setFieldsValue({
      societe_nom_court: societe.societe_nom_court,
      societe_raison_sociale: societe.societe_raison_sociale,
      societe_telephone: societe.societe_telephone,
      societe_telephone2: societe.societe_telephone2,
      societe_email: societe.societe_email,
      societe_site_web: societe.societe_site_web,
      societe_zone_libre_1: societe.societe_zone_libre_1,
      societe_zone_libre_2: societe.societe_zone_libre_2,
      societe_zone_libre_3: societe.societe_zone_libre_3,
      societe_zone_libre_4: societe.societe_zone_libre_4,
      societe_zone_libre_5: societe.societe_zone_libre_5,
      adresse_gmaps_adresse: adresse.adresse_gmaps_adresse,
      adresse_numero: adresse.adresse_numero,
      adresse_voie: adresse.adresse_voie,
      complement_adresse: "",
      adresse_code_postal: adresse.adresse_code_postal,
      adresse_ville: adresse.adresse_ville,
      adresse_departement: adresse.adresse_departement,
      adresse_region: adresse.adresse_region,
      adresse_pays: adresse.adresse_pays,
    });

    this.setState({
      nomCourt: societe.societe_nom_court,
      raisonSociale: societe.societe_raison_sociale,
      telephone1: societe.societe_telephone,
      telephone2: societe.societe_telephone2,
      mail1: societe.societe_email,
      siteWeb: societe.societe_site_web,
      zoneLibre1: societe.societe_zone_libre_1,
      zoneLibre2: societe.societe_zone_libre_2,
      zoneLibre3: societe.societe_zone_libre_3,
      zoneLibre4: societe.societe_zone_libre_4,
      zoneLibre5: societe.societe_zone_libre_5,
      adresse: adresse.adresse_gmaps_adresse,
      numero: adresse.adresse_numero,
      voie: adresse.adresse_voie,
      complement: "",
      cp: adresse.adresse_code_postal,
      ville: adresse.adresse_ville,
      departement: adresse.adresse_departement,
      region: adresse.adresse_region,
      pays: adresse.adresse_pays,
    });
  };

  resetSociete = () => {
    this.formRef.current.resetFields([
      "societe_nom_court",
      "societe_raison_sociale",
      "societe_telephone",
      "societe_telephone2",
      "societe_email",
      "societe_site_web",
      "societe_zone_libre_1",
      "societe_zone_libre_2",
      "societe_zone_libre_3",
      "societe_zone_libre_4",
      "societe_zone_libre_5",
      "adresse_gmaps_adresse",
      "adresse_numero",
      "adresse_voie",
      "complement_adresse",
      "adresse_code_postal",
      "adresse_ville",
      "adresse_departement",
      "adresse_region",
      "adresse_pays",
    ]);

    this.setState({
      nomCourt: "",
      raisonSociale: "",
      telephone1: "",
      telephone2: "",
      mail1: "",
      siteWeb: "",
      zoneLibre1: "",
      zoneLibre2: "",
      zoneLibre3: "",
      zoneLibre4: "",
      zoneLibre5: "",
      adresse: "",
      numero: "",
      voie: "",
      complement: "",
      cp: "",
      ville: "",
      departement: "",
      region: "",
      pays: "",
    });
  };

  onFinish = (data) => {
    data.societe_adresse_id = this.props.societe
      ? this.props.societe.societe_adresse.adresse_id
      : null;
    data.societe_beds24 = this.props.societe ? this.props.societe.societe_beds24 : null;
    this.props.societe
      ? this.props.handleEdit(
          data,
          this.props.societe.societe_id,
          this.props.societe.societe_num_client
        )
      : this.props.handleSubmit(data, this.props.numClient);
  };

  handleChangeAdresse = (adresse) => {
    this.setState({ adresse });
  };

  handleSelectAdresse = (adresse) => {
    this.formRef.current.setFieldsValue({
      adresse_gmaps_adresse: adresse,
      adresse_numero: "",
      adresse_voie: "",
      adresse_ville: "",
      adresse_code_postal: "",
      adresse_region: "",
      adresse_departement: "",
      adresse_pays: "",
    });
    geocodeByAddress(adresse).then((results) => {
      results[0].address_components.forEach((component, i) => {
        if (component.types[0] === "street_number") {
          this.setState({ numero: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_numero: component.long_name,
          });
        }
        if (component.types[0] === "route") {
          this.setState({ voie: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_voie: component.long_name,
          });
        }
        if (component.types[0] === "locality") {
          this.setState({ ville: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_ville: component.long_name,
          });
        }
        if (component.types[0] === "postal_code") {
          this.setState({ cp: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_code_postal: component.long_name,
          });
        }
        if (component.types[0] === "administrative_area_level_1") {
          this.setState({ region: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_region: component.long_name,
          });
        }
        if (component.types[0] === "administrative_area_level_2") {
          this.setState({ departement: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_departement: component.long_name,
          });
        }
        if (component.types[0] === "country") {
          this.setState({ pays: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_pays: component.long_name,
          });
        }
      });
    });
    this.setState({ adresse });
  };

  render() {
    const {
      nomCourt,
      raisonSociale,
      telephone1,
      telephone2,
      mail1,
      siteWeb,
      zoneLibre1,
      zoneLibre2,
      zoneLibre3,
      zoneLibre4,
      zoneLibre5,
      adresse,
      numero,
      voie,
      complement,
      cp,
      ville,
      departement,
      region,
      pays,
    } = this.state;

    return (
      <Form ref={this.formRef} onFinish={this.onFinish} size="large" className="form-requete">
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: "Coordonnées",
              key: "1",
              children: (
                <Row gutter={16}>
                  <Col span={12} style={{ padding: 20 }}>
                    <Divider orientation="left">Identification</Divider>
                    <Row gutter={8}>
                      <Col span={12}>
                        <FloatLabel label="Nom court" name="nom_court" value={nomCourt}>
                          <Form.Item name="societe_nom_court">
                            <Input
                              value={nomCourt}
                              onChange={(e) => this.setState({ nomCourt: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={12}>
                        <FloatLabel
                          label="Raison sociale"
                          name="raison_sociale"
                          value={raisonSociale}
                        >
                          <Form.Item name="societe_raison_sociale">
                            <Input
                              value={raisonSociale}
                              onChange={(e) => this.setState({ raisonSociale: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={6}>
                        <FloatLabel label="Téléphone 1" name="telephone1" value={telephone1}>
                          <Form.Item name="societe_telephone">
                            <Cleave
                              className="ant-input ant-input-lg"
                              value={telephone1}
                              options={{ phone: true, phoneRegionCode: "FR" }}
                              onChange={(e) => this.setState({ telephone1: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={6}>
                        <FloatLabel label="Téléphone 2" name="telephone2" value={telephone2}>
                          <Form.Item name="societe_telephone2">
                            <Cleave
                              className="ant-input ant-input-lg"
                              value={telephone2}
                              options={{ phone: true, phoneRegionCode: "FR" }}
                              onChange={(e) => this.setState({ telephone2: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={12}>
                        <FloatLabel label="Email" name="mail1" value={mail1}>
                          <Form.Item name="societe_email">
                            <Input
                              value={mail1}
                              onChange={(e) => this.setState({ mail1: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={24}>
                        <FloatLabel label="Site web" name="site_web" value={siteWeb}>
                          <Form.Item name="societe_site_web">
                            <Input
                              value={siteWeb}
                              onChange={(e) => this.setState({ siteWeb: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                    </Row>

                    <Divider orientation="left">Informations diverses</Divider>
                    <Row gutter={8} style={{ marginTop: 20 }}>
                      <Col span={24}>
                        <FloatLabel label="Zone libre 1" name="zone_libre_1" value={zoneLibre1}>
                          <Form.Item name="societe_zone_libre_1">
                            <Input
                              value={zoneLibre1}
                              onChange={(e) => this.setState({ zoneLibre1: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={24}>
                        <FloatLabel label="Zone libre 2" name="zone_libre_2" value={zoneLibre2}>
                          <Form.Item name="societe_zone_libre_2">
                            <Input
                              value={zoneLibre2}
                              onChange={(e) => this.setState({ zoneLibre2: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={24}>
                        <FloatLabel label="Zone libre 3" name="zone_libre_3" value={zoneLibre3}>
                          <Form.Item name="societe_zone_libre_3">
                            <Input
                              value={zoneLibre3}
                              onChange={(e) => this.setState({ zoneLibre3: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={24}>
                        <FloatLabel label="Zone libre 4" name="zone_libre_4" value={zoneLibre4}>
                          <Form.Item name="societe_zone_libre_4">
                            <Input
                              value={zoneLibre4}
                              onChange={(e) => this.setState({ zoneLibre4: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={24}>
                        <FloatLabel label="Zone libre 5" name="zone_libre_5" value={zoneLibre5}>
                          <Form.Item name="societe_zone_libre_5">
                            <Input
                              value={zoneLibre5}
                              onChange={(e) => this.setState({ zoneLibre5: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12} style={{ padding: 20 }}>
                    <Divider orientation="left">Adresse</Divider>
                    <Row gutter={8}>
                      <Col span={24}>
                        <PlacesAutocomplete
                          value={adresse}
                          onChange={this.handleChangeAdresse}
                          onSelect={this.handleSelectAdresse}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <FloatLabel
                                label="Recherche GOOGLE"
                                name="adresse_gmaps_adresse"
                                value={adresse}
                              >
                                <Form.Item name="adresse_gmaps_adresse">
                                  <Input
                                    {...getInputProps({
                                      className: "location-search-input",
                                    })}
                                  />
                                </Form.Item>
                              </FloatLabel>
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Chargement ...</div>}
                                {suggestions.map((suggestion, i) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                      key={i}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </Col>

                      <Col span={8}>
                        <FloatLabel label="N°" name="adresse_numero" value={numero}>
                          <Form.Item name="adresse_numero">
                            <Input
                              value={numero}
                              onChange={(e) => this.setState({ numero: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={16}>
                        <FloatLabel label="Libellé voie" name="adresse_voie" value={voie}>
                          <Form.Item name="adresse_voie">
                            <Input
                              value={voie}
                              onChange={(e) => this.setState({ voie: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={24}>
                        <FloatLabel
                          label="Complément adresse"
                          name="complement_adresse"
                          value={complement}
                        >
                          <Form.Item name="complement_adresse">
                            <Input
                              value={complement}
                              onChange={(e) => this.setState({ complement: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={8}>
                        <FloatLabel label="CP" name="adresse_code_postal" value={cp}>
                          <Form.Item name="adresse_code_postal">
                            <Input
                              value={cp}
                              onChange={(e) => this.setState({ cp: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={16}>
                        <FloatLabel label="Ville" name="adresse_ville" value={ville}>
                          <Form.Item name="adresse_ville">
                            <Input
                              value={ville}
                              onChange={(e) => this.setState({ ville: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={8}>
                        <FloatLabel
                          label="Département"
                          name="adresse_departement"
                          value={departement}
                        >
                          <Form.Item name="adresse_departement">
                            <Input
                              value={departement}
                              onChange={(e) => this.setState({ departement: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={8}>
                        <FloatLabel label="Région" name="adresse_region" value={region}>
                          <Form.Item name="adresse_region">
                            <Input
                              value={region}
                              onChange={(e) => this.setState({ region: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={8}>
                        <FloatLabel label="Pays" name="adresse_pays" value={pays}>
                          <Form.Item name="adresse_pays">
                            <Input
                              value={pays}
                              onChange={(e) => this.setState({ pays: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ),
            },
          ]}
        />
        <Row style={{ marginTop: 10, float: "right" }}>
          <Button type="primary" danger>
            Annulation
          </Button>
          <Button type="primary" htmlType="submit" style={{ marginLeft: 10 }}>
            Validation
          </Button>
        </Row>
      </Form>
    );
  }
}

export default FormSociete;
