import { Tag } from "antd";
import {
  CheckOutlined,
  ClearOutlined,
  CloseCircleOutlined,
  LoginOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { ARRIVEE_PREVUE } from "../../utils/constants";
import { ETAT_CLIENT } from "../../utils/constants";
import { ETAT_LOGEMENT } from "../../utils/constants";
import dayjs from "dayjs";

export const columns = () => [
  {
    title: "N°",
    dataIndex: "location_id",
    key: "location_id",
    sorter: (a, b) => parseInt(a.location_id) - parseInt(b.location_id),
    width: "5%",
  },
  {
    title: "Date Début",
    dataIndex: "location_date_debut",
    key: "location_date_debut",
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) => dayjs(a.location_date_debut).unix() - dayjs(b.location_date_debut).unix(),
    width: "8%",
  },
  {
    title: "Date Fin",
    dataIndex: "location_date_fin",
    key: "location_date_fin",
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) => dayjs(a.location_date_fin).unix() - dayjs(b.location_date_fin).unix(),
    width: "8%",
  },
  {
    title: "Arrivée prévue",
    dataIndex: "location_arrivee_tardive",
    key: "location_arrivee_tardive",
    render: (arriveeTardive) => {
      const item = ARRIVEE_PREVUE.find((i) => i.value === arriveeTardive);
      return (
        <span style={{ color: item?.color }}>
          {getIcon(item?.icon)} {item?.name}
        </span>
      );
    },
    editable: true,
    select: true,
    datas: ARRIVEE_PREVUE,
    width: "12%",
  },
  {
    title: "Client arrivé",
    dataIndex: "location_etat_client",
    key: "location_etat_client",
    render: (etatClient) => {
      const item = ETAT_CLIENT.find((item) => item.value === etatClient);
      return (
        <span style={{ color: item?.color }}>
          {getIcon(item?.icon)} {item?.name}
        </span>
      );
    },
    editable: true,
    select: true,
    datas: ETAT_CLIENT,
    width: "12%",
  },
  {
    title: "Lot",
    dataIndex: "location_lot",
    key: "location_lot",
    render: (lot) => <Tag key={lot.lot_reference}>{lot.lot_reference}</Tag>,
    width: "10%",
  },
  {
    title: "Locataire",
    dataIndex: "location_requete",
    key: "location_requete",
    render: (requete) =>
      requete && (
        <span>
          {`${requete.requete_locataire.locataire_personne.personne_nom} ${requete.requete_locataire.locataire_personne.personne_prenom}`}
          <br />
          {`${requete.requete_locataire.locataire_personne.personne_email1}`}
          <br />
          {`${requete.requete_locataire.locataire_personne.personne_tel1}`}
        </span>
      ),
    width: "12%",
  },
  {
    title: "État logement",
    dataIndex: "location_etat_logement",
    key: "location_etat_logement",
    render: (etatLogement) => {
      const item = ETAT_LOGEMENT.find((item) => item.value === etatLogement);
      return (
        <span style={{ color: item?.color }}>
          {getIcon(item?.icon)} {item?.name}
        </span>
      );
    },
    editable: true,
    select: true,
    datas: ETAT_LOGEMENT,
    width: "12%",
  },
];

const getIcon = (icon) => {
  switch (icon) {
    case "CheckOutlined":
      return <CheckOutlined />;
    case "CloseCircleOutlined":
      return <CloseCircleOutlined />;
    case "LoginOutlined":
      return <LoginOutlined />;
    case "LogoutOutlined":
      return <LogoutOutlined />;
    case "ClearOutlined":
      return <ClearOutlined />;
    default:
      return <></>;
  }
};
