import React, { Component } from "react";

import Constants from "../../../utils/constants";

import dayjs from "dayjs";
import { round } from "../../../utils/tools";

export default class ListeRedditionPDF extends Component {
  render() {
    const { utilisateur, dateButoir, lignesReddition } = this.props;

    const societe = utilisateur.utilisateur_etablissement.etablissement_societe;

    const chemin = `${Constants.mode}://${Constants.domain}:${Constants.port}/api/societe/display/${societe.societe_id}`;

    let width = 200;
    let height = 30;

    if (societe.societe_logo_width) width = societe.societe_logo_width;
    if (societe.societe_logo_height) height = societe.societe_logo_height;

    let totalSolde = 0;
    let totalHonoraires = 0;
    let totalTVA = 0;
    let total = 0;

    lignesReddition.forEach((ligne) => {
      let honorairesHT = round(ligne.montantFrais / 1.2, 2);
      let honorairesTVA = ligne.montantFrais - honorairesHT;

      totalSolde += ligne.solde;
      totalHonoraires += ligne.montantFrais;
      totalTVA += honorairesTVA;
      total += ligne.montantDu;
    });

    total = lignesReddition.reduce((a, b) => a + parseFloat(b.montantDu), 0);

    return (
      <div>
        {/* LOGO */}
        <div align="center">
          <img src={chemin} width={width} height={height} alt="" />
        </div>

        {/* TITRE DOCUMENT */}
        <div align="center" style={{ fontSize: 16 }}>
          <strong>{`LISTE PRÉPARATOIRE DES VIREMENTS DU ${
            dateButoir
              ? dayjs(dateButoir, "DD/MM/YYYY").format("DD/MM/YYYY")
              : dayjs().format("DD/MM/YYYY")
          }`}</strong>
        </div>

        {/* LIGNES QUITTANCES */}
        <table className="table-signature">
          <thead>
            <tr bgcolor="#afaeae">
              <th>
                <strong>Propriétaire</strong>
              </th>
              <th align="right">
                <strong>Solde comptable</strong>
              </th>
              <th align="right">
                <strong>Honoraires</strong>
              </th>
              <th align="right">
                <strong>TVA</strong>
              </th>
              <th align="right">
                <strong>À verser</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {lignesReddition
              .sort((a, b) => parseInt(a.proprietaire_code) - parseInt(b.proprietaire_code))
              .map((ligne, i) => {
                let ligneHonorairesHT = round(ligne.montantFrais / 1.2, 2);
                let ligneHonorairesTVA = ligne.montantFrais - ligneHonorairesHT;
                return (
                  <tr key={i} bgcolor={i % 2 === 0 ? "#ffffff" : "#eeeeee"}>
                    <td>{`${ligne.proprietaire_code} ${ligne.proprietaire_nom} ${ligne.proprietaire_prenom}`}</td>
                    <td align="right">
                      {parseFloat(ligne.solde).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </td>
                    <td align="right">
                      {parseFloat(ligne.montantFrais).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </td>
                    <td align="right">
                      {parseFloat(ligneHonorairesTVA).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </td>
                    <td align="right">
                      {parseFloat(ligne.montantDu).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </td>
                  </tr>
                );
              })}
          </tbody>
          <tfoot>
            <tr>
              <th>
                <strong>Totaux</strong>
              </th>
              <td align="right">
                {parseFloat(totalSolde).toLocaleString("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                })}
              </td>
              <td align="right">
                {parseFloat(totalHonoraires).toLocaleString("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                })}
              </td>
              <td align="right">
                {parseFloat(totalTVA).toLocaleString("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                })}
              </td>
              <td align="right">
                <strong>
                  {parseFloat(total).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}
