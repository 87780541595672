import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row, Table, Typography } from "antd";

import { CSVLink } from "react-csv";
// import { headers } from "./headers";
import { useReactToPrint } from "react-to-print";
import dayjs from "dayjs";

const { Title, Text } = Typography;

const ListSperone = (props) => {
  const [lignes, setLignes] = useState([]);
  const { listData, locations, quittances, reglements, tableOption } = props;

  const componentRef = useRef();

  useEffect(() => {
    listData && locations && quittances && reglements && init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData, locations, quittances, reglements]);

  const init = () => {
    setLignes(getData());
  };

  const getData = () => {
    return listData.map((data) => {
      const stats = calculateYearlyStats(data.lot_id);
      const proprietaire = stats.revenueP * (data.lot_frais_gestion_ct / 100);
      const honoraires = proprietaire + stats.revenueG;
      return {
        lot_id: data.lot_id,
        reference: data.lot_reference,
        proprietaire:
          data.lot_proprietaire &&
          `${data.lot_proprietaire.proprietaire_personne.personne_nom} ${data.lot_proprietaire.proprietaire_personne.personne_prenom}`,
        type_lot: data.lot_type_lot && `${data.lot_type_lot.type_lot_libelle}`,
        nuits_confiees: stats.trustedNights,
        nuits_louees: stats.rentedNights,
        occupation: stats.occupancyRate.toFixed(2),
        nbr_locations: stats.reservedCount,
        duree_moyenne: stats.averageReservedDuration.toFixed(2),
        adultes: stats.adultPercentage.toFixed(2),
        enfants_bebes: stats.childrenAndBabiesPercentage.toFixed(2),
        montant_total: stats.revenueP.toFixed(2),
        taux_honoraires: data.lot_frais_gestion_ct,
        montant_proprietaire: proprietaire.toFixed(2),
        montant_agence: stats.revenueG.toFixed(2),
        montant_honoraires: honoraires.toFixed(2),
      };
    });
  };

  const calculateYearlyStats = (id) => {
    const currentYear = dayjs().year();
    const startOfYear = dayjs(`${currentYear}-01-01`);
    const endOfYear = dayjs(`${currentYear}-12-31`);
    const totalDaysInYear = endOfYear.diff(startOfYear, "day") + 1;

    let rentedNights = 0;
    let ownerNights = 0;
    let reservedCount = 0;
    const reservedDurations = [];

    let totalAdults = 0;
    let totalChildrenAndBabies = 0;
    let totalPeople = 0;

    let revenueP = 0;
    let revenueG = 0;

    const statusOwner = ["b", "n"];
    const statusReserved = ["r"];
    const locationsLot = locations?.filter((location) => location.location_lot.lot_id === id);

    locationsLot.forEach(
      ({
        location_id,
        location_date_debut,
        location_date_fin,
        location_etat,
        location_nombre_adultes,
        location_nombre_enfants,
        location_nombre_bebes,
      }) => {
        let start = dayjs(location_date_debut);
        let end = dayjs(location_date_fin);

        if (start.isBefore(startOfYear) && end.isBefore(startOfYear)) {
          return;
        }
        if (start.isAfter(endOfYear) && end.isAfter(endOfYear)) {
          return;
        }

        if (start.year() < currentYear) start = startOfYear;
        if (end.year() > currentYear) end = endOfYear;

        if (start.isBefore(end) || start.isSame(end)) {
          const nights = end.diff(start, "day");

          if (statusReserved.includes(location_etat)) {
            reservedCount++;
            rentedNights += nights;
            reservedDurations.push(nights);

            totalAdults += location_nombre_adultes;
            totalChildrenAndBabies += location_nombre_enfants + location_nombre_bebes;
            totalPeople +=
              location_nombre_adultes + location_nombre_enfants + location_nombre_bebes;

            const revenues = getRevenue(location_id);

            revenueP += revenues.totalP;
            revenueG += revenues.totalG;
          }
          if (statusOwner.includes(location_etat)) ownerNights += nights;
        }
      }
    );

    const trustedNights = totalDaysInYear - ownerNights;
    const occupancyRate = (rentedNights / trustedNights) * 100;

    const totalReservedDuration = reservedDurations.reduce((sum, duration) => sum + duration, 0);
    const averageReservedDuration =
      reservedDurations.length === 0 ? 0 : totalReservedDuration / reservedDurations.length;

    const adultPercentage = totalPeople > 0 ? (totalAdults / totalPeople) * 100 : 0;
    const childrenAndBabiesPercentage =
      totalPeople > 0 ? (totalChildrenAndBabies / totalPeople) * 100 : 0;

    return {
      reservedCount,
      rentedNights,
      trustedNights,
      occupancyRate,
      averageReservedDuration,
      adultPercentage,
      childrenAndBabiesPercentage,
      revenueP,
      revenueG,
    };
  };

  const getRevenue = (id) => {
    const quittancesIds = quittances
      ?.filter((quittance) => quittance.quittance_location_id === id)
      .map((quittance) => quittance.quittance_id);

    const reglementsFiltered = reglements?.filter((reglement) =>
      quittancesIds.includes(reglement.reglement_quittance.quittance_id)
    );

    return reglementsFiltered.reduce(
      (totals, reglement) => {
        const { totalP, totalG } = reglement.reglement_ligne.reduce(
          (sums, ligne) => {
            const rubrique = ligne.reglement_ligne_quittance_ligne_id.quittance_ligne_rubrique;

            if (rubrique.rubrique_type === "P") {
              sums.totalP += parseFloat(ligne.reglement_ligne_montant);
            } else if (rubrique.rubrique_type === "G" && rubrique.rubrique_recapitulatif_ca) {
              sums.totalG += parseFloat(ligne.reglement_ligne_montant);
            }

            return sums;
          },
          { totalP: 0, totalG: 0 }
        );

        return {
          totalP: totals.totalP + parseFloat(totalP),
          totalG: totals.totalG + parseFloat(totalG),
        };
      },
      { totalP: 0, totalG: 0 }
    );
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div style={{ padding: 20 }}>
        <Row>
          <Title level={2}>Récapitulatif C.A. par Lots</Title>
        </Row>
        <Row>
          <Col offset={19} span={2}>
            <Text mark>{`Nombre de lot(s): ${listData ? listData.length : 0}`}</Text>
          </Col>
          <Col span={3}>
            <div style={{ float: "right" }}>
              <Button onClick={handlePrint} className="btn-primary">
                PDF
              </Button>
              <Button style={{ marginLeft: 10 }}>
                {lignes.length > 0 && (
                  <CSVLink
                    filename={"export.csv"}
                    data={lignes}
                    // headers={headers()}
                    separator={";"}
                  >
                    Export CSV
                  </CSVLink>
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div ref={componentRef}>
        <Table
          className="list-data"
          dataSource={lignes}
          columns={tableOption}
          pagination={false}
          rowKey="lot_id"
        />
      </div>
    </>
  );
};

export default ListSperone;
