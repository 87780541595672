import React from "react";

import { Button, Popconfirm, Tag, Tooltip } from "antd";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";

import dayjs from "dayjs";

export const columns = (lotsProps, onClickValidation, onClickSuppression) => [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    render: () => <></>,
    sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
    width: "5%",
  },
  {
    title: "Date début",
    dataIndex: "date_debut",
    key: "date_debut",
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) => dayjs(a.date_debut).unix() - dayjs(b.date_debut).unix(),
    width: "8%",
  },
  {
    title: "Date fin",
    dataIndex: "date_fin",
    key: "date_fin",
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) => dayjs(a.date_fin).unix() - dayjs(b.date_fin).unix(),
    width: "8%",
  },
  {
    title: "Nb. adulte(s)",
    dataIndex: "nb_adultes",
    key: "nb_adultes",
    width: "5%",
  },
  {
    title: "Nb. enfant(s)",
    dataIndex: "nb_enfants",
    key: "nb_enfants",
    width: "5%",
  },
  {
    title: "Nom",
    dataIndex: "nom",
    key: "nom",
    width: "8%",
  },
  {
    title: "Prénom",
    dataIndex: "prenom",
    key: "prenom",
    width: "8%",
  },
  {
    title: "Email",
    dataIndex: "mail",
    key: "mail",
    width: "8%",
  },
  {
    title: "Telephone",
    dataIndex: "telephone",
    key: "telephone",
    width: "8%",
  },
  {
    title: "Remarques",
    dataIndex: "remarques",
    key: "remarques",
    width: "10%",
  },
  {
    title: "Lot(s)",
    dataIndex: "lots",
    key: "lots",
    render: (lots) =>
      lots
        ?.split(";")
        .map((lot) => (
          <Tag key={lot}>{lotsProps?.find((e) => e.lot_id === parseInt(lot))?.lot_reference}</Tag>
        )),
    width: "10%",
  },
  {
    title: "Montant",
    dataIndex: "montant",
    key: "montant",
    render: (montant) =>
      parseFloat(montant).toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      }),
    width: "6%",
  },
  {
    title: "Actions",
    dataIndex: "id",
    key: "action",
    render: (id) => (
      <>
        <Tooltip title="Validation de la demande">
          <Button size="small" onClick={() => onClickValidation(id)}>
            <CheckOutlined />
          </Button>
        </Tooltip>
        <Popconfirm
          title="Êtes-vous sûr de vouloir supprimer cette demande ?"
          onConfirm={() => onClickSuppression(id)}
          cancelText="Annuler"
        >
          <Tooltip title="Suppression de la demande">
            <Button size="small" style={{ marginLeft: 10 }}>
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </Popconfirm>
      </>
    ),
    width: "7%",
  },
];
