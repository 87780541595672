export function comptesToAdapted(items) {
  return items
    ? items.map((item) => {
        return compteToAdapted(item);
      })
    : null;
}

export function compteToAdapted(item) {
  return item
    ? {
        compte_id: item.id,
        compte_compte_general: item.compte_general,
        compte_compte_auxiliaire: item.compte_auxiliaire,
        compte_libelle: item.libelle,
        compte_type_compte: item.type_compte,
        compte_loc_obligatoire: item.loc_obligatoire,
        compte_actif: item.actif,
        compte_honoraire: item.honoraire,
        compte_societe_id: item.societe_id,
      }
    : null;
}

export function adaptedToCompte(item) {
  return item
    ? {
        compte_general: item.compte_compte_general,
        compte_auxiliaire: item.compte_compte_auxiliaire,
        libelle: item.compte_libelle,
        type_compte: item.compte_type_compte,
        loc_obligatoire: item.compte_loc_obligatoire,
        actif: item.compte_actif,
        honoraire: item.compte_honoraire,
        societe_id: item.compte_societe_id,
      }
    : null;
}
