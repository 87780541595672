import React, { Component } from "react";

import { Row, Col, Form, Input, Select, Button, Switch, Checkbox, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import { DeleteOutlined } from "@ant-design/icons";

const { Option, OptGroup } = Select;
const { Column } = Table;

class FormRubrique extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rubriques: [],
      modalRubrique: false,
      comptesVisible: false,
    };
  }
  // Référence du formulaire
  formRef = React.createRef();

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    let rubriques = props.rubriques;
    const rubrique = props.rubrique;

    this.formRef.current.resetFields();

    if (rubrique !== null) {
      this.formRef.current.setFieldsValue({
        rubrique_code: rubrique.rubrique_code,
        rubrique_libelle: rubrique.rubrique_libelle,
        rubrique_type: rubrique.rubrique_type,
        rubrique_signe: rubrique.rubrique_signe,
        rubrique_recapitulatif_ca: rubrique.rubrique_recapitulatif_ca,
        rubrique_optionnelle: rubrique.rubrique_optionnelle,
        rubrique_optionnelle_montant: rubrique.rubrique_optionnelle_montant,
        rubrique_optionnelle_defaut: rubrique.rubrique_optionnelle_defaut,
        rubrique_montant_fixe: rubrique.rubrique_montant_fixe,
        rubrique_montant_defaut: rubrique.rubrique_montant_defaut,
        rubrique_pourcentage: rubrique.rubrique_pourcentage,
        rubrique_tva: rubrique.rubrique_tva,
        rubrique_taxe_de_sejour: rubrique.rubrique_taxe_de_sejour,
        rubrique_permanente: rubrique.rubrique_permanente,
        rubrique_compte_id: rubrique.rubrique_compte ? rubrique.rubrique_compte.compte_id : null,
        rubrique_contrat: rubrique.rubrique_contrat,
        rubrique_pourcentage_acompte: rubrique.rubrique_pourcentage_acompte,
        rubrique_honoraires: rubrique.rubrique_honoraires,
      });
      rubrique.rubrique_type === "G" && this.handleChangeType("G");

      if (rubrique.rubrique_type === "G") {
        this.setState({ comptesVisible: true });
      } else {
        this.setState({ comptesVisible: false });
      }

      rubriques = rubriques.filter((i) => i.rubrique_id !== rubrique.rubrique_id);
      props.rubrique.rubrique_liees.forEach((ligne) => {
        rubriques = rubriques.filter((i) => i.rubrique_id !== ligne.rubrique_id);
      });
    } else {
      this.formRef.current.setFieldsValue({
        rubrique_code: this.props.last,
      });
    }
    this.setState({ rubriques });
  };

  handleChangeType = (e) => {
    e === "P" && this.formRef.current.resetFields(["rubrique_compte_id"]);
    this.setState({
      comptesVisible: e === "G" ? true : false,
    });
  };

  onFinish = (data) => {
    this.props.rubrique === null
      ? this.props.handleSubmit(data)
      : this.props.handleEdit({ ...data, rubrique_ordre: this.props.rubrique.rubrique_ordre });
  };

  openModalRubrique = () => {
    this.setState({ selectedRows: [], modalRubrique: true });
  };

  closeModalRubrique = () => {
    this.setState({ selectedRows: [], modalRubrique: false });
  };

  handleSubmitAddRubriques = () => {
    const rubrique = this.props.rubrique;
    this.state.selectedRows.forEach((item) => {
      const ligne = {
        rubrique_pourcentage_principale_id: rubrique.rubrique_id,
        rubrique_pourcentage_liee_id: item.rubrique_id,
      };
      this.props.saveRubriquePourcentage(ligne, rubrique.rubrique_id);
    });

    this.closeModalRubrique();
  };

  deleteRubriquePourcentage = (id) => {
    const rubrique = this.props.rubrique;
    this.props.deleteRubriquePourcentage(rubrique.rubrique_id, id);
  };

  render() {
    const { rubrique, comptes } = this.props;
    const { rubriques, modalRubrique, comptesVisible } = this.state;

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRows });
      },
    };

    return (
      <div>
        <Modal
          footer={null}
          width="600px"
          title="Rubriques"
          open={modalRubrique}
          onCancel={this.closeModalRubrique}
        >
          <div>
            <Row>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                rowKey="rubrique_id"
                dataSource={rubriques}
              >
                <Column title="Code" dataIndex="rubrique_code" key="code" />
                <Column title="Libelle" dataIndex="rubrique_libelle" key="libelle" />
              </Table>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Button
                type="primary"
                onClick={this.handleSubmitAddRubriques}
                style={{ float: "right" }}
              >
                Valider
              </Button>
            </Row>
          </div>
        </Modal>
        <Form ref={this.formRef} onFinish={this.onFinish} style={{ padding: "20px" }}>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="rubrique_code"
                rules={[
                  {
                    required: true,
                    message: "Code obligatoire",
                  },
                ]}
              >
                <Input placeholder="Code" />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="rubrique_libelle"
                rules={[
                  {
                    required: true,
                    message: "Libellé obligatoire",
                  },
                ]}
              >
                <Input placeholder="Libellé" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={16}>
              <Form.Item name="rubrique_type">
                <Select placeholder="TYPE" optionLabelProp="label" onChange={this.handleChangeType}>
                  <OptGroup label="Type">
                    <Option value="P" label="Propriétaire (P)">
                      Propriétaire (P)
                    </Option>
                    <Option value="G" label="Gardée (G)">
                      Gardée (G)
                    </Option>
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="rubrique_signe">
                <Select placeholder="SIGNE" optionLabelProp="label">
                  <OptGroup label="Signe">
                    <Option value="+">+</Option>
                    <Option value="-">-</Option>
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {comptesVisible && (
            <Row gutter={12}>
              <Col span={16}>
                <Form.Item
                  name="rubrique_compte_id"
                  rules={[
                    {
                      required: true,
                      message: "Compte obligatoire",
                    },
                  ]}
                >
                  <Select optionLabelProp="label" placeholder="COMPTES">
                    <OptGroup label="Comptes">
                      {comptes &&
                        comptes
                          .filter((item) => item.compte_type_compte !== "A")
                          .sort((a, b) => a.compte_compte_general - b.compte_compte_general)
                          .map((item, i) => {
                            return (
                              <Option
                                key={item.compte_id}
                                value={item.compte_id}
                                label={item.compte_compte_general + " - " + item.compte_libelle}
                              >
                                <Row>
                                  <Col sm={8}>
                                    {item.compte_compte_general} - {item.compte_libelle}
                                  </Col>
                                </Row>
                              </Option>
                            );
                          })}
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="rubrique_recapitulatif_ca" valuePropName="checked">
                  <Checkbox>Rubrique sur récapitulatif C.A.</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={12}>
              <Form.Item name="rubrique_honoraires" valuePropName="checked">
                <Checkbox>Rubrique soumise à honoraires</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={6}>
              <Form.Item name="rubrique_optionnelle" valuePropName="checked">
                <Checkbox>Rubrique optionnel</Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="rubrique_optionnelle_montant">
                <Input type="number" placeholder="Montant" addonAfter="€" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="rubrique_optionnelle_defaut" valuePropName="checked">
                <Checkbox>Sélectionner par défaut</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={6}>
              <Form.Item name="rubrique_montant_fixe" valuePropName="checked">
                <Checkbox>Montant fixe</Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="rubrique_montant_defaut">
                <Input type="number" placeholder="Montant par défaut" addonAfter="€" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={6}>Pourcentage acompte</Col>
            <Col span={12}>
              <Form.Item name="rubrique_pourcentage_acompte">
                <Input type="number" placeholder="Pourcentage acompte" addonAfter="%" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6}>
              <Form.Item name="rubrique_permanente" valuePropName="checked">
                <Checkbox>Permanente</Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="rubrique_contrat" valuePropName="checked">
                <Checkbox>Contrat</Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="rubrique_tva" valuePropName="checked">
                <Switch checkedChildren="TVA" unCheckedChildren="TVA" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="rubrique_taxe_de_sejour" valuePropName="checked">
                <Switch checkedChildren="Taxe de séjour" unCheckedChildren="Taxe de séjour" />
              </Form.Item>
            </Col>
          </Row>
          {rubrique && (
            <>
              <Row style={{ marginTop: "10px" }}>
                <Button type="primary" onClick={this.openModalRubrique}>
                  Ajout Rubrique
                </Button>
              </Row>
              <Row gutter={12}>
                <Col span={14}>
                  <Table rowKey={(r) => r.rubrique_id} dataSource={rubrique.rubrique_liees}>
                    <Column title="Code" dataIndex="rubrique_code" key="code" />
                    <Column title="Libelle" dataIndex="rubrique_libelle" key="libelle" />
                    <Column
                      title="Supprimer"
                      dataIndex="rubrique_id"
                      key="rubrique_id"
                      render={(rubrique_id) => (
                        <Button onClick={() => this.deleteRubriquePourcentage(rubrique_id)}>
                          <DeleteOutlined />
                        </Button>
                      )}
                    />
                  </Table>
                </Col>
                <Col span={6}>
                  <Form.Item name="rubrique_pourcentage">
                    <Input type="number" placeholder="Pourcentage" addonAfter="%" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Row style={{ marginTop: "10px" }}>
            <Button type="primary" htmlType="submit" style={{ float: "right" }}>
              Valider
            </Button>
          </Row>
        </Form>
      </div>
    );
  }
}

export default FormRubrique;
