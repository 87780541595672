import React, { Component } from "react";
import MediaQuery from "react-responsive";
import PanelHautLarge from "../PanelHautLarge/index";

class HomeLarge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requete: null,
    };
  }

  componentDidMount() {
    this.syncRequete(this.props.requeteProps, this.props.tabKey);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.requeteProps !== this.props.requeteProps) {
      this.syncRequete(nextProps.requeteProps, nextProps.tabKey);
    }
  }

  syncRequete = (requete, key) => {
    this.setState({ requete });
    if (requete !== null) this.props.syncRequeteRouter(requete, key ? key : this.props.tabKey);
  };

  render() {
    const { requete } = this.state;

    return (
      <div className="parent">
        <MediaQuery minDeviceWidth={1224} device={{ deviceWidth: 1600 }}>
          <div className="wrapper">
            <PanelHautLarge
              requete={requete}
              syncRequete={this.syncRequete}
              addPanesPersonne={this.props.addPanesPersonne}
              addPanesLot={this.props.addPanesLot}
              addPanesReglement={this.props.addPanesReglement}
              addPanesQuittancement={this.props.addPanesQuittancement}
              addPanesSuppressionReglement={this.props.addPanesSuppressionReglement}
              tabContrat={this.props.tabContrat}
            />
          </div>
        </MediaQuery>
        <MediaQuery minResolution="2dppx"></MediaQuery>
      </div>
    );
  }
}

export default HomeLarge;
