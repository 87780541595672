import React, { useState, useEffect, useRef } from "react";
import { Button, Checkbox, Col, Divider, Form, Popconfirm, Row, Select, Spin } from "antd";
import { PlusOutlined, DeleteOutlined, UndoOutlined } from "@ant-design/icons";
import FormApptLarge from "../FormApptLarge";
import { removeAccents } from "../../utils/tools";

// import { getInfoLocataire, getInfoProprietaire } from "../../utils/tools";

const { Option, OptGroup } = Select;

const SelectLot = (props) => {
  const [lots, setLots] = useState(
    props.listData ? props.listData.filter((lot) => !lot.lot_fin) : null
  );
  const [lot, setLot] = useState(props.lotProps ? props.lotProps : null);
  const [nouveau, setNouveau] = useState(false);

  const {
    readOnly,
    utilisateur,
    societes,
    etablissements,
    parametre,
    typesLots,
    proprietaires,
    immeubles,
    equipements,
    champsPersosGroupes,
    champsPersos,
    langues,
    pieces,
    locations,
    quittances,
    reglements,
    rubriques,
    listData,
    locataires,
    qualites,
    documents,
    tags,
    groupes,
    provenances,
    parcoursClients,
  } = props;

  // Référence du formulaire
  const formRefLot = useRef();

  useEffect(() => {
    setLots(listData ? listData.filter((lot) => !lot.lot_fin) : null);
    lot && onSelectLot(lot.lot_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lot, listData]);

  function onClickAddLot() {
    setLot(null);
    setNouveau(true);
  }

  function enableLot(lot) {
    if (lot) {
      props.desarchiverLot(lot.lot_id);
    }
  }

  function disableLot(lot) {
    if (lot) {
      props.archiverLot(lot.lot_id);
    }
  }

  function onSelectLot(id) {
    formRefLot.current.setFieldsValue({ lot_id: id });
    const lot = props.listData?.find((e) => e.lot_id === id);
    if (lot) {
      if (!lot.lot_matrice) {
        Promise.all([
          props.saveMatrice(
            {
              matrice_lot_id: lot.lot_id,
            },
            lot
          ),
        ]).then(() => onSelectLot(id));
        return;
      }
      setLot(lot);
      setNouveau(false);
    }
  }

  const onChangeAll = (e) => {
    const lots = e.target.checked ? listData : listData.filter((lot) => !lot.lot_fin);
    setLots(lots);
  };

  const addLotButton = (
    <Button type="primary" htmlType="reset" onClick={onClickAddLot}>
      <PlusOutlined />
    </Button>
  );

  const archiverLot = (
    <Popconfirm
      title="Attention, êtes vous sur de vouloir archiver ce lot ?"
      onConfirm={() => disableLot(lot)}
    >
      <Button type="primary">
        <DeleteOutlined />
      </Button>
    </Popconfirm>
  );

  const desarchiverLot = (
    <Popconfirm
      title="Attention, êtes vous sur de vouloir désarchiver ce lot ?"
      onConfirm={() => enableLot(lot)}
    >
      <Button type="primary">
        <UndoOutlined />
      </Button>
    </Popconfirm>
  );

  return (
    <Spin spinning={false} size="large" tip="Chargement...">
      <div style={{ padding: 20 }}>
        <Form ref={formRefLot}>
          <Row>
            <Col span={11}>
              <Form.Item name="lot_id">
                <Select
                  showSearch
                  disabled={readOnly}
                  optionLabelProp="label"
                  placeholder={"Lot"}
                  onSelect={onSelectLot}
                  filterOption={(input, option) =>
                    removeAccents(option.label.toLowerCase()).indexOf(input.toLowerCase()) >= 0
                  }
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div style={{ padding: "8px", cursor: "pointer" }}>
                        <Checkbox onChange={onChangeAll}>Tous les lots</Checkbox>
                      </div>
                    </div>
                  )}
                >
                  <OptGroup label={"Lot"}>
                    {lots &&
                      lots.map((item, i) => {
                        return (
                          <Option
                            key={i}
                            value={item.lot_id}
                            label={`${item.lot_reference} - ${item.lot_designation} - ${
                              item.lot_adresse.adresse_gmaps_adresse
                            } (${item.lot_proprietaire.proprietaire_personne.personne_nom})${
                              item.lot_fin ? " (archivé)" : ""
                            }`}
                          >
                            <Row>
                              <Col sm={8}>{`${item.lot_reference} - ${item.lot_designation} - ${
                                item.lot_adresse.adresse_gmaps_adresse
                              } (${item.lot_proprietaire.proprietaire_personne.personne_nom})${
                                item.lot_fin ? " (archivé)" : ""
                              }`}</Col>
                            </Row>
                          </Option>
                        );
                      })}
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
            <Col span={1}>{!readOnly && <Form.Item>{addLotButton}</Form.Item>}</Col>
            {!readOnly && lot && !lot.lot_fin && (
              <Col span={1}>
                <Form.Item>{archiverLot}</Form.Item>
              </Col>
            )}
            {!readOnly && lot && lot.lot_fin && (
              <Col span={1}>
                <Form.Item>{desarchiverLot}</Form.Item>
              </Col>
            )}
          </Row>
        </Form>
        <FormApptLarge
          readOnly={readOnly}
          utilisateur={utilisateur}
          lot={lot}
          nouveau={nouveau}
          societes={societes}
          etablissements={etablissements}
          parametre={parametre}
          groupes={groupes}
          provenances={provenances}
          typesLots={typesLots}
          proprietaires={proprietaires}
          immeubles={immeubles}
          equipements={equipements}
          champsPersosGroupes={champsPersosGroupes}
          champsPersos={champsPersos}
          langues={langues}
          pieces={pieces}
          locations={locations}
          quittances={quittances}
          reglements={reglements}
          rubriques={rubriques}
          lots={listData}
          locataires={locataires}
          documents={documents}
          tags={tags}
          qualites={qualites}
          parcoursClients={parcoursClients}
          saveRenduA={props.saveRenduA}
          saveDocument={props.saveDocument}
          deleteDocument={props.deleteDocument}
          saveTag={props.saveTag}
          updateTag={props.updateTag}
          deleteTag={props.deleteTag}
          saveLocataire={props.saveLocataire}
          addPanesPersonne={props.addPanesPersonne}
          addPanesImmeuble={props.addPanesImmeuble}
          saveLot={props.saveLot}
          updateLot={props.updateLot}
          updateLotNuitees={props.updateLotNuitees}
          saveDescription={props.saveDescription}
          updateDescription={props.updateDescription}
          saveUpdateLotsChampsPersos={props.saveUpdateLotsChampsPersos}
          savePiece={props.savePiece}
          updatePiece={props.updatePiece}
          deletePiece={props.deletePiece}
          savePiecesContenus={props.savePiecesContenus}
          updatePiecesContenus={props.updatePiecesContenus}
          saveDossier={props.saveDossier}
          updateDossier={props.updateDossier}
          savePhoto={props.savePhoto}
          updatePhotos={props.updatePhotos}
          deletePhoto={props.deletePhoto}
          uploadICal={props.uploadICal}
          saveLocation={props.saveLocation}
          updateLocation={props.updateLocation}
          deleteLocation={props.deleteLocation}
          saveEquipement={props.saveEquipement}
          updateEquipement={props.updateEquipement}
          deleteEquipement={props.deleteEquipement}
          saveLotsEquipements={props.saveLotsEquipements}
          deleteLotsEquipements={props.deleteLotsEquipements}
          saveLigneMatrice={props.saveLigneMatrice}
          updateLigneMatrice={props.updateLigneMatrice}
          deleteLigneMatrice={props.deleteLigneMatrice}
          savePrixBase={props.savePrixBase}
          updatePrixBase={props.updatePrixBase}
          deletePrixBase={props.deletePrixBase}
          saveNuite={props.saveNuite}
          updateNuite={props.updateNuite}
          deleteNuite={props.deleteNuite}
          saveRequete={props.saveRequete}
          saveRequeteLot={props.saveRequeteLot}
          addPanesRequete={props.addPanesRequete}
          saveChampPerso={props.saveChampPerso}
          updateChampPerso={props.updateChampPerso}
          deleteChampPerso={props.deleteChampPerso}
          saveEmail={props.saveEmail}
          saveLotNote={props.saveLotNote}
          updateLotNote={props.updateLotNote}
          deleteLotNote={props.deleteLotNote}
        />
      </div>
    </Spin>
  );
};

export default SelectLot;
