import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { CSVLink } from "react-csv";
import { Modal, Switch } from "antd";

import { Button, Col, DatePicker, Form, Radio, Row, Space, Table, Typography, Select } from "antd";

import FloatLabel from "../FloatLabel";

import dayjs from "dayjs";

// import { useReactToPrint } from "react-to-print";

import MouvementsLocationPDF from "../Pdf/MouvementsLocationPDF";
import MouvementsRubriquePDF from "../Pdf/MouvementsRubriquePDF";

import { HttpMethod, initFetch } from "../../utils/fetcher";
import { openPdf } from "../../utils/utils";

const dateFormat = "DD/MM/YYYY";
const { Option, OptGroup } = Select;
const { Title, Text } = Typography;

const ListMouvements = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [locations, setLocationsFiltered] = useState(props.locations);
  const [dateDu, setDateDu] = useState(dayjs()); // today
  const [dateAu, setDateAu] = useState(dayjs().endOf("month")); // last day of month
  const [mouvement, setMouvement] = useState(3);
  const { tableOption } = props;
  // const [reglements, setReglements] = useState(props.reglements);
  const [lignesMouvements, setMouvements] = useState();
  const [lignesCSV, setCSV] = useState();
  // const [typesLots, setTypesLots] = useState(props.typesLots);
  // const [immeubles, setImmeubles] = useState(props.immeubles);
  // const [lignesFiltered, setFiltered] = useState();
  const [typePersonne, setTypePersonne] = useState();
  const [typeLot, setTypeLot] = useState();
  const [immeuble, setImmeuble] = useState();
  const [rubriques, setRubriques] = useState();
  // const [loading, setLoading] = useState();
  const [modalPdf, setModalPdf] = useState();
  const [idPdf, setIdPdf] = useState(0);
  const [avecRubriques, setAvecRubriques] = useState(false);

  // Référence du formulaire
  const formRef = useRef();

  const componentRef = useRef();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.locations,
    props.reglements,
    props.typesLots,
    props.immeubles,
    props.rubriques,
    dateDu,
    dateAu,
    mouvement,
    typePersonne,
    typeLot,
    immeuble,
    rubriques,
    avecRubriques,
  ]);

  const init = () => {
    formRef.current.setFieldsValue({
      date_du: dayjs(),
      date_au: dayjs().add(7, "days"),
      mouvement: 3,
    });
    setDateDu(dayjs());
    setDateAu(dayjs().add(7, "days"));
    applyFilter();
  };

  const applyFilter = () => {
    let filtered = [];

    if (dateDu && dateAu) {
      if (props.locations && mouvement === 1) {
        filtered = props.locations.filter((item) =>
          dayjs(item.location_date_debut, "YYYY-MM-DD").isBetween(
            dayjs(dateDu, "YYYY-MM-DD"),
            dayjs(dateAu, "YYYY-MM-DD"),
            "days",
            "[]"
          )
        );
      }

      if (props.locations && mouvement === 2) {
        filtered = props.locations.filter((item) =>
          dayjs(item.location_date_fin, "YYYY-MM-DD").isBetween(
            dayjs(dateDu, "YYYY-MM-DD"),
            dayjs(dateAu, "YYYY-MM-DD"),
            "days",
            "[]"
          )
        );
      }

      if (props.locations && mouvement === 3) {
        filtered = props.locations.filter(
          (item) =>
            dayjs(item.location_date_fin, "YYYY-MM-DD").isBetween(
              dayjs(dateDu, "YYYY-MM-DD"),
              dayjs(dateAu, "YYYY-MM-DD"),
              "days",
              "[]"
            ) ||
            dayjs(item.location_date_debut, "YYYY-MM-DD").isBetween(
              dayjs(dateDu, "YYYY-MM-DD"),
              dayjs(dateAu, "YYYY-MM-DD"),
              "days",
              "[]"
            )
        );
      }
    }

    filtered = filtered.filter((e) => e.location_etat === "r" || e.location_etat === "b");

    // TYPE PERSONNE
    if (parseInt(typePersonne) === 1) {
      filtered = filtered.filter((e) => e.location_etat === "r");
    }
    if (parseInt(typePersonne) === 2) {
      filtered = filtered.filter((e) => e.location_etat === "b");
    }
    // TYPE LOT
    if (typeLot) {
      filtered = filtered.filter((e) => e.location_lot.lot_type_lot_id === typeLot);
    }
    // IMMEUBLE
    if (immeuble) {
      filtered = filtered.filter((e) => e.location_lot.lot_immeuble_id === immeuble);
    }
    // RUBRIQUE
    if (rubriques && rubriques.length > 0) {
      filtered = filtered.filter(
        (location) =>
          location.location_etat === "r" &&
          props.quittances?.filter(
            (quittance) =>
              quittance.quittance_location_id === location.location_id &&
              quittance.quittance_ligne.some((ligne) =>
                rubriques.includes(ligne.quittance_ligne_rubrique.rubrique_id)
              )
          ).length > 0
      );
    }

    setLocationsFiltered(filtered);

    const rubriquesCSV = props.rubriques.filter((rubrique) =>
      filtered.some((location) =>
        location.location_ligne.some(
          (ligne) => ligne.location_ligne_rubrique.rubrique_id === rubrique.rubrique_id
        )
      )
    );

    if (props.locations && props.reglements && props.quittances) {
      let quittances = props.quittances;
      let reglements = props.reglements;

      const lignesMouvements = [];
      const lignesCSV = [];

      filtered.forEach((ligne) => {
        const quittancesFiltered = quittances.filter(
          (item) => item.quittance_location_id === ligne.location_id
        );
        const reglementsFiltered = reglements.filter(
          (item) => item.reglement_quittance.quittance_location_id === ligne.location_id
        );

        let quittancesLignes = [];
        if (quittancesFiltered.length > 0) {
          quittancesFiltered.forEach((quittance) => {
            quittance.quittance_ligne.forEach((ligne) => {
              quittancesLignes.push(ligne);
            });
          });
        }

        let reglementsLignes = [];
        if (reglementsFiltered.length > 0) {
          reglementsFiltered.forEach((reglement) => {
            reglement.reglement_ligne.forEach((ligne) => {
              reglementsLignes.push(ligne);
            });
          });
        }

        let total = 0;
        let regle = 0;
        let solde = 0;

        quittancesLignes.forEach((ligne) => {
          total += parseFloat(ligne.quittance_ligne_montant);
        });

        reglementsLignes.forEach((ligne) => {
          regle += parseFloat(ligne.reglement_ligne_montant);
        });

        solde = total - regle;

        const ligneMouvement = {
          location_id: ligne.location_id,
          location_etat: ligne.location_etat,
          location_date_debut: ligne.location_date_debut,
          location_date_fin: ligne.location_date_fin,
          location_requete: ligne.location_requete,
          location_lot: ligne.location_lot,
          location_ligne: ligne.location_ligne,
          quittance_ligne: quittancesLignes ? quittancesLignes : [],
          reglement_ligne: reglementsLignes ? reglementsLignes : [],
          solde: solde,
          location_nb_adultes: ligne.location_nb_adultes,
          location_nb_enfants: ligne.location_nb_enfants,
          location_nb_bebes: ligne.location_nb_bebes,
          location_desiderata: ligne.location_desiderata,
          location_heure_arrivee: ligne.location_heure_arrivee,
          location_heure_depart: ligne.location_heure_depart,
        };
        lignesMouvements.push(ligneMouvement);

        // let nbNuits = dayjs(ligne.location_date_fin).diff(dayjs(ligne.location_date_debut), "days");

        const ligneCSV = {
          location_id: ligne.location_id,
          locataire_nom: ligne.location_requete
            ? ligne.location_requete.requete_locataire.locataire_personne.personne_nom
            : "",
          locataire_prenom: ligne.location_requete
            ? ligne.location_requete.requete_locataire.locataire_personne.personne_prenom
            : "",
          locataire_mail_1: ligne.location_requete
            ? ligne.location_requete.requete_locataire.locataire_personne.personne_email1
            : "",
          locataire_telephone_1: ligne.location_requete
            ? ligne.location_requete.requete_locataire.locataire_personne.personne_tel1
            : "",
          locataire_mail_2: ligne.location_requete
            ? ligne.location_requete.requete_locataire.locataire_personne.personne_email2
            : "",
          locataire_telephone_2: ligne.location_requete
            ? ligne.location_requete.requete_locataire.locataire_personne.personne_tel2
            : "",
          requete_id: ligne.location_requete ? ligne.location_requete.requete_id : "",
          requete_source: ligne.location_requete
            ? ligne.location_requete.requete_provenance?.provenance_libelle
            : "",
          lot_reference: `=""${ligne.location_lot.lot_reference}""`,
          lot_designation: `=""${ligne.location_lot.lot_designation}""`,
          location_date_debut: ligne.location_date_debut,
          location_date_fin: ligne.location_date_fin,
          location_nb_adultes: ligne.location_nb_adultes,
          location_nb_enfants: ligne.location_nb_enfants,
          location_nb_bebes: ligne.location_nb_bebes,
          location_commentaire: convertToPlain(
            ligne.location_remarques ? ligne.location_remarques : ""
          ),
          location_desiderata: convertToPlain(
            ligne.location_desiderata ? ligne.location_desiderata : ""
          ),
          total: total.toFixed(2).replace(".", ","),
          acompte: regle.toFixed(2).replace(".", ","),
          solde: solde.toFixed(2).replace(".", ","),
        };

        avecRubriques &&
          rubriquesCSV.forEach((rubrique) => {
            ligneCSV[rubrique.rubrique_libelle.toLowerCase().replace(/\s/g, "_")] = getMontantLigne(
              ligne.location_ligne,
              rubrique.rubrique_id
            );
          });

        lignesCSV.push(ligneCSV);
      });

      lignesCSV.sort((a, b) => a.locataire_nom.localeCompare(b.locataire_nom));

      setMouvements(lignesMouvements);
      setCSV(lignesCSV);
    }
  };

  const getMontantLigne = (lignes, id) => {
    const ligne = lignes.find((ligne) => ligne.location_ligne_rubrique.rubrique_id === id);
    return ligne ? parseFloat(ligne.location_ligne_montant).toFixed(2).replace(".", ",") : "";
  };

  const convertToPlain = (rtf) => {
    // rtf = rtf.replace(/\\par[d]?/g, "");
    // return rtf.replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "").trim();
    let txt = rtf.replace(/<br ?\/?>/g, " ");
    return txt.replace(/(<([^>]+)>)/gi, "");
  };

  const onChangeAvecRubriques = (value) => {
    setAvecRubriques(value);
  };

  const onChangeDateDu = (dateDu) => {
    setDateDu(dateDu);
  };

  const onChangeDateAu = (dateAu) => {
    setDateAu(dateAu);
  };

  const onChangeMouvement = (mouvement) => {
    setMouvement(mouvement);
  };

  const onChangeTypeLot = (typeLot) => {
    setTypeLot(typeLot);
  };

  const onChangeImmeuble = (immeuble) => {
    setImmeuble(immeuble);
  };

  const onChangeTypePersonne = (typePersonne) => {
    setTypePersonne(typePersonne);
  };

  const onChangeRubriques = (rubriques) => {
    setRubriques(rubriques);
  };

  const createPdfLocation = () => {
    const html = ReactDOMServer.renderToStaticMarkup(
      <MouvementsLocationPDF
        lignesMouvements={lignesMouvements.sort(
          (a, b) => dayjs(a.location_date_fin).unix() - dayjs(b.location_date_fin).unix()
        )}
        dateDu={dateDu}
        dateAu={dateAu}
        mouvement={mouvement}
      />
    );
    const pdf = {
      titre: "Mouvements",
      destinataire: "",
      pdf_page: [],
    };
    const pdfPage = {
      html: html,
    };
    pdf.pdf_page.push(pdfPage);
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then(function (result) {
        setIdPdf(result.pdf.id);
        setModalPdf(true);
      });
  };

  const createPdfRubrique = () => {
    const html = ReactDOMServer.renderToStaticMarkup(
      <MouvementsRubriquePDF
        lignesMouvements={lignesMouvements}
        dateDu={dateDu}
        dateAu={dateAu}
        mouvement={mouvement}
      />
    );
    const pdf = {
      titre: "Mouvements",
      destinataire: "",
      pdf_page: [],
    };
    const pdfPage = {
      html: html,
    };
    pdf.pdf_page.push(pdfPage);
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then(function (result) {
        setIdPdf(result.pdf.id);
        setModalPdf(true);
      });
  };

  const closeModal = () => {
    setModalPdf(false);
  };

  return (
    <div style={{ padding: 20 }}>
      <Modal footer={null} title="Edition" open={modalPdf} onCancel={closeModal}>
        {idPdf && (
          <Button type="text" onClick={() => openPdf(idPdf)}>
            Document PDF
          </Button>
        )}
      </Modal>
      <Form ref={formRef}>
        <Row>
          <Title level={2}>Entrées / Sorties</Title>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <FloatLabel label="Du" name="dateDu" value={dateDu}>
              <Form.Item name="date_du">
                <DatePicker
                  value={dateDu}
                  format={dateFormat}
                  placeholder=""
                  onChange={(e) => onChangeDateDu(e)}
                />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={6}>
            <FloatLabel label="Au" name="dateAu" value={dateAu}>
              <Form.Item name="date_au">
                <DatePicker
                  value={dateAu}
                  format={dateFormat}
                  placeholder=""
                  onChange={(e) => onChangeDateAu(e)}
                />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={12}>
            <Form.Item name="mouvement">
              <Radio.Group onChange={(e) => onChangeMouvement(e.target.value)} value={mouvement}>
                <Space direction="horizontal">
                  <Radio value={1}>Entrées</Radio>
                  <Radio value={2}>Sorties</Radio>
                  <Radio value={3}>Tous</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={4}>
            <Select
              showSearch
              optionFilterProp="label"
              optionLabelProp="label"
              allowClear
              placeholder="Personnes"
              style={{ width: "100%" }}
              onChange={onChangeTypePersonne}
            >
              <OptGroup label="Type de personne">
                <Option label="Locataires" value="1">
                  Locataires
                </Option>
                <Option label="Propriétaires" value="2">
                  Propriétaires
                </Option>
              </OptGroup>
            </Select>
          </Col>
          <Col span={4}>
            <Select
              showSearch
              optionFilterProp="label"
              optionLabelProp="label"
              allowClear
              placeholder="Types de lot"
              style={{ width: "100%" }}
              onChange={(id) => onChangeTypeLot(id)}
            >
              <OptGroup label="Type de lot">
                {props.typesLots &&
                  props.typesLots.map((item, i) => {
                    return (
                      <Option key={i} value={item.type_lot_id} label={item.type_lot_libelle}>
                        <Row>
                          <Col sm={8}>{item.type_lot_libelle}</Col>
                        </Row>
                      </Option>
                    );
                  })}
              </OptGroup>
            </Select>
          </Col>
          <Col span={5}>
            <Select
              showSearch
              optionFilterProp="label"
              optionLabelProp="label"
              allowClear
              placeholder="Immeubles"
              style={{ width: "100%" }}
              onChange={(id) => onChangeImmeuble(id)}
            >
              <OptGroup label="Immeuble">
                {props.immeubles &&
                  props.immeubles.map((item, i) => {
                    return (
                      <Option key={i} value={item.immeuble_id} label={item.immeuble_nom}>
                        <Row>
                          <Col sm={8}>{item.immeuble_nom}</Col>
                        </Row>
                      </Option>
                    );
                  })}
              </OptGroup>
            </Select>
          </Col>
          <Col span={5}>
            <Select
              mode="multiple"
              showSearch
              optionFilterProp="label"
              optionLabelProp="label"
              allowClear
              placeholder="Rubriques"
              style={{ width: "100%" }}
              onChange={(values) => onChangeRubriques(values)}
            >
              <OptGroup label="Rubrique">
                {props.rubriques &&
                  props.rubriques.map((item, i) => {
                    return (
                      <Option key={i} value={item.rubrique_id} label={item.rubrique_libelle}>
                        <Row>
                          <Col sm={8}>{item.rubrique_libelle}</Col>
                        </Row>
                      </Option>
                    );
                  })}
              </OptGroup>
            </Select>
          </Col>
          <Col span={3}>
            <Text mark>{`Nombre de contrats(s): ${
              lignesMouvements ? lignesMouvements.length : 0
            }`}</Text>
          </Col>
        </Row>
      </Form>
      <Row gutter={12} style={{ marginTop: 10 }}>
        <Col span={21}>
          <div ref={componentRef}>
            {lignesMouvements && (
              <Table
                className="list-data"
                dataSource={lignesMouvements}
                columns={tableOption(mouvement)}
                pagination={false}
                rowKey={"location_id"}
              />
            )}
          </div>
        </Col>
        <Col span={3}>
          {lignesMouvements && (
            <>
              <Row style={{ marginBottom: 10 }}>
                <Button
                  type="primary"
                  // style={{ width: "100%" }}
                  onClick={() => createPdfLocation()}
                >
                  PDF (par location)
                </Button>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Button
                  type="primary"
                  // style={{ width: "100%" }}
                  onClick={() => createPdfRubrique()}
                >
                  PDF (par rubrique)
                </Button>
              </Row>
            </>
          )}
          {lignesCSV && (
            <>
              <Row style={{ marginBottom: 10 }}>
                <Button>
                  <CSVLink
                    filename={"export.csv"}
                    data={lignesCSV}
                    //headers={headers()}
                    separator={";"}
                  >
                    Export CSV
                  </CSVLink>
                </Button>
              </Row>
              <Row>
                <Switch
                  // checked={commercial}
                  onChange={onChangeAvecRubriques}
                  checkedChildren="(avec rubriques)"
                  unCheckedChildren="(avec rubriques)"
                />
              </Row>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ListMouvements;
