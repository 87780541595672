import { combineReducers } from "redux";

import agents from "./agentReducer";
import caution from "./cautionReducer";
import codesCouleurs from "./codeCouleurReducer";
import champsPersos from "./champPersoReducer";
import champsPersosGroupes from "./champPersoGroupeReducer";
import comptes from "./compteReducer";
import demandes from "./demandeReducer";
import documents from "./documentReducer";
import ecritures from "./ecritureReducer";
import equipements from "./equipementReducer";
import etablissements from "./etablissementReducer";
import etapes from "./etapeReducer";
import etapesTemplates from "./etapeTemplateReducer";
import evenements from "./evenementReducer";
import fraisAgence from "./fraisAgenceReducer";
import groupes from "./groupeReducer";
import immeubles from "./immeubleReducer";
import journals from "./journalReducer";
import langues from "./langueReducer";
import locataires from "./locataireReducer";
import locations from "./locationReducer";
import lots from "./lotReducer";
import menages from "./menageReducer";
import parametre from "./parametreReducer";
import parcoursClients from "./parcoursClientReducer";
import pieces from "./pieceReducer";
import proprietaires from "./proprietaireReducer";
import provenances from "./provenanceReducer";
import qualites from "./qualiteReducer";
import quittances from "./quittanceReducer";
import quittancesLignes from "./quittanceLigneReducer";
import rapprochementsBancaires from "./rapprochementBancaireReducer";
import reglements from "./reglementReducer";
import renduAs from "./renduReducer";
import renduAsHistorique from "./renduAHistoriqueReducer";
import requetes from "./requeteReducer";
import requetesLots from "./requeteLotReducer";
import rubriques from "./rubriqueReducer";
import societes from "./societeReducer";
import tags from "./tagReducer";
import templates from "./templateReducer";
import templatesLangues from "./templateLangueReducer";
import typesEmails from "./typeEmailReducer";
import typesLots from "./typeLotReducer";
import typesPaiements from "./typePaiementReducer";
import tree from "./treeReducer";
import utilisateur from "./utilisateurReducer";
import words from "./wordReducer";

export default combineReducers({
  agents,
  caution,
  codesCouleurs,
  champsPersos,
  champsPersosGroupes,
  comptes,
  demandes,
  documents,
  ecritures,
  equipements,
  etablissements,
  etapes,
  etapesTemplates,
  evenements,
  fraisAgence,
  groupes,
  immeubles,
  journals,
  langues,
  locataires,
  locations,
  lots,
  menages,
  parametre,
  parcoursClients,
  pieces,
  proprietaires,
  provenances,
  qualites,
  quittances,
  quittancesLignes,
  rapprochementsBancaires,
  reglements,
  requetes,
  requetesLots,
  renduAs,
  renduAsHistorique,
  rubriques,
  societes,
  typesEmails,
  typesLots,
  typesPaiements,
  tags,
  templates,
  templatesLangues,
  tree,
  utilisateur,
  words,
});
