import React, { Component } from "react";

import Constants from "../../../utils/constants";

import dayjs from "dayjs";

export default class AvisEcheancePDF extends Component {
  addSpaces(numSpaces, str) {
    return " ".repeat(numSpaces) + str;
  }

  render() {
    const { avis, utilisateur } = this.props;

    const societe = utilisateur.utilisateur_etablissement.etablissement_societe;

    const chemin = `${Constants.mode}://${Constants.domain}:${Constants.port}/api/societe/display/${societe.societe_id}`;

    let width = 200;
    let height = 30;

    if (societe.societe_logo_width) width = societe.societe_logo_width;
    if (societe.societe_logo_height) height = societe.societe_logo_height;

    const locataire = avis.locataire;
    const lot = avis.lot;

    const totalAvis = avis.location_ligne.reduce(
      (a, b) => a + parseFloat(b.location_ligne_montant),
      0
    );

    const resteDu = parseFloat(totalAvis + avis.solde);

    return (
      <>
        {/* LOGO GROUPEMENT */}
        <div align="center">
          <img src={chemin} width={width} height={height} alt="" />
        </div>

        {/* ADRESSE SOCIETE */}
        <div>
          <strong>{`${societe.societe_raison_sociale}`}</strong>
          <br />
          {`${societe.societe_adresse.adresse_numero} ${societe.societe_adresse.adresse_voie}`}
          <br />
          {`${societe.societe_adresse.adresse_code_postal} ${societe.societe_adresse.adresse_ville}`}
        </div>

        {/* ADRESSE LOCATAIRE */}
        <div>
          <table className="table-signature">
            <thead>
              <tr>
                <th width="55%"></th>
                <th width="45%"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="55%"></td>
                <td width="45%">
                  <p>
                    <strong>
                      {`${locataire.locataire_personne.personne_qualite.qualite_libelle} ${locataire.locataire_personne.personne_nom} ${locataire.locataire_personne.personne_prenom}`}
                    </strong>
                  </p>
                  <p>
                    {`${locataire.locataire_personne.personne_adresse.adresse_numero} ${locataire.locataire_personne.personne_adresse.adresse_voie}`}
                  </p>
                  <p>
                    {`${locataire.locataire_personne.personne_adresse.adresse_code_postal} ${locataire.locataire_personne.personne_adresse.adresse_ville}`}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />

        {/* TITRE DOCUMENT */}
        <div align="center" style={{ fontSize: 16 }}>
          <strong>
            {`AVIS D'ÉCHÉANCE`}
            <br />
            {`PÉRIODE DU ${dayjs(avis.date_debut).format("DD/MM/YYYY")} AU ${dayjs(
              avis.date_fin
            ).format("DD/MM/YYYY")}`}
          </strong>
        </div>

        <br />
        <br />

        {/* LOCATION */}
        <table className="table-signature">
          <thead>
            <tr bgcolor="#afaeae">
              <th>
                <strong>Location</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr bgcolor="#eeeeee">
              <td>
                {`Référence: ${lot.lot_reference}`}
                <br />
                {`Adresse:`}
                <br />
                {`${lot.lot_adresse.adresse_numero} ${lot.lot_adresse.adresse_voie}`}
                <br />
                {`${lot.lot_adresse.adresse_code_postal} ${lot.lot_adresse.adresse_ville}`}
              </td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />
        <br />

        {/* LIGNES QUITTANCES */}
        <table className="table-signature" style={{ width: "100%" }}>
          <thead>
            <tr bgcolor="#f4c181">
              <th style={{ width: "50%" }}>
                <strong>Libellé</strong>
              </th>
              <th style={{ width: "25%" }}></th>
              <th align="right" style={{ width: "25%" }}>
                <strong>{`Montant`}</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {avis.location_ligne
              .sort(
                (a, b) =>
                  a.location_ligne_rubrique.rubrique_id - b.location_ligne_rubrique.rubrique_id
              )
              .map((ligne, i) => {
                return (
                  <tr key={i} bgcolor={i % 2 === 0 ? "#ffffff" : "#eeeeee"}>
                    <td style={{ width: "50%" }}>
                      {ligne.location_ligne_rubrique.rubrique_libelle}
                    </td>
                    <td style={{ width: "25%" }}></td>
                    <td align="right" style={{ width: "25%" }}>
                      {parseFloat(ligne.location_ligne_montant).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </td>
                  </tr>
                );
              })}
          </tbody>
          <tfoot>
            <br />
            <tr>
              <th style={{ width: "50%" }}></th>
              <th align="right" style={{ width: "25%" }}>
                <strong>{`TOTAL DU TERME:`}</strong>
              </th>
              <td align="right" style={{ width: "25%" }}>
                <strong>
                  {parseFloat(totalAvis).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </strong>
              </td>
            </tr>
            <tr>
              <th style={{ width: "50%" }}></th>
              <th align="right" style={{ width: "25%" }}>
                <strong>ARRIÉRÉS:</strong>
              </th>
              <td align="right" style={{ width: "25%" }}>
                <strong>
                  {parseFloat(avis.solde).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </strong>
              </td>
            </tr>
            <tr>
              <th style={{ width: "50%" }}></th>
              <th align="right" style={{ width: "25%" }}>
                <strong>TOTAL À REGLER:</strong>
              </th>
              <td align="right" style={{ width: "25%" }}>
                <strong>
                  {parseFloat(resteDu).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </strong>
              </td>
            </tr>
          </tfoot>
        </table>

        <br />
        <br />
        <br />
        <br />

        {avis.quittance && avis.quittance.solde === 0 && (
          <>
            <div style={{ borderTop: "2px solid black" }}></div>
            <br />
            <br />
            {/* TITRE DOCUMENT */}
            <div align="center" style={{ fontSize: 16 }}>
              <strong>
                {`QUITTANCE`}
                <br />
                {`PÉRIODE DU ${dayjs(avis.quittance.quittance_date_debut).format(
                  "DD/MM/YYYY"
                )} AU ${dayjs(avis.quittance.quittance_date_fin).format("DD/MM/YYYY")}`}
              </strong>
            </div>

            <br />
            <br />

            {/* LIGNES QUITTANCES */}
            <table className="table-signature" style={{ width: "100%" }}>
              <thead>
                <tr bgcolor="#f4c181">
                  <th style={{ width: "50%" }}>
                    <strong>Libellé</strong>
                  </th>
                  <th style={{ width: "25%" }}></th>
                  <th align="right" style={{ width: "25%" }}>
                    <strong>{`Montant`}</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {avis.quittance.quittance_ligne
                  .sort(
                    (a, b) =>
                      a.quittance_ligne_rubrique.rubrique_id -
                      b.quittance_ligne_rubrique.rubrique_id
                  )
                  .map((ligne, i) => {
                    return (
                      <tr key={i} bgcolor={i % 2 === 0 ? "#ffffff" : "#eeeeee"}>
                        <td style={{ width: "50%" }}>
                          {ligne.quittance_ligne_rubrique.rubrique_libelle}
                        </td>
                        <td style={{ width: "25%" }}></td>
                        <td align="right" style={{ width: "25%" }}>
                          {parseFloat(ligne.quittance_ligne_montant).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot>
                <br />
                <tr>
                  <th style={{ width: "50%" }}></th>
                  <th align="right" style={{ width: "25%" }}>
                    <strong>{`TOTAL DU TERME:`}</strong>
                  </th>
                  <td align="right" style={{ width: "25%" }}>
                    <strong>
                      {parseFloat(
                        avis.quittance.quittance_ligne.reduce(
                          (a, b) => a + parseFloat(b.quittance_ligne_montant),
                          0
                        )
                      ).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </>
        )}
      </>
    );
  }
}
