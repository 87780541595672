import React, { Component } from "react";

import { MinusOutlined, PlusOutlined, WarningOutlined } from "@ant-design/icons";
import { Form, Row, Col, DatePicker, Button, Divider, Popconfirm, Switch, Select } from "antd";

import CustomInput from "../CustomFormItem/Input";
import CustomSelect from "../CustomFormItem/Select";

import Constants from "../../utils/constants";
import { formatDateToBDD, getInfoLocataire } from "../../utils/tools";

import { toast } from "react-toastify";

import dayjs from "dayjs";

const dateFormat = "DD/MM/YYYY";

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

class FormContratRapide extends Component {
  state = {
    loading: false,
    dates: [dayjs(), dayjs()],
    blocageProprietaire: false,
    indisponible: false,
    isDisabled: false,
    isDisabled2: false,
    isDisabled3: false,
    isDisabled4: false,
    newLocataire: false,
    codeLocataire: "",
    notification: false,
    premierClic: false,
    dateAnterieur: false,
  };

  // Référence du formulaire
  formRef = React.createRef();

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    if (props.currentEvent && props.currentEvent.location_etat === "b") {
      this.setState({ blocageProprietaire: true });
    }
    if (props.currentEvent && props.currentEvent.location_etat === "n") {
      this.setState({ indisponible: true });
    }

    if (!props.currentEvent.location_id) {
      this.setState({
        blocageProprietaire: false,
        indisponible: false,
        notification: false,
        newLocataire: false,
        premierClic: false,
        isDisabled2: false,
        isDisabled3: false,
      });
      this.formRef.current.resetFields([
        "blocageProprietaire",
        "indisponible",
        "locataire_id",
        "newLocataire",
        "notification",
      ]);
      this.resetLocataire();
    }

    this.formRef.current.setFieldsValue({
      lot_id: props.lot.lot_id,
      dates: [dayjs(props.currentEvent.startDate), dayjs(props.currentEvent.endDate)],
      location_remarques: props.currentEvent.location_remarques,
    });
    this.setState({
      dates: [dayjs(props.currentEvent.startDate), dayjs(props.currentEvent.endDate)],
      dateAnterieur: dayjs(props.currentEvent.startDate).isBefore(dayjs(), "day"),
    });
  };

  onChangeNotification = (checked) => {
    if (checked === true) {
      this.setState({ isDisabled4: false });
    }
    if (checked === false) {
      this.setState({ isDisabled4: false });
    }
    this.setState({ notification: checked });
  };

  onChangeBlocageProprietaire = (checked) => {
    if (checked === true) {
      this.setState({ isDisabled2: true, isDisabled3: true, notification: true });
      this.formRef.current.setFieldsValue({ notification: true });
    }
    if (checked === false) {
      this.setState({ isDisabled2: false, isDisabled3: false });
    }
    this.setState({ blocageProprietaire: checked });
  };

  onChangeIndisponible = (checked) => {
    if (checked === true) {
      this.setState({ isDisabled: true, isDisabled3: true });
    }
    if (checked === false) {
      this.setState({ isDisabled: false, isDisabled3: false });
    }
    this.setState({ indisponible: checked });
  };

  onChangeRange = (e) => {
    this.setState({ dates: [e[0], e[1]] });
  };

  onFinish = (data) => {
    if (data.dates[1].diff(data.dates[0]) > 0) {
      this.setState({ premierClic: true });

      if (this.state.blocageProprietaire) {
        this.handleBlocageProprietaire(data);
        if (this.state.notification) {
          this.envoiNotifProp(data);
        }
      }
      if (this.state.indisponible) {
        this.handleIndisponible(data);
      }
      if (!this.state.blocageProprietaire && !this.state.indisponible) {
        this.handleCreateRequete(data);
      }
    } else {
      toast.error("Validation impossible, la durée de la location est de 0 jour !", {
        containerId: "A",
      });
    }
  };

  handleBlocageProprietaire = (data) => {
    const { currentEvent } = this.props;
    currentEvent.id
      ? this.updateBlocage(data)
      : this.props.saveLocation(this.jsonLocation(data, null, "b")).then((res) => {
          this.setState({
            blocageProprietaire: false,
            indisponible: false,
            isDisabled: false,
            isDisabled2: false,
            isDisabled3: false,
            newLocataire: false,
            notification: false,
          });
          this.props.closeModal();
        });
  };

  handleIndisponible = (data) => {
    const { currentEvent } = this.props;
    currentEvent.id
      ? this.updateBlocage(data)
      : this.props.saveLocation(this.jsonLocation(data, null, "n")).then((res) => {
          this.setState({
            blocageProprietaire: false,
            indisponible: false,
            isDisabled: false,
            isDisabled2: false,
            isDisabled3: false,
            newLocataire: false,
            notification: false,
          });
          this.props.closeModal();
        });
  };

  handleCreateRequete = (data) => {
    if (this.state.newLocataire) {
      // création du locataire
      this.props
        .saveLocataire({
          compte_bancaire_type_paiement_id: 1,
          locataire_code: data.locataire_code,
          personne_actif: true,
          personne_groupement_id: this.props.utilisateur.groupement_id,
          personne_nom: data.personne_nom,
          personne_prenom: data.personne_prenom,
          personne_qualite_id: data.personne_qualite_id,
          personne_email1: data.personne_email1,
          personne_portable1: data.personne_portable1,
          personne_langue_id: this.props.parametre.parametre_langue_id,
        })
        .then((res) => {
          this.createRequete(data, res.locataire_id);
        });
    } else {
      this.createRequete(data, data.locataire_id);
    }
    this.setState({ loading: true });
  };

  createRequete = (data, idLocataire) => {
    this.props.saveDossier(this.getJsonDossier()).then((dossier) => {
      this.props
        .saveRequete({
          requete_locataire_id: idLocataire,
          requete_provenance_id: data.requete_provenance_id,
          requete_groupe_id: data.requete_groupe_id,
          requete_dossier_id: dossier ? dossier.dossier_id : null,
        })
        .then((req) => {
          // Mise à jour renduA
          if (
            this.props.parametre.parametre_parcours_client &&
            this.props.parcoursClients.length > 0
          ) {
            let firstPC = this.props.parcoursClients.find(
              (i) =>
                i.parcours_client_ordre === 1 &&
                i.parcours_client_groupe.groupe_id === data.requete_groupe_id
            );
            this.props.saveRenduA({
              rendu_a_actif: true,
              rendu_a_commentaire: "",
              rendu_a_requete_id: req.requete_id,
              rendu_a_etape_id: firstPC.parcours_client_etape.etape_id,
            });
          }
          this.props.saveRequeteLot({
            requete_lot_requete_id: req.requete_id,
            requete_lot_lot_id: data.lot_id,
            requete_lot_date_debut: formatDateToBDD(data.dates[0]),
            requete_lot_date_fin: formatDateToBDD(data.dates[1]),
            requete_lot_type: 1,
          });
          this.props
            .updateDossier(this.getJsonDossier(req), dossier.dossier_id)
            .then((dos) => (req.requete_dossier = dos));
          this.props.saveLocation(this.jsonLocation(data, req, "o")).then((res) => {
            const pane = { key: "26", title: "Contrats / Disponibilités", content: "" };
            this.props.addPanesRequete(pane, "26", req);
            this.setState({
              loading: false,
              blocageProprietaire: false,
              indisponible: false,
              isDisabled: false,
              isDisabled2: false,
              isDisabled3: false,
              newLocataire: false,
              notification: false,
            });
            this.resetLocataire();
            this.props.closeModal();
          });
        });
    });
  };

  getJsonDossier = (requete) => {
    return {
      dossier_url: requete ? `Requetes/${requete.requete_id}/` : Constants.directory,
    };
  };

  jsonLocation = (data, req, etat) => {
    let motif = "";
    if (etat === "b" || etat === "n") {
      motif = data.location_remarques ? data.location_remarques : "";
    } else {
      motif = "/";
    }

    const lot = this.props.lots.find((l) => l.lot_id === data.lot_id);

    const getTypeContrat = (lot) => {
      return lot.lot_court_terme && !lot.lot_alur && !lot.lot_long_terme ? 3 : null;
    };

    const jsonData = {
      location_date_creation: formatDateToBDD(),
      location_date_debut: formatDateToBDD(data.dates[0]),
      location_date_fin: formatDateToBDD(data.dates[1]),
      location_acompte_propose: 0.0,
      location_remarques: motif,
      location_fin_option: "/",
      location_desiderata: "",
      location_etat: etat,
      location_nb_bebes: 0,
      location_nb_adultes: 0,
      location_nb_enfants: 0,
      location_montant_propose: 0,
      location_pourcentage_acompte: 1,
      location_heure_arrivee: "10:00",
      location_heure_depart: "15:00",
      location_entree: true,
      location_sortie: true,
      location_menage: true,
      location_type_contrat: getTypeContrat(lot),
      location_remise_fidelite: true,
      location_bail_signe: false,
      location_info_sejour: false,
      location_chauffeur_reserve: false,
      location_lot_id: data.lot_id,
      ...(req && { location_requete_id: req.requete_id }),
    };
    return jsonData;
  };

  resetLocataire = () => {
    this.formRef.current.resetFields([
      "locataire_code",
      "personne_nom",
      "personne_prenom",
      "personne_qualite_id",
      "personne_email1",
      "personne_portable1",
      "requete_provenance_id",
      "requete_groupe_id",
    ]);
  };

  updateBlocage = (data) => {
    const { currentEvent } = this.props;
    currentEvent.location_lot_id = currentEvent.location_lot.lot_id;
    currentEvent.location_date_debut = formatDateToBDD(this.state.dates[0]);
    currentEvent.location_date_fin = formatDateToBDD(this.state.dates[1]);
    currentEvent.location_remarques = data.location_remarques;
    this.props.updateLocation(currentEvent, currentEvent.id).then(this.props.closeModal());
  };

  deleteBlocage = () => {
    const { currentEvent } = this.props;
    currentEvent.location_lot_id = currentEvent.location_lot.lot_id;
    currentEvent.location_date_debut = formatDateToBDD(this.state.dates[0]);
    currentEvent.location_date_fin = formatDateToBDD(this.state.dates[1]);
    currentEvent.location_etat = "a";
    this.props.updateLocation(currentEvent, currentEvent.id).then(this.props.closeModal());
  };

  generateCodeLocataire = () => {
    let maxCode = 0;
    let maxNum = 0;
    let code = "";
    this.props.locataires &&
      this.props.locataires.forEach((item) => {
        if (parseInt(item.locataire_code) > parseInt(maxCode)) maxCode = item.locataire_code;
      });
    maxNum = parseInt(maxCode) + 1;
    for (var i = 0; i < 6 - maxNum.toString().length; i++) {
      code = code + "0";
    }
    return code + maxNum;
  };

  getCodeLocataire = () => {
    const code = this.generateCodeLocataire();
    this.setState({ codeLocataire: code });
    this.formRef.current.setFieldsValue({
      locataire_code: code,
    });
  };

  onClickAddLoc = () => {
    this.setState({ newLocataire: true });
    this.getCodeLocataire();
  };

  onClickReduceLoc = () => {
    this.resetLocataire();
    this.setState({ newLocataire: false });
  };

  envoiNotifProp = (data) => {
    const arrayDestinataire = [];
    const lot = this.props.lots.find((l) => l.lot_id === data.lot_id);
    const libProp =
      lot.lot_proprietaire.proprietaire_personne.personne_prenom +
      " " +
      lot.lot_proprietaire.proprietaire_personne.personne_nom;

    arrayDestinataire.push({
      email: lot.lot_proprietaire.proprietaire_personne.personne_email1,
      name: libProp,
    });

    const message =
      "Madame, Monsieur, " +
      "<br/>" +
      "<br/>" +
      "Nous vous confirmons que nous avons bien bloqué votre appartement n°" +
      lot.lot_reference +
      " pour la période du " +
      dayjs(formatDateToBDD(data.dates[0])).format("DD/MM/YYYY") +
      " au " +
      dayjs(formatDateToBDD(data.dates[1])).format("DD/MM/YYYY") +
      "." +
      "<br/>" +
      "<br/>" +
      "Bien cordialement," +
      "<br/>" +
      "<br/>" +
      "Le Service Gestion.";

    // plutôt un template

    const jsonData = {
      email_objet: `Confirmation de blocage pour votre appartement référence ${lot.lot_reference}`,
      email_message: message,
      email_destinataire: lot.lot_proprietaire.proprietaire_personne.personne_email1,
      email_destinataire_array: arrayDestinataire,
      email_copie: "",
      email_copie_array: [],
      email_pieces_jointes: [],
      email_utilisateur_id: this.props.utilisateur.utilisateur_id,
      email_requete_id: null,
      email_lot_id: lot.lot_id,
      parametre_smtp: this.props.parametre.parametre_smtp,
      parametre_port: this.props.parametre.parametre_port,
      parametre_email: this.props.parametre.parametre_email,
      parametre_mdp: this.props.parametre.parametre_mdp,
      parametre_copie_agence: this.props.parametre.parametre_copie_agence,
      parametre_email_copie: this.props.parametre.parametre_email_copie,
    };

    toast.info("Envoi en cours...", {
      containerId: "A",
    });

    // Envoi du mail puis sauvegarde de celui-ci
    this.props
      .saveEmail(jsonData)
      .then((res) => {
        toast.success("Email envoyé !", {
          containerId: "A",
        });
      })
      .catch((err) => {
        toast.error("Échec de l'envoi !", {
          containerId: "A",
        });
      });
  };

  render() {
    const {
      loading,
      dates,
      blocageProprietaire,
      indisponible,
      notification,
      isDisabled,
      isDisabled2,
      isDisabled3,
      isDisabled4,
      newLocataire,
      codeLocataire,
      premierClic,
      dateAnterieur,
    } = this.state;
    const { currentEvent, lots, locataires, qualites, nuites, parametre, groupes, provenances } =
      this.props;

    return (
      <>
        {currentEvent && (
          <Form ref={this.formRef} onFinish={this.onFinish}>
            {dateAnterieur && (
              <h5>
                <WarningOutlined /> Dates antérieures
              </h5>
            )}
            <Divider orientation="left">Sélection du lot</Divider>
            <Row>
              <Col span={20}>
                <Form.Item name="lot_id">
                  <Select
                    disabled={currentEvent.id ? true : false}
                    showSearch
                    allowClear
                    optionLabelProp="label"
                    placeholder={"Lot"}
                    // onSelect={onSelectLot}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <OptGroup label={"Lot"}>
                      {lots &&
                        lots.map((item, i) => {
                          return (
                            <Option
                              key={i}
                              value={item.lot_id}
                              label={`${item.lot_reference} - ${item.lot_designation} - ${item.lot_adresse.adresse_ville}, ${item.lot_adresse.adresse_pays}`}
                            >
                              <Row>
                                <Col
                                  sm={8}
                                >{`${item.lot_reference} - ${item.lot_designation} - ${item.lot_adresse.adresse_ville}, ${item.lot_adresse.adresse_pays}`}</Col>
                              </Row>
                            </Option>
                          );
                        })}
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <div style={{ textAlign: "center", marginTop: 10, color: "red" }}>
                  {`${nuites} nuits`}
                </div>
              </Col>
            </Row>
            <Divider orientation="left">Sélection des dates</Divider>
            <Row>
              <Col span={20}>
                <Form.Item name="dates">
                  <RangePicker
                    format={dateFormat}
                    onCalendarChange={(e) => this.onChangeRange(e)}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <div style={{ textAlign: "center", marginTop: 10 }}>
                  {dates[0] &&
                    dates[1] &&
                    `(${Math.round(dayjs.duration(dates[1].diff(dates[0])).asDays())} nuits)`}
                </div>
              </Col>
            </Row>
            {!currentEvent.id && (
              <>
                <Divider orientation="left">Blocage propriétaire</Divider>
                <Row gutter={12}>
                  <Col span={6}>
                    <Form.Item name="blocageProprietaire" valuePropName="checked">
                      <Switch
                        disabled={isDisabled}
                        checked={blocageProprietaire}
                        onChange={this.onChangeBlocageProprietaire}
                        style={{ height: "40px" }}
                        checkedChildren="Blocage propriétaire"
                        unCheckedChildren="Blocage propriétaire"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    {blocageProprietaire && !currentEvent.id && (
                      <Form.Item name="notification" valuePropName="checked">
                        <Switch
                          disabled={isDisabled4}
                          checked={notification}
                          onChange={this.onChangeNotification}
                          style={{ height: "40px" }}
                          checkedChildren="Notification propriétaire"
                          unCheckedChildren="Notification propriétaire"
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Divider orientation="left">Non disponible</Divider>
                <Form.Item name="indisponible" valuePropName="checked">
                  <Switch
                    disabled={isDisabled2}
                    checked={indisponible}
                    onChange={this.onChangeIndisponible}
                    style={{ height: "40px" }}
                    checkedChildren="Non disponible"
                    unCheckedChildren="Non disponible"
                  />
                </Form.Item>
              </>
            )}
            {(blocageProprietaire || indisponible) && (
              <>
                <Row>
                  <Col span={20}>
                    <Form.Item name="location_remarques">
                      <CustomInput
                        label="Motif"
                        inputName="location_remarques"
                        formItemName="location_remarques"
                        objectValue={currentEvent.location_remarques}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {!currentEvent.id && !blocageProprietaire && !indisponible && !newLocataire && (
              <>
                <Divider orientation="left">ou Sélection du locataire pour contrat</Divider>
                <Row>
                  <Col span={22}>
                    <Form.Item
                      name="locataire_id"
                      rules={[
                        {
                          required: true,
                          message: "Locataire obligatoire",
                        },
                      ]}
                    >
                      <Select
                        disabled={isDisabled3}
                        style={{ width: "100%" }}
                        showSearch
                        allowClear
                        optionLabelProp="label"
                        placeholder="Locataire"
                        // onChange={onChangeLoc}
                        // onClear={onClearLoc}
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <OptGroup label="Locataire">
                          {locataires &&
                            locataires.map((item, i) => {
                              return (
                                <Option
                                  key={i}
                                  value={item.locataire_id}
                                  label={getInfoLocataire(item)}
                                >
                                  <Row>
                                    <Col sm={8}>{getInfoLocataire(item)}</Col>
                                  </Row>
                                </Option>
                              );
                            })}
                        </OptGroup>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Form.Item>
                      <Button type="primary" htmlType="reset" onClick={this.onClickAddLoc}>
                        <PlusOutlined />
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {newLocataire && !blocageProprietaire && !indisponible && (
              <>
                <Divider orientation="left">ou Création d'un locataire pour contrat</Divider>
                <Row gutter={4}>
                  <Col span={4}>
                    <CustomInput
                      label="Code"
                      inputName="code"
                      formItemName="locataire_code"
                      objectValue={codeLocataire}
                      rules={{ required: true, message: "Code obligatoire" }}
                      readOnly
                    />
                  </Col>
                  <Col span={4}>
                    <CustomSelect
                      label="Qualité"
                      inputName="qualite"
                      formItemName="personne_qualite_id"
                      rules={{ required: true, message: "Qualité obligatoire" }}
                    >
                      <OptGroup label="Qualite">
                        {qualites &&
                          qualites.map((item, i) => {
                            return (
                              <Option key={i} value={item.qualite_id} label={item.qualite_libelle}>
                                <Row>
                                  <Col sm={8}>{item.qualite_libelle}</Col>
                                </Row>
                              </Option>
                            );
                          })}
                      </OptGroup>
                    </CustomSelect>
                  </Col>
                  <Col span={8}>
                    <CustomInput
                      label="Nom"
                      inputName="nom"
                      formItemName="personne_nom"
                      rules={{ required: true, message: "Nom obligatoire" }}
                    />
                  </Col>
                  <Col span={8}>
                    <CustomInput label="Prénom" inputName="prenom" formItemName="personne_prenom" />
                  </Col>
                </Row>
                <Row gutter={4}>
                  <Col span={12}>
                    <CustomInput label="Email" inputName="mail1" formItemName="personne_email1" />
                  </Col>
                  <Col span={11}>
                    <CustomInput
                      label="Portable"
                      inputName="portable1"
                      formItemName="personne_portable1"
                    />
                  </Col>
                  <Col span={1}>
                    <Form.Item>
                      <Button type="primary" htmlType="reset" onClick={this.onClickReduceLoc}>
                        <MinusOutlined />
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            {parametre.parametre_parcours_client && !blocageProprietaire && !indisponible && (
              <>
                <Row gutter={12}>
                  <Col span={6}>
                    <Form.Item
                      name="requete_provenance_id"
                      rules={[
                        {
                          required: true,
                          message: "Provenance obligatoire",
                        },
                      ]}
                    >
                      <Select
                        // disabled={isDisabled3}
                        style={{ width: "100%" }}
                        showSearch
                        allowClear
                        optionLabelProp="label"
                        placeholder="Provenance"
                      >
                        <OptGroup label="Provenance">
                          {provenances &&
                            provenances.map((item, i) => {
                              return (
                                <Option
                                  key={i}
                                  value={item.provenance_id}
                                  label={item.provenance_libelle}
                                >
                                  <Row>
                                    <Col sm={8}>{item.provenance_libelle}</Col>
                                  </Row>
                                </Option>
                              );
                            })}
                        </OptGroup>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="requete_groupe_id"
                      rules={[
                        {
                          required: true,
                          message: "Groupe obligatoire",
                        },
                      ]}
                    >
                      <Select
                        // disabled={isDisabled3}
                        style={{ width: "100%" }}
                        showSearch
                        allowClear
                        optionLabelProp="label"
                        placeholder="Groupe"
                      >
                        <OptGroup label="Groupe">
                          {groupes &&
                            groupes
                              .filter((groupe) => groupe.groupe_visible === true)
                              .map((item, i) => {
                                return (
                                  <Option
                                    key={i}
                                    value={item.groupe_id}
                                    label={item.groupe_libelle}
                                  >
                                    <Row>
                                      <Col sm={8}>{item.groupe_libelle}</Col>
                                    </Row>
                                  </Option>
                                );
                              })}
                        </OptGroup>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Row style={{ marginTop: 10, textAlign: "right" }}>
              {currentEvent.id && (
                <Popconfirm
                  title="Êtes-vous sûr de vouloir supprimer cet évenement ?"
                  onConfirm={this.deleteBlocage}
                  cancelText="Annuler"
                >
                  <Button type="primary" danger>
                    Supprimer
                  </Button>
                </Popconfirm>
              )}
              <Button
                disabled={premierClic}
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ marginRight: 10 }}
              >
                Valider
              </Button>
            </Row>
          </Form>
        )}
      </>
    );
  }
}

export default FormContratRapide;
