import { templateToAdapted } from "./templateAdapter";

export function emailsToAdapted(items) {
  return items
    ? items.map((item) => {
        return emailToAdapted(item);
      })
    : null;
}

export function emailToAdapted(item) {
  return item
    ? {
        email_id: item.id,
        email_objet: item.objet,
        email_message: item.message,
        email_destinataire: item.destinataire,
        email_copie: item.copie,
        email_utilisateur: item.utilisateur,
        email_requete_id: item.requete_id,
        email_lot_id: item.lot_id,
        email_template: templateToAdapted(item.template),
        email_pieces_jointes: item.pieces_jointes, // ?? TODO
        created_at: item.created_at,
      }
    : null;
}

export function adaptedToEmail(item) {
  return item
    ? {
        objet: item.email_objet,
        message: item.email_message,
        destinataire: item.email_destinataire,
        copie: item.email_copie,
        user_id: item.email_utilisateur_id,
        requete_id: item.email_requete_id,
        lot_id: item.email_lot_id,
        template_id: item.email_template_id,
        destinataire_array: item.email_destinataire_array,
        copie_array: item.email_copie_array,
        pieces_jointes: item.email_pieces_jointes,
        parametre_smtp: item.parametre_smtp,
        parametre_port: item.parametre_port,
        parametre_email: item.parametre_email,
        parametre_mdp: item.parametre_mdp,
        parametre_copie_agence: item.parametre_copie_agence,
        parametre_email_copie: item.parametre_email_copie,
      }
    : null;
}
