import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";
import { columns } from "./columns";
import { loadLocations, updateLocation } from "../../store/actions/locationAction";
import ListAltimmo from "../../components/ListAltimmo";

class Altimmo extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    // const societe_id = this.props.utilisateur.societe_id;
    // const etablissement_id = this.props.utilisateur.etablissement_id;
    this.props.loadLocations(groupement_id);
  }

  render() {
    const { locations, typesEmails, utilisateur } = this.props;

    return (
      <Spin spinning={!locations} size="large" tip="Chargement en cours...">
        <Card title="" bordered={false} className="card-panel">
          <ListAltimmo
            locations={
              locations &&
              locations
                .sort((a, b) => a.location_id - b.location_id)
                .filter((item) => item.location_lot.lot_societe_id === utilisateur.societe_id)
            }
            typesEmails={typesEmails.sort((a, b) => a.id - b.id)}
            tableOption={columns}
            updateLocation={this.props.updateLocation}
            addPanesRequete={this.props.addPanesRequete}
          ></ListAltimmo>
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    updateLocation: (jsonData, id) => dispatch(updateLocation(jsonData, id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    locations: state.locations.data,
    typesEmails: state.typesEmails.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Altimmo);
