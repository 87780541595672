import React, { Component } from "react";
import { Button, Col, Form, Popconfirm, Row, Select, Table } from "antd";

import { DeleteOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { getInfoLocataireSimple, formatDateToAPP } from "../../utils/tools";

import CustomSelect from "../CustomFormItem/Select";

import { toast } from "react-toastify";

import dayjs from "dayjs";

const { Option, OptGroup } = Select;

class FormSuppressionReglement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      societe: null,
      reglements: [],
    };
  }

  formRef = React.createRef();

  componentDidMount() {
    this.init();
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const idLocataire = this.state.idLocataire;
    if (idLocataire) {
      this.onSelectLocataire(idLocataire, nextProps.reglements);
    }
  };

  init = () => {
    const { idLocataire, reglements } = this.props;
    this.formRef.current.setFieldsValue({
      societe_id: this.props.societes[0].societe_id,
    });
    this.setState({
      societe: this.props.societes[0].societe_id,
    });
    idLocataire && this.onSelectLocataire(idLocataire, reglements);
  };

  handleLocataire = (idLocataire) => {
    this.onSelectLocataire(idLocataire);
  };

  onSelectLocataire = (idLocataire, propsReglements) => {
    this.formRef.current.setFieldsValue({
      requete_locataire_id: idLocataire,
    });
    const reglements = propsReglements ? propsReglements : this.props.reglements;
    const locations = this.props.locations.filter(
      (item) =>
        item.location_requete &&
        item.location_requete.requete_locataire?.locataire_id === idLocataire
    );

    const reglementsFilterd = reglements
      .filter(
        (item) =>
          locations.some(
            (location) => item.reglement_quittance.quittance_location_id === location.location_id
          ) === true
      )
      .sort((a, b) => b.reglement_id - a.reglement_id);
    this.setState({ idLocataire, reglements: reglementsFilterd });
  };

  handleDeleteReglement = (e, row) => {
    this.annulationEcriture(row);
  };

  annulationEcriture = (reglement) => {
    const admin = this.props.utilisateur.utilisateur_admin;
    const ecrituresAnnulation = this.props.ecritures.filter(
      (item) => item.ecriture_action === `REG${reglement.reglement_id}`
    );
    if (ecrituresAnnulation.length > 0) {
      if (
        !ecrituresAnnulation.some((ecriture) =>
          ecriture.ecriture_ligne.some(
            (ligne) =>
              ligne.ecriture_ligne_lettrage !== "" && ligne.ecriture_ligne_lettrage !== null
          )
        ) ||
        admin
      ) {
        ecrituresAnnulation.forEach((ecriture, idx, ecritures) => {
          this.createEcritureAnnulation(ecriture);
          if (reglement.reglement_type_paiement.type_paiement_bordereau === true) {
            reglement.reglement_bordereau === false &&
              this.createEcritureAnnulationBordereau(reglement);
          }
          if (idx === ecritures.length - 1) {
            this.props
              .deleteReglement(reglement.reglement_id)
              .then((res) => toast.success("Encaissement supprimé !", { containerId: "A" }));
          }
        });
      } else toast.error("Écriture(s) pointée(s), annulation impossible", { containerId: "A" });
    } else {
      this.props.deleteReglement(reglement.reglement_id).then(() => {
        toast.info(
          "Attention, aucune régularisation comptable n'a été générée. Ne pas oublier de passer une OD si nécessaire",
          { containerId: "A", autoClose: false }
        );
        toast.success("Encaissement supprimé !", { containerId: "A" });
      });
    }
  };

  createEcritureAnnulation = (ecriture) => {
    const ecritureLignes = [];
    ecriture.ecriture_ligne.forEach((ligne) => {
      const ligneAnnulation = {
        ecriture_ligne_libelle: "Annulation " + ligne.ecriture_ligne_libelle,
        ecriture_ligne_montant_debit: ligne.ecriture_ligne_montant_credit,
        ecriture_ligne_montant_credit: ligne.ecriture_ligne_montant_debit,
        ecriture_ligne_compte_id: ligne.ecriture_ligne_compte_id,
        ecriture_ligne_locataire_code: "",
        ecriture_ligne_en_reddition: false,
      };
      ecritureLignes.push(ligneAnnulation);
    });
    const ecritureAnnulation = {
      ecriture_journal_id: ecriture.ecriture_journal.journal_id,
      ecriture_date_ecriture: ecriture.ecriture_date_ecriture,
      ecriture_libelle: "Annulation " + ecriture.ecriture_libelle,
      ecriture_action: ecriture.ecriture_action,
      ecriture_ligne: ecritureLignes,
    };
    this.props
      .saveEcriture(ecritureAnnulation)
      .catch((err) => toast.error("Erreur création écriture d'annulation !", { containerId: "A" }));
  };

  createEcritureAnnulationBordereau = (reglement) => {
    const ecritureLignes = [];
    const libelle = `Annulation règlement n°${reglement.reglement_id}`;
    // ligne débit
    const ligneDebit = {
      ecriture_ligne_libelle: libelle,
      ecriture_ligne_montant_debit: 0,
      ecriture_ligne_montant_credit: reglement.reglement_montant,
      ecriture_ligne_compte_id: reglement.reglement_journal.journal_compte.compte_id,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    ecritureLignes.push(ligneDebit);
    // ligne(s) crédit
    const location = this.props.locations.find(
      (item) => item.location_id === reglement.reglement_quittance.quittance_location_id
    );
    const montantTropPercu = this.getMontantTropPercu(reglement);
    reglement.reglement_ligne.forEach((ligne) => {
      const ligneCredit = {
        ecriture_ligne_libelle: libelle,
        ecriture_ligne_montant_debit: parseFloat(ligne.reglement_ligne_montant),
        ecriture_ligne_montant_credit: 0,
        ecriture_ligne_compte_id: ligne.reglement_ligne_quittance_ligne_id.quittance_ligne_rubrique
          .rubrique_compte_id
          ? ligne.reglement_ligne_quittance_ligne_id.quittance_ligne_rubrique.rubrique_compte_id
          : location.location_lot.lot_proprietaire.proprietaire_affectations[0]
              .proprietaire_affectation_compte_id,
        ecriture_ligne_locataire_code: "",
        ecriture_ligne_en_reddition: false,
      };
      ecritureLignes.push(ligneCredit);
    });
    if (montantTropPercu > 0) {
      const ligneTropPercu = {
        ecriture_ligne_libelle: libelle,
        ecriture_ligne_montant_debit: montantTropPercu,
        ecriture_ligne_montant_credit: 0,
        ecriture_ligne_compte_id: this.props.comptes.find(
          (compte) => compte.compte_compte_general === "467001"
        ).compte_id,
        ecriture_ligne_locataire_code: "",
        ecriture_ligne_en_reddition: false,
      };
      ecritureLignes.push(ligneTropPercu);
    }
    const ecritureAnnulation = {
      ecriture_journal_id: reglement.reglement_journal.journal_id,
      ecriture_date_ecriture: reglement.reglement_date,
      ecriture_libelle: libelle,
      ecriture_action: `ANN`,
      ecriture_ligne: ecritureLignes,
    };
    this.props
      .saveEcriture(ecritureAnnulation)
      .catch((err) => toast.error("Erreur création écriture d'annulation !", { containerId: "A" }));
  };

  getMontantTropPercu = (reglement) => {
    let total = 0;
    reglement.reglement_ligne.forEach((ligne) => {
      total += parseFloat(ligne.reglement_ligne_montant);
    });
    return reglement.reglement_montant - total;
  };

  handleClickPointage = (e, row) => {
    this.props.updateReglements({ reglements: [row] });
  };

  handleClickDepointage = (e, row) => {
    // TODO
  };

  render() {
    const { utilisateur, societes, locataires, annulation } = this.props;
    const { societe, reglements, idLocataire } = this.state;

    const admin = utilisateur.utilisateur_admin;

    const expandedRowRender = (row) => {
      return (
        <Table dataSource={row.reglement_ligne} pagination={false} rowKey="reglement_ligne_id">
          <Column
            title="#"
            dataIndex="reglement_ligne_quittance_ligne_id"
            key="rubrique_code"
            render={(quittanceLigne) => quittanceLigne?.quittance_ligne_rubrique.rubrique_code}
          />
          <Column
            title="Rubrique"
            dataIndex="reglement_ligne_quittance_ligne_id"
            key="rubrique_libelle"
            render={(quittanceLigne) => quittanceLigne?.quittance_ligne_rubrique.rubrique_libelle}
          />
          <Column
            title="Type"
            dataIndex="reglement_ligne_quittance_ligne_id"
            key="rubrique_type"
            render={(quittanceLigne) => quittanceLigne?.quittance_ligne_rubrique.rubrique_type}
          />
          <Column
            title="Signe"
            dataIndex="reglement_ligne_quittance_ligne_id"
            key="rubrique_signe"
            render={(quittanceLigne) => quittanceLigne?.quittance_ligne_rubrique.rubrique_signe}
          />
          <Column
            title="Montant"
            dataIndex="reglement_ligne_montant"
            key="reglement_ligne_montant"
            render={(montant) =>
              parseFloat(montant).toLocaleString("fr-FR", {
                style: "currency",
                currency: "EUR",
              })
            }
          />
        </Table>
      );
    };

    return (
      <Form ref={this.formRef} onFinish={this.onFinishReglement} style={{ padding: "20px" }}>
        <Row gutter={10}>
          <Col span={12}>
            <CustomSelect
              label="Société"
              inputName="societe"
              formItemName="societe_id"
              objectValue={societe}
              onChange={(id) => this.onChangeSociete(id)}
            >
              <OptGroup label="Société">
                {societes &&
                  societes.map((item, i) => {
                    return (
                      <Option key={i} value={item.societe_id} label={item.societe_raison_sociale}>
                        <Row>
                          <Col sm={8}>{item.societe_raison_sociale}</Col>
                        </Row>
                      </Option>
                    );
                  })}
              </OptGroup>
            </CustomSelect>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomSelect
              label="Locataire"
              inputName="requete_locataire_id"
              formItemName="requete_locataire_id"
              onSelect={this.handleLocataire}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              objectValue={idLocataire}
            >
              <OptGroup label="Locataire">
                {locataires &&
                  locataires.map((item, i) => {
                    return (
                      <Option
                        key={i}
                        value={item.locataire_id}
                        label={getInfoLocataireSimple(item)}
                      >
                        <Row>
                          <Col sm={8}>{getInfoLocataireSimple(item)}</Col>
                        </Row>
                      </Option>
                    );
                  })}
              </OptGroup>
            </CustomSelect>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Table
            dataSource={reglements}
            expandable={{ expandedRowRender }}
            rowKey="reglement_id"
            scroll={{ y: 512 }}
            size="small"
          >
            <Column
              title="N° Encaissement"
              dataIndex="reglement_id"
              key="reglement_id"
              width="10%"
            />
            <Column
              title="Quittance"
              dataIndex="reglement_quittance"
              key="quittance"
              render={(quittance) =>
                `Appel n°${quittance.quittance_id} pour la période du ${formatDateToAPP(
                  quittance.quittance_date_debut
                )} au ${formatDateToAPP(quittance.quittance_date_fin)}`
              }
              width="30%"
            />
            <Column
              title="Date"
              dataIndex="reglement_date"
              key="reglement_date"
              //render={(reglement_date) => <>{dayjs(reglement_date).format("DD/MM/YYYY")}</>}
              render={(reglement_date) => <>{dayjs(reglement_date).format("DD/MM/YYYY")}</>}
            />
            <Column
              title="Type de paiement"
              dataIndex="reglement_type_paiement"
              key="reglement_type_paiement"
              render={(reglement_type_paiement) => (
                <>{reglement_type_paiement.type_paiement_libelle}</>
              )}
            />
            <Column
              title="Montant"
              dataIndex="reglement_montant"
              key="reglement_montant"
              render={(reglement_montant) => (
                <>
                  {parseFloat(reglement_montant).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </>
              )}
              className="column-money"
              align="right"
            />
            {annulation && (
              <>
                <Column
                  title="Suppression"
                  dataIndex="reglement_id"
                  key="reglement_id"
                  render={(reglement_id, row) => (
                    <Popconfirm
                      title="Confirmez vous la suppression de l'encaissement ?"
                      onConfirm={(e) => this.handleDeleteReglement(e, row)}
                    >
                      <Button>
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  )}
                  align="right"
                />
                <Column
                  title="Reddition"
                  dataIndex="reglement_reddition"
                  key="reglement_reddition"
                  render={(reglement_reddition) => (reglement_reddition ? "*" : "")}
                  align="right"
                />
                {admin && (
                  <Column
                    title="Pointage"
                    dataIndex="reglement_id"
                    key="reglement_id"
                    render={(reglement_id, row) => (
                      <Button
                        onClick={(e) =>
                          row.reglement_reddition
                            ? this.handleClickDepointage(e, row)
                            : this.handleClickPointage(e, row)
                        }
                        disabled={row.reglement_reddition}
                      >
                        {row.reglement_reddition ? <StopOutlined /> : <EditOutlined />}
                      </Button>
                    )}
                    align="center"
                  />
                )}
              </>
            )}
          </Table>
        </Row>
      </Form>
    );
  }
}

export default FormSuppressionReglement;
