import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Row, Table, Typography } from "antd";

import CustomDatePicker from "../CustomFormItem/DatePicker";
import { EditableCell, EditableRow } from "../EditableTable";
import { CSVLink } from "react-csv";

import dayjs from "dayjs";
import { MailTwoTone } from "@ant-design/icons";

const { Title } = Typography;

const ListAltimmo = (props) => {
  const [locations, setLocations] = useState([]);
  const [dateDu, setDateDu] = useState(dayjs().format("DD/MM/YYYY")); // today
  const [dateAu, setDateAu] = useState(dayjs().add(30, "day").format("DD/MM/YYYY")); // last day of month
  const [lignesCSV, setCSV] = useState();

  const { typesEmails, tableOption } = props;

  // Référence du formulaire
  const formRef = useRef();

  const componentRef = useRef();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.locations, dateDu, dateAu]);

  const init = () => {
    formRef.current.setFieldsValue({
      date_du: dayjs(),
      date_au: dayjs().add(30, "day"),
    });
    applyFilter();
  };

  const applyFilter = () => {
    let filtered = [];

    if (dateDu && dateAu) {
      if (props.locations) {
        filtered = props.locations
          .filter((e) => e.location_etat === "r")
          .filter(
            (item) =>
              dayjs(item.location_date_fin, "YYYY-MM-DD").isBetween(
                dayjs(dateDu, "DD/MM/YYYY"),
                dayjs(dateAu, "DD/MM/YYYY"),
                "days",
                "[]"
              ) ||
              dayjs(item.location_date_debut, "YYYY-MM-DD").isBetween(
                dayjs(dateDu, "DD/MM/YYYY"),
                dayjs(dateAu, "DD/MM/YYYY"),
                "days",
                "[]"
              )
          );
      }
    }

    const lignesCSV = filtered.map((ligne) => {
      return {
        location_id: ligne.location_id,
      };
    });

    setLocations(filtered);
    setCSV(lignesCSV);
  };

  const handleSave = (location) => {
    location.location_lot_id = location.location_lot?.lot_id;
    location.location_requete_id = location.location_requete?.requete_id;
    props.updateLocation(location, location.location_id);
  };

  const handleEmail = (requete) => {
    const pane = { key: "26", title: "", content: "" };
    props.addPanesRequete(pane, "26", requete);
  };

  const getStatusMail = (requete, id) => {
    return requete?.requete_emails?.some(
      (email) => email.email_template?.template_type_email_id === id
    )
      ? ""
      : "#ffb38a";
  };

  const onChangeDateDu = (dateDu) => {
    setDateDu(dateDu);
  };

  const onChangeDateAu = (dateAu) => {
    setDateAu(dateAu);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = tableOption().map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        select: col.select,
        datas: col.datas,
        handleSave: handleSave,
      }),
    };
  });

  typesEmails.slice(0, 3).forEach((typeEmail) => {
    columns.push({
      title: typeEmail.name,
      dataIndex: "location_requete",
      key: "location_requete",
      render: (requete) => (
        <Button onClick={() => handleEmail(requete)}>
          <MailTwoTone twoToneColor={getStatusMail(requete, typeEmail.id)} />
        </Button>
      ),
      width: "7%",
    });
  });

  return (
    <div style={{ padding: 20 }}>
      <Form ref={formRef}>
        <Row>
          <Title level={2}>SUIVI DES SÉJOURS</Title>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <CustomDatePicker
              label="Du"
              inputName="dateDu"
              formItemName="date_du"
              objectValue={dateDu}
              onChange={(e) => onChangeDateDu(e)}
            />
          </Col>
          <Col span={6}>
            <CustomDatePicker
              label="Au"
              inputName="dateAu"
              formItemName="date_au"
              objectValue={dateAu}
              onChange={(e) => onChangeDateAu(e)}
            />
          </Col>
        </Row>
      </Form>
      {locations.length > 0 && (
        <Row gutter={12} style={{ marginTop: 10 }}>
          <Col span={22}>
            <div ref={componentRef}>
              <Table
                className="list-data"
                components={components}
                dataSource={locations}
                columns={columns}
                pagination={true}
                rowKey={"location_id"}
              />
            </div>
          </Col>
          <Col span={2}>
            {lignesCSV && (
              <>
                <Row style={{ marginBottom: 10 }}>
                  <Button>
                    <CSVLink filename={"export.csv"} data={lignesCSV} separator={";"}>
                      Export CSV
                    </CSVLink>
                  </Button>
                </Row>
              </>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ListAltimmo;
