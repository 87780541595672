import React, { Component } from "react";

import { Dropdown, Tabs } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

class MyHeader extends Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 0;

    this.state = {
      panes: this.props.panes,
      activeKey: this.props.panes[0].key,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ panes: props.panes, activeKey: props.activeKey });
  }

  onChange = (activeKey) => {
    this.setState({ activeKey });
    this.props.syncState(this.state.panes, activeKey);
  };

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };

  remove = (targetKey) => {
    let activeKey = this.state.activeKey;
    let lastIndex;

    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const panes = this.state.panes.filter((pane) => pane.key !== targetKey);
    if (panes.length && activeKey === targetKey) {
      if (lastIndex >= 0) {
        activeKey = panes[lastIndex].key;
      } else {
        activeKey = panes[0].key;
      }
    }

    this.setState({ panes, activeKey });
    this.props.syncState(panes, activeKey);
  };

  handleCloseTabs = () => {
    const panes = [];

    this.setState({ panes });
    this.props.syncState(panes, this.state.activeKey);
  };

  add = () =>
    this.props.addPanesRequete(
      { key: "26", title: "Contrats / Disponibilités", content: "" },
      "26",
      null
    );

  render() {
    const { panes, activeKey } = this.state;

    const dropdownItems = [
      {
        key: "1",
        label: "Fermer tous les onglets",
        icon: <CloseCircleOutlined />,
        onClick: () => this.handleCloseTabs(),
      },
    ];

    const items = panes.map((pane) => {
      return { label: pane.title, key: pane.key, children: pane.content };
    });

    return (
      // <Header style={{ background: "#000", padding: 0, height: '100%' }}>
      <div style={{ padding: 0 }}>
        <Dropdown menu={{ items: dropdownItems }} trigger={["contextMenu"]}>
          <Tabs
            type="editable-card"
            className="tabs-panel-haut"
            activeKey={activeKey}
            onChange={this.onChange}
            onEdit={this.onEdit}
            items={items}
          ></Tabs>
        </Dropdown>
      </div>
      // </Header>
    );
  }
}

export default MyHeader;
