import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_TYPES_EMAILS = "LOAD_TYPES_EMAILS";
export const LOAD_SUCCESS_TYPES_EMAILS = "LOAD_SUCCESS_TYPES_EMAILS";
export const LOAD_FAIL_TYPES_EMAILS = "LOAD_FAIL_TYPES_EMAILS";
export const SAVE_SUCCESS_TYPES_EMAILS = "SAVE_SUCCESS_TYPES_EMAILS";
export const SAVE_FAIL_TYPES_EMAILS = "SAVE_FAIL_TYPES_EMAILS";
export const UPDATE_SUCCESS_TYPES_EMAILS = "UPDATE_SUCCESS_TYPES_EMAILS";
export const UPDATE_FAIL_TYPES_EMAILS = "UPDATE_FAIL_TYPES_EMAILS";
export const DELETE_SUCCESS_TYPES_EMAILS = "DELETE_SUCCESS_TYPES_EMAILS";
export const DELETE_FAIL_TYPES_EMAILS = "DELETE_FAIL_TYPES_EMAILS";

export function loadTypesEmails() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`typesEmails`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.type_email);
          dispatch({
            type: LOAD_SUCCESS_TYPES_EMAILS,
            payload: res.type_email,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_TYPES_EMAILS,
            error: err,
          });
        });
    });
  };
}

export function saveTypeEmail(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`typesEmails`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(jsonData) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.type_email);
          dispatch({
            type: SAVE_SUCCESS_TYPES_EMAILS,
            payload: res.type_email,
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_TYPES_EMAILS,
            error: err,
          })
        );
    });
  };
}

export function updateTypeEmail(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`typesEmails/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(jsonData) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.type_email);
          dispatch({
            type: UPDATE_SUCCESS_TYPES_EMAILS,
            payload: res.type_email,
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_TYPES_EMAILS,
            error: err,
          })
        );
    });
  };
}

export function deleteTypeEmail(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`typesEmails/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.type_email);
          dispatch({
            type: DELETE_SUCCESS_TYPES_EMAILS,
            payload: res.type_email,
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_TYPES_EMAILS,
            error: err,
          });
        });
    });
  };
}
