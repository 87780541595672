import React, { useRef, useState } from "react";
import { Button, Col, DatePicker, Form, Row, Select, Table, Typography } from "antd";
import CustomSelect from "../CustomFormItem/Select";
import CustomInput from "../CustomFormItem/Input";
import FloatLabel from "../FloatLabel";

import { toast } from "react-toastify";

import dayjs from "dayjs";
import CustomDatePicker from "../CustomFormItem/DatePicker";
const dateFormat = "DD/MM/YYYY";

const { OptGroup, Option } = Select;
const { Text, Title } = Typography;

const FormHonoraires = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [date, setDate] = useState(dayjs());
  const { comptes, ecritures, journals, words } = props;

  // Référence du formulaire
  const formRef = useRef();

  const getSoldeCompte = (id) => {
    return ecritures
      .filter((ecriture) =>
        dayjs(ecriture.ecriture_date_ecriture, "DD/MM/YYYY").isSameOrBefore(date)
      )
      .flatMap((ecriture) => ecriture.ecriture_ligne)
      .filter((ligne) => ligne.ecriture_ligne_compte_id === id)
      .reduce(
        (total, ligne) =>
          total +
          parseFloat(ligne.ecriture_ligne_montant_debit - ligne.ecriture_ligne_montant_credit),
        0
      );
  };

  const getTotalComptes = () => {
    return selectedRows
      .map((compte) => compte.compte_id)
      .reduce((total, compte_id) => {
        const compteTotal = getSoldeCompte(compte_id);
        return total + compteTotal;
      }, 0);
  };

  const onChangeDate = (e) => {
    setDate(e);
  };

  const handleOnFinish = (data) => {
    const journal = journals.find(
      (journal) => journal.journal_compte?.compte_id === data.compte_id
    );
    journal
      ? createEcriture(data, journal.journal_id)
      : toast.error("Aucun journal trouvé !", { containerId: "A" });
  };

  const createEcriture = (data, id) => {
    const ecritureLignes = [];
    let montant = 0;
    selectedRows.forEach((row) => {
      const soldeCompte = getSoldeCompte(row.compte_id);
      if (soldeCompte < 0) {
        const ligneDebit = {
          ecriture_ligne_libelle: row.compte_libelle,
          ecriture_ligne_montant_debit: parseFloat(Math.abs(soldeCompte)).toFixed(2),
          ecriture_ligne_montant_credit: 0,
          ecriture_ligne_compte_id: row.compte_id,
          ecriture_ligne_locataire_code: "",
          ecriture_ligne_en_reddition: false,
        };
        montant += parseFloat(soldeCompte);
        ecritureLignes.push(ligneDebit);
      }
    });
    const ligneCredit = {
      ecriture_ligne_libelle: data.libelle,
      ecriture_ligne_montant_debit: 0,
      ecriture_ligne_montant_credit: parseFloat(Math.abs(montant)).toFixed(2),
      ecriture_ligne_compte_id: data.compte_id,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    ecritureLignes.push(ligneCredit);
    const ecriture = {
      ecriture_journal_id: id,
      ecriture_date_ecriture: dayjs(data.date_ecriture).format("DD/MM/YYYY"),
      ecriture_libelle: data.libelle,
      ecriture_ligne: ecritureLignes,
    };
    props
      .saveEcriture(ecriture)
      .then(() => {
        reset();
        toast.success("Écriture créée !", { containerId: "A" });
      })
      .catch(() => toast.error("Erreur création écriture honoraires !", { containerId: "A" }));
  };

  const reset = () => {
    formRef.current.resetFields();
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  const handleCreatePdf = () => {
    setLoading(true);
    createPdf();
  };

  const createPdf = () => {
    setLoading(false);
  };

  let columns = [
    {
      title: "Compte",
      dataIndex: "compte_compte_general",
      key: "compte_compte_general",
      width: "25%",
    },
    {
      title: "Compte",
      dataIndex: "compte_libelle",
      key: "compte_libelle",
      width: "50%",
    },
    {
      title: "Solde",
      dataIndex: "compte_id",
      key: "solde",
      render: (id) =>
        parseFloat(getSoldeCompte(id)).toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        }),
      width: "25%",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    selectedRowKeys: selectedRowKeys,
    selectedRows: selectedRows,
  };

  return (
    <div style={{ padding: 20 }}>
      <Row>
        <Title level={2}>
          <strong>Versement des honoraires</strong>
        </Title>
      </Row>
      <Row>
        <Col span={6}>
          <FloatLabel label="Date butoir" name="date" value={date}>
            <DatePicker format={dateFormat} defaultValue={date} onChange={(e) => onChangeDate(e)} />
          </FloatLabel>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={24}>
          <Table
            dataSource={comptes.filter((compte) => compte.compte_honoraire)}
            columns={columns}
            rowKey="compte_id"
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            pagination={false}
            summary={() => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text style={{ float: "right" }}>
                        <strong>SOLDE DES COMPTES :</strong>
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text style={{ float: "right" }}>
                        <strong>
                          {parseFloat(getTotalComptes()).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </strong>
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </Col>
      </Row>
      <Form ref={formRef} onFinish={handleOnFinish} style={{ marginTop: 20 }}>
        <Row gutter={12}>
          <Col span={6}>
            <CustomSelect
              label="Compte"
              inputName="compte_id"
              formItemName="compte_id"
              rules={{ required: true, message: "Compte obligatoire" }}
            >
              <OptGroup label="Compte">
                {comptes &&
                  comptes
                    .filter((item) => item.compte_type_compte !== "A")
                    .map((item, i) => {
                      return (
                        <Option
                          key={i}
                          value={item.compte_id}
                          label={`${item.compte_compte_general} - ${item.compte_libelle}`}
                        >
                          <Row>
                            <Col
                              sm={8}
                            >{`${item.compte_compte_general} - ${item.compte_libelle}`}</Col>
                          </Row>
                        </Option>
                      );
                    })}
              </OptGroup>
            </CustomSelect>
          </Col>
          <Col offset={8} span={8}>
            <CustomSelect
              label="Choix modèle facture"
              inputName="pdf_template"
              formItemName="pdf_template"
            >
              <OptGroup label="Modèle">
                {words &&
                  words
                    .filter((item) => item.word_type === 6)
                    .map((item, i) => {
                      return (
                        <Option key={i} value={item.word_id} label={`${item.word_nom}`}>
                          <Row>
                            <Col sm={8}>{`${item.word_nom}`}</Col>
                          </Row>
                        </Option>
                      );
                    })}
              </OptGroup>
            </CustomSelect>
          </Col>
          <Col span={2}>
            <Button
              size="middle"
              type="primary"
              disabled={selectedRows.length === 0}
              loading={loading}
              onClick={() => handleCreatePdf()}
            >
              Générer PDF
            </Button>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <CustomDatePicker
              label="Date d'écriture"
              inputName="date_ecriture"
              formItemName="date_ecriture"
              rules={{ required: true, message: "Date obligatoire" }}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <CustomInput
              label="Libellé"
              inputName="libelle"
              formItemName="libelle"
              rules={{ required: true, message: "Libellé obligatoire" }}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col offset={6} span={2} align="right">
            <Button
              type="primary"
              htmlType="submit"
              disabled={selectedRows.length === 0}
              style={{ marginTop: 20 }}
            >
              Validation
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FormHonoraires;
