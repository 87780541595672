import { toast } from "react-toastify";
import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import {
  Row,
  Col,
  Form,
  Button,
  Select,
  Table,
  DatePicker,
  Modal,
  Spin,
  Typography,
  Popconfirm,
  Checkbox,
  List,
} from "antd";

import {
  ArrowRightOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";

import QuittancePDF from "../Pdf/QuittancePDF";
import Constants from "../../utils/constants";
import { formatDateToAPP, round } from "../../utils/tools";

import dayjs from "dayjs";
import { EditableCell, EditableRow } from "../EditableTable";
import { HttpMethod, initFetch } from "../../utils/fetcher";
import { openPdf } from "../../utils/utils";
const dateFormat = "DD/MM/YYYY";

const { Column } = Table;
const { Option, OptGroup } = Select;
const { Text } = Typography;

const update = require("immutability-helper");

class FormPdf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      modele: null,
      quittance: null,
      idPdf: null,
      rubriques: [],
      selectedRows: [],
      modalRubrique: false,
      modalPdf: false,
      documentWord: null,
      documentPdf: null,
      strQuittance: false,
      lignes: [],
      modification: false,
    };
  }

  formRef = React.createRef();

  componentDidMount() {
    this.init(
      this.props.words.filter((word) => word.word_type === 1),
      this.props.locationQuittances
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.locationQuittances !== this.props.locationQuittances) {
      this.formRef.current.resetFields(["quittances"]);
      this.init(
        nextProps.words.filter((word) => word.word_type === 1),
        nextProps.locationQuittances
      );
    }
  }

  init = (words, quittances) => {
    words.length > 0 && this.onSelectModele(words.sort((a, b) => a.word_id - b.word_id)[0].word_id);
    quittances.length > 0 &&
      this.onSelectQuittance(
        quittances.sort((a, b) => b.quittance_id - a.quittance_id)[0].quittance_id,
        quittances
      );
  };

  onSelectModele = (modele) => {
    this.formRef.current.setFieldsValue({
      pdf_template: modele,
    });
    this.setState({ modele });
  };

  onClearModele = () => {
    this.setState({ modele: null });
  };

  onSelectQuittance = (id, quittances) => {
    const quittance = quittances.filter((item) => {
      return item.quittance_id === id;
    })[0];
    quittance &&
      this.formRef.current.setFieldsValue({
        quittances: quittance.quittance_id,
        quittance_date_paiement: quittance.quittance_date_paiement
          ? dayjs(quittance.quittance_date_paiement, "YYYY-MM-DD")
          : null,
      });
    this.setState({ quittance, lignes: [], modification: false });
  };

  onClearQuittance = () => {
    this.setState({ quittance: null });
  };

  handleCreatePdf = () => {
    if (this.state.modele) {
      const word = this.props.words.find((word) => word.word_id === this.state.modele);
      this.createDocx(word);
    } else {
      toast.error("Veuillez sélectionner un modèle", { containerId: "A" });
    }
    this.setState({ loading: true });
  };

  handleEmail = () => {
    this.closeModal();
    this.props.onChangeTab("2", this.state.documentPdf);
  };

  handleSignature = (document) => {
    const { url, params } = initFetch(`documents/display/${document.document_id}`, HttpMethod.GET, {
      Authorization: true,
      formData: true,
      pdf: false,
    });
    fetch(url, params)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], `${document.document_nom}`);
        let formData = new FormData();
        formData.append("file", file);
        formData.append("nature", "signable_document");
        fetch("https://api.yousign.app/v3/documents", {
          method: "POST",
          headers: {
            Authorization: "Bearer 8lj0pevKaOYCEpceDU3P4hBMhw2kl9fA",
          },
          body: formData,
        })
          .then((res) => res.json())
          .then((res) => {
            // préparation de la requête
            let signers = [];
            if (this.props.utilisateur.societe_id === 19) {
              signers = [
                {
                  document_id: res.id,
                  type: "signature",
                  page: 4,
                  width: 180,
                  x: 300,
                  y: 730,
                },
                {
                  document_id: res.id,
                  type: "signature",
                  page: 5,
                  width: 180,
                  x: 300,
                  y: 730,
                },
              ];
            } else {
              signers = [
                {
                  document_id: res.id,
                  type: "signature",
                  page: 1,
                  width: 180,
                  x: 300,
                  y: 730,
                },
              ];
            }
            const dateExpiration = dayjs().add(7, "days").format("YYYY-MM-DD");
            const requete = {
              name: "Contrat de location",
              delivery_mode: "email",
              timezone: "Europe/Paris",
              documents: [res.id],
              expiration_date: dateExpiration,
              signers: [
                {
                  info: {
                    first_name:
                      this.props.location.location_requete.requete_locataire.locataire_personne
                        .personne_prenom,
                    last_name:
                      this.props.location.location_requete.requete_locataire.locataire_personne
                        .personne_nom,
                    email:
                      this.props.location.location_requete.requete_locataire.locataire_personne
                        .personne_email1,
                    phone_number: "+33673735003",
                    locale: "fr",
                  },
                  signature_level: "electronic_signature",
                  signature_authentication_mode: "no_otp",
                  fields: signers,
                },
              ],
            };
            fetch("https://api.yousign.app/v3/signature_requests", {
              method: "POST",
              headers: {
                Authorization: "Bearer 8lj0pevKaOYCEpceDU3P4hBMhw2kl9fA",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requete),
            })
              .then((res2) => res2.json())
              .then((res2) => {
                // activation de la requete
                const urlActivation =
                  "https://api.yousign.app/v3/signature_requests/" + res2.id + "/activate";
                fetch(urlActivation, {
                  method: "POST",
                  headers: {
                    Authorization: "Bearer 8lj0pevKaOYCEpceDU3P4hBMhw2kl9fA",
                  },
                })
                  .then((res3) => res3.json())
                  .then((res3) => {
                    // ajout followers
                    const follower = [
                      {
                        email: this.props.parametre.parametre_email,
                        locale: "fr",
                      },
                    ];
                    const urlFollower =
                      "https://api.yousign.app/v3/signature_requests/" + res2.id + "/followers";
                    fetch(urlFollower, {
                      method: "POST",
                      headers: {
                        Authorization: "Bearer 8lj0pevKaOYCEpceDU3P4hBMhw2kl9fA",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(follower),
                    })
                      .then((res4) => res4.json())
                      .then((res4) => {
                        toast.success("Invitation YOUSIGN envoyée !", { containerId: "A" });
                        // mise à jour documents
                        this.props.updateDocument(
                          {
                            document_nom: document.document_nom,
                            document_dispo_mail: false,
                            document_yousign_document_id: res.id,
                            document_yousign_requete_id: res3.id,
                            document_yousign_statut: res3.status,
                            document_utilisateur_id: this.props.utilisateur.utilisateur_nom,
                            document_locataire_id:
                              this.props.location.location_requete.requete_locataire.locataire_id,
                          },
                          document.document_id
                        );
                        // envoi mail de confirmation
                        this.envoiMail();
                      });
                  });
              });
          });
      });

    this.closeModal();
  };

  envoiMail = () => {
    const arrayDestinataire = [];
    arrayDestinataire.push({
      email: this.props.parametre.parametre_email,
      name: this.props.utilisateur.utilisateur_nom,
    });

    const objet = "Invitation YOUSIGN envoyée";
    const message =
      "Votre invitation a bien été envoyée au locataire suivant : " +
      "<br/>" +
      this.props.location.location_requete.requete_locataire.locataire_personne.personne_nom +
      " " +
      this.props.location.location_requete.requete_locataire.locataire_personne.personne_prenom +
      "<br/>" +
      this.props.location.location_requete.requete_locataire.locataire_personne.personne_email1;

    const jsonData = {
      email_objet: objet,
      email_message: message,
      email_destinataire: this.props.parametre.parametre_email,
      email_destinataire_array: arrayDestinataire,
      email_copie: "",
      email_copie_array: [],
      email_pieces_jointes: [],
      email_utilisateur_id: this.props.utilisateur.utilisateur_id,
      email_requete_id: this.props.location.location_requete.requete_id,
      email_lot_id: this.props.location.location_lot.lot_id,
      parametre_smtp: this.props.parametre.parametre_smtp,
      parametre_port: this.props.parametre.parametre_port,
      parametre_email: this.props.parametre.parametre_email,
      parametre_mdp: this.props.parametre.parametre_mdp,
      parametre_copie_agence: this.props.parametre.parametre_copie_agence,
      parametre_email_copie: this.props.parametre.parametre_email_copie,
    };

    toast.info("Envoi en cours...", {
      containerId: "A",
    });

    // Envoi du mail puis sauvegarde de celui-ci
    this.props.saveEmail(jsonData);
  };

  convertToPlain(rtf) {
    // rtf = rtf.replace(/\\par[d]?/g, "");
    // return rtf.replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "").trim();
    let txt = rtf.replace(/<br ?\/?>/g, "\n");
    return txt.replace(/(<([^>]+)>)/gi, "");
  }

  createDocx = (word) => {
    const location = this.props.location;
    const idLot = this.props.location.location_lot.lot_id;
    const lot = this.props.lots.find((item) => item.lot_id === idLot);
    const reglements = this.props.reglements;
    const quittances = this.props.locationQuittances;
    const quittanceSelected = this.state.quittance;

    let total = 0;
    let totalPermanente = 0;
    let sejour = 0;
    let acompte = 0;
    let loyer = 0;
    let fraisAgence = 0;
    let assurance = 0;
    let totalFraisAgence = 0;
    let caution = 0;
    let menage = 0;
    let remiseProp = 0;
    let remiseAgence = 0;
    let fraisDossier = 0;
    let totalHorsFraisDossier = 0;
    let totalHorsTaxeEtFraisDossier = 0;
    let soldeSejour = 0;
    let solde = 0;

    location.location_ligne.forEach((ligne) => {
      total += parseFloat(ligne.location_ligne_montant);
      if (ligne.location_ligne_permanente)
        totalPermanente += parseFloat(ligne.location_ligne_montant);
      acompte +=
        (parseFloat(ligne.location_ligne_montant) *
          parseFloat(ligne.location_ligne_pourcentage_acompte)) /
        100;
      if (ligne.location_ligne_rubrique.rubrique_taxe_de_sejour === 1) {
        sejour = parseFloat(ligne.location_ligne_montant);
      }
      if (ligne.location_ligne_rubrique.rubrique_code === "01") {
        loyer = parseFloat(ligne.location_ligne_montant);
      }
      if (ligne.location_ligne_rubrique.rubrique_code === "02") {
        fraisAgence = parseFloat(ligne.location_ligne_montant);
      }
      if (ligne.location_ligne_rubrique.rubrique_code === "03") {
        assurance = parseFloat(ligne.location_ligne_montant);
      }
      if (ligne.location_ligne_rubrique.rubrique_code === "05") {
        menage = parseFloat(ligne.location_ligne_montant);
      }
      if (ligne.location_ligne_rubrique.rubrique_code === "07") {
        fraisDossier = parseFloat(ligne.location_ligne_montant);
      }
      if (ligne.location_ligne_rubrique.rubrique_code === "14") {
        caution = parseFloat(ligne.location_ligne_montant);
      }
      if (ligne.location_ligne_rubrique.rubrique_code === "20") {
        remiseProp = parseFloat(ligne.location_ligne_montant);
      }
      if (ligne.location_ligne_rubrique.rubrique_code === "21") {
        remiseAgence = parseFloat(ligne.location_ligne_montant);
      }
    });
    totalFraisAgence = fraisAgence + assurance;
    totalHorsFraisDossier = total - fraisDossier;
    totalHorsTaxeEtFraisDossier = total - sejour - fraisDossier;
    soldeSejour = total - Math.round(acompte, 0);

    const quittancesFilteredId = quittances?.map((quittance) => quittance.quittance_id);
    const reglementsFiltered = reglements?.filter((reglement) =>
      quittancesFilteredId?.includes(reglement.reglement_quittance.quittance_id)
    );

    const totalReglements = reglementsFiltered.reduce(
      (a, b) => a + parseFloat(b.reglement_montant),
      0
    );

    solde = total - totalReglements;

    const totalQuittanceSelected = quittanceSelected?.quittance_ligne.reduce(
      (a, b) => a + parseFloat(b.quittance_ligne_montant),
      0
    );

    let occupants = "";
    let compteur = 0;
    location.location_occupant.forEach((occupant) => {
      occupants += `\n ${occupant.occupant_nom} ${occupant.occupant_prenom} née le ${
        occupant.occupant_date_naissance
          ? dayjs(occupant.occupant_date_naissance).format("DD/MM/YYYY")
          : ""
      } à ${occupant.occupant_lieu_naissance ? occupant.occupant_lieu_naissance : ""}`;
    });

    compteur =
      parseFloat(location.location_nb_adultes) +
      parseFloat(location.location_nb_enfants) +
      parseFloat(location.location_nb_bebes);

    let champ_perso_1128 = lot.lot_elements.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 1128
    );

    let champ_perso_2299 = lot.lot_elements.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 2299
    );

    let champ_perso_3181 = lot.lot_elements.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3181
    );

    let champ_perso_3415 = lot.lot_elements.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3415
    );

    let champ_perso_3416 = lot.lot_elements.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3416
    );

    let champ_perso_3417 = lot.lot_elements.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3417
    );

    let champ_perso_3418 = lot.lot_elements.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3418
    );

    let champ_perso_3431 = lot.lot_elements.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3431
    );

    let champ_perso_3432 = lot.lot_elements.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3432
    );

    let champ_perso_3434 = lot.lot_elements.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3434
    );

    let champ_perso_3438 = lot.lot_elements.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3438
    );

    let champ_perso_3719 = lot.lot_elements.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3719
    );

    let champ_perso_3720 = lot.lot_elements.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3720
    );

    const strDate = dayjs().format("YYYY-MM-DD_HH-mm-ss");
    let documentName = `${this.props.utilisateur.utilisateur_etablissement.etablissement_societe.societe_raison_sociale}-${location.location_lot.lot_reference}-${location.location_requete.requete_locataire.locataire_personne.personne_nom}-${location.location_requete.requete_locataire.locataire_personne.personne_prenom}-${strDate}`;
    documentName = documentName.replace("&", "").replace(/\s/g, "-");

    let quittancesLignes = [];
    let datePaiementAttendu = null;
    if (quittances && quittances.length > 0) {
      const lastQuittance = quittances.slice(-1);
      datePaiementAttendu = lastQuittance[0].quittance_date_paiement;
      lastQuittance[0].quittance_ligne.forEach((ligne) => {
        quittancesLignes.push(ligne);
      });
    }

    let blocQuittance = "";
    quittancesLignes.forEach((ligne) => {
      blocQuittance += `${ligne.quittance_ligne_rubrique.rubrique_libelle} : ${parseFloat(
        ligne.quittance_ligne_montant
      ).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}<br />`;
    });

    let blocTarif = "";
    location.location_ligne.forEach((ligne) => {
      blocTarif += `${ligne.location_ligne_rubrique.rubrique_libelle} : ${parseFloat(
        ligne.location_ligne_montant
      ).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}<br />`;
    });

    let blocTarifProrata = "";
    let totalProrata = 0;
    const dateDebut = dayjs(location.location_date_debut).format("DD/MM/YYYY");
    const dateFin = this.getDateFin(location);
    const nbJours = dayjs(dateFin, "DD/MM/YYYY").diff(dayjs(dateDebut, "DD/MM/YYYY"), "days") + 1;
    const nbJoursInMonth = dayjs(dateDebut, "DD/MM/YYYY").daysInMonth();
    location.location_ligne.forEach((ligne) => {
      const montantLigne = !ligne.location_ligne_rubrique.rubrique_montant_fixe
        ? nbJours !== nbJoursInMonth
          ? round((ligne.location_ligne_montant / nbJoursInMonth) * nbJours, 2)
          : round(ligne.location_ligne_montant, 2)
        : round(ligne.location_ligne_montant, 2);
      totalProrata += parseFloat(montantLigne);
      blocTarifProrata += `${ligne.location_ligne_rubrique.rubrique_libelle} : ${parseFloat(
        montantLigne
      ).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}<br />`;
    });

    let blocTarifPermanente = "";
    location.location_ligne
      .filter((ligne) => ligne.location_ligne_permanente)
      .forEach((ligne) => {
        blocTarifPermanente += `${ligne.location_ligne_rubrique.rubrique_libelle} : ${parseFloat(
          ligne.location_ligne_montant
        ).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}<br />`;
      });

    let blocMatrice = "";
    lot.lot_matrice?.matrice_ligne.forEach((ligne) => {
      blocMatrice += `${ligne.matrice_ligne_rubrique.rubrique_libelle} : ${parseFloat(
        ligne.matrice_ligne_montant_mois
      ).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}<br />`;
    });

    const data = {
      document_nom: `${documentName}.pdf`,
      document_word_nom: `${documentName}.docx`,
      document_dossier_id: this.props.dossier.dossier_id,
      document_tag_id: word.word_tag_id ? word.word_tag_id : this.props.tags[0].tag_id,
      date_J7: dayjs().add(7, "days").format("DD/MM/YYYY"),
      proprietaire_qualite:
        lot.lot_proprietaire.proprietaire_personne.personne_qualite.qualite_libelle,
      proprietaire_nom: lot.lot_proprietaire.proprietaire_personne.personne_nom,
      proprietaire_prenom: lot.lot_proprietaire.proprietaire_personne.personne_prenom,
      proprietaire_adresse:
        lot.lot_proprietaire.proprietaire_personne.personne_adresse.adresse_gmaps_adresse !== null
          ? lot.lot_proprietaire.proprietaire_personne.personne_adresse.adresse_gmaps_adresse
          : "",
      proprietaire_cp:
        lot.lot_proprietaire.proprietaire_personne.personne_adresse.adresse_code_postal,
      proprietaire_ville: lot.lot_proprietaire.proprietaire_personne.personne_adresse.adresse_ville,
      proprietaire_tel: lot.lot_proprietaire.proprietaire_personne.personne_tel1,
      proprietaire_tel2: lot.lot_proprietaire.proprietaire_personne.personne_tel2,
      proprietaire_mail: lot.lot_proprietaire.proprietaire_personne.personne_email1,
      proprietaire_mail2: lot.lot_proprietaire.proprietaire_personne.personne_email2,
      lot_reference: lot.lot_reference,
      lot_immeuble: lot.lot_immeuble?.immeuble_nom,
      lot_code_porte: lot.lot_code_porte,
      lot_type: lot.lot_type_lot?.type_lot_libelle,
      lot_surface: lot.lot_surface,
      lot_etage: lot.lot_etage,
      lot_nb_chambres: lot.lot_nb_chambres,
      lot_couchages: lot.lot_couchage,
      lot_adresse:
        lot.lot_adresse.adresse_gmaps_adresse !== null ? lot.lot_adresse.adresse_gmaps_adresse : "",
      lot_cp: lot.lot_adresse.adresse_code_postal,
      lot_ville: lot.lot_adresse.adresse_ville,
      lot_situation: "",
      lot_champs_persos: "",
      lot_equipements: "",
      lot_description: lot.lot_descriptions[0]
        ? this.convertToPlain(lot.lot_descriptions[0].description_html)
        : "",
      lot_numero_mandat: lot.lot_numero_mandat,
      lot_date_mandat: dayjs(lot.lot_date_signature_mandat).format("DD/MM/YYYY"),
      lot_photos: lot.lot_photos.sort((a, b) => a.photo_ordre - b.photo_ordre),
      lot_bloc_matrice: this.convertToPlain(blocMatrice),
      locataire_qualite:
        location.location_requete.requete_locataire.locataire_personne.personne_qualite
          .qualite_libelle,
      locataire_nom: location.location_requete.requete_locataire.locataire_personne.personne_nom,
      locataire_prenom:
        location.location_requete.requete_locataire.locataire_personne.personne_prenom,
      locataire_adresse:
        location.location_requete.requete_locataire.locataire_personne.personne_adresse
          .adresse_gmaps_adresse !== null
          ? location.location_requete.requete_locataire.locataire_personne.personne_adresse
              .adresse_gmaps_adresse
          : "",
      locataire_cp:
        location.location_requete.requete_locataire.locataire_personne.personne_adresse
          .adresse_code_postal,
      locataire_ville:
        location.location_requete.requete_locataire.locataire_personne.personne_adresse
          .adresse_ville,
      locataire_pays:
        location.location_requete.requete_locataire.locataire_personne.personne_adresse
          .adresse_pays,
      locataire_tel: location.location_requete.requete_locataire.locataire_personne.personne_tel1,
      locataire_tel2: location.location_requete.requete_locataire.locataire_personne.personne_tel2,
      locataire_mail:
        location.location_requete.requete_locataire.locataire_personne.personne_email1,
      locataire_mail2:
        location.location_requete.requete_locataire.locataire_personne.personne_email2,
      location_date_debut: dayjs(location.location_date_debut).format("DD/MM/YYYY"),
      location_date_fin: dayjs(location.location_date_fin).format("DD/MM/YYYY"),
      location_loyer: parseFloat(loyer).toFixed(2),
      location_charges: "",
      location_frais_agence: parseFloat(totalFraisAgence).toFixed(2),
      location_taxe_de_sejour: parseFloat(sejour).toFixed(2),
      location_caution: parseFloat(caution).toFixed(2),
      location_total_prix: parseFloat(total).toFixed(2),
      location_total_prix_prorata: parseFloat(totalProrata).toFixed(2),
      location_total_prix_permanente: parseFloat(totalPermanente).toFixed(2),
      location_total_acompte: Math.round(acompte, 0).toFixed(2),
      location_total_quittance: parseFloat(totalQuittanceSelected).toFixed(2),
      location_occupants: occupants,
      location_commission: parseFloat(fraisAgence).toFixed(2),
      location_assurance: parseFloat(assurance).toFixed(2),
      location_menage: parseFloat(menage).toFixed(2),
      location_remise_prop: parseFloat(remiseProp).toFixed(2),
      location_remise_agence: parseFloat(remiseAgence).toFixed(2),
      location_nombre_occupants: parseFloat(compteur),
      location_nombre_adultes: location.location_nb_adultes,
      location_nombre_enfants: location.location_nb_enfants,
      location_nombre_bebes: location.location_nb_bebes,
      location_frais_dossier: fraisDossier,
      location_total_hors_frais_dossier: totalHorsFraisDossier,
      location_solde_sejour: parseFloat(soldeSejour).toFixed(2),
      location_nombre_nuits: dayjs(this.props.location.location_date_fin).diff(
        dayjs(this.props.location.location_date_debut),
        "days"
      ),
      // A REPRENDRE DANS ASTER TEMPLATES
      location_date_acompte: dayjs(this.props.location.location_date_debut)
        .subtract(6, "month")
        .format("DD/MM/YYYY"),
      location_date_solde: dayjs(this.props.location.location_date_debut)
        .subtract(30, "days")
        .format("DD/MM/YYYY"),
      location_provenance: location.location_requete.requete_provenance
        ? location.location_requete.requete_provenance.provenance_libelle
        : "",
      location_groupe: location.location_requete.requete_groupe
        ? location.location_requete.requete_groupe.groupe_libelle
        : "",
      location_creation_requete: dayjs(location.location_requete.requete_created).format(
        "DD/MM/YYYY"
      ),
      location_numero_requete: location.location_requete.requete_id,
      location_numero_contrat: location.location_id,
      location_numero_quittance: quittanceSelected?.quittance_id,
      location_date_fin_option: dayjs(this.props.location.location_date_fin_option).format(
        "DD/MM/YYYY"
      ),
      location_montant_plateforme: parseFloat(location.location_montant_plateforme).toFixed(2),
      proprietaire_adresse_numero:
        lot.lot_proprietaire.proprietaire_personne.personne_adresse.adresse_numero,
      proprietaire_adresse_voie:
        lot.lot_proprietaire.proprietaire_personne.personne_adresse.adresse_voie,
      proprietaire_adresse_complement:
        lot.lot_proprietaire.proprietaire_personne.personne_adresse.adresse_situation,
      locataire_adresse_numero:
        location.location_requete.requete_locataire.locataire_personne.personne_adresse
          .adresse_numero,
      locataire_adresse_voie:
        location.location_requete.requete_locataire.locataire_personne.personne_adresse
          .adresse_voie,
      locataire_adresse_complement:
        location.location_requete.requete_locataire.locataire_personne.personne_adresse
          .adresse_situation,
      lot_adresse_numero: lot.lot_adresse.adresse_numero,
      lot_adresse_voie: lot.lot_adresse.adresse_voie,
      lot_adresse_complement: lot.lot_adresse.adresse_situation,
      lot_depot_garantie: lot.lot_depot_garantie,
      champ_perso_1128: champ_perso_1128 ? champ_perso_1128.lot_champ_perso_valeur : "",
      champ_perso_2299: champ_perso_2299 ? champ_perso_2299.lot_champ_perso_valeur : "",
      champ_perso_3181: champ_perso_3181 ? champ_perso_3181.lot_champ_perso_valeur : "",
      champ_perso_3415: champ_perso_3415 ? champ_perso_3415.lot_champ_perso_valeur : "",
      champ_perso_3416: champ_perso_3416 ? champ_perso_3416.lot_champ_perso_valeur : "",
      champ_perso_3417: champ_perso_3417 ? champ_perso_3417.lot_champ_perso_valeur : "",
      champ_perso_3418: champ_perso_3418 ? champ_perso_3418.lot_champ_perso_valeur : "",
      champ_perso_3431: champ_perso_3431
        ? this.convertToPlain(champ_perso_3431.lot_champ_perso_valeur)
        : "",
      champ_perso_3432: champ_perso_3432
        ? this.convertToPlain(champ_perso_3432.lot_champ_perso_valeur)
        : "",
      champ_perso_3434: champ_perso_3434
        ? this.convertToPlain(champ_perso_3434.lot_champ_perso_valeur)
        : "",
      champ_perso_3438: champ_perso_3438
        ? this.convertToPlain(champ_perso_3438.lot_champ_perso_valeur)
        : "",
      champ_perso_3719: champ_perso_3719 ? champ_perso_3719.lot_champ_perso_valeur : "",
      champ_perso_3720: champ_perso_3720 ? champ_perso_3720.lot_champ_perso_valeur : "",
      location_total_hors_taxe_et_frais_dossier: parseFloat(totalHorsTaxeEtFraisDossier).toFixed(2),
      location_desiderata: location.location_desiderata,
      location_remarques: location.location_remarques,
      proprietaire_portable1: lot.lot_proprietaire.proprietaire_personne.personne_portable1,
      proprietaire_portable2: lot.lot_proprietaire.proprietaire_personne.personne_portable2,
      locataire_portable1:
        location.location_requete.requete_locataire.locataire_personne.personne_portable1,
      locataire_portable2:
        location.location_requete.requete_locataire.locataire_personne.personne_portable2,
      location_deja_regle: parseFloat(totalReglements).toFixed(2),
      location_solde: parseFloat(solde).toFixed(2),
      location_bloc_quittance: this.convertToPlain(blocQuittance),
      location_bloc_tarif: this.convertToPlain(blocTarif),
      location_bloc_tarif_prorata: this.convertToPlain(blocTarifProrata),
      location_bloc_tarif_permanente: this.convertToPlain(blocTarifPermanente),
      date_paiement_attendu: datePaiementAttendu
        ? dayjs(datePaiementAttendu).format("DD/MM/YYYY")
        : "",
    };

    this.props.rubriques.forEach((rubrique) => {
      data[`rubrique_libelle_${rubrique.rubrique_id}`] = rubrique.rubrique_libelle;
    });

    this.props.rubriques.forEach((rubrique) => {
      const montantTTC = this.getLocationLigneMontant(
        location.location_ligne,
        rubrique.rubrique_id
      );
      const montantHT = this.getMontantHT(montantTTC, 0.2);
      const montantTVA = montantTTC - montantHT;
      data[`location_rubrique_${rubrique.rubrique_id}`] = montantTTC.toFixed(2);
      data[`location_rubrique_${rubrique.rubrique_id}_HT`] = montantHT.toFixed(2);
      data[`location_rubrique_${rubrique.rubrique_id}_TVA`] = montantTVA.toFixed(2);
    });

    if (quittances.length > 0) {
      this.props.rubriques.forEach((rubrique) => {
        const montantTTC = this.getQuittanceLigneMontant(
          quittances[quittances.length - 1].quittance_ligne,
          rubrique.rubrique_id
        );
        const montantHT = this.getMontantHT(montantTTC, 0.2);
        const montantTVA = montantTTC - montantHT;
        data[`quittance_rubrique_${rubrique.rubrique_id}`] = montantTTC;
        data[`quittance_rubrique_${rubrique.rubrique_id}_HT`] = montantHT;
        data[`quittance_rubrique_${rubrique.rubrique_id}_TVA`] = montantTVA;
      });
    }

    this.props.champsPersosGroupes.forEach((groupe) => {
      data[`champ_perso_groupe_${groupe.champ_perso_groupe_id}`] = this.getBlocGroupes(
        lot,
        groupe.champ_perso_groupe_id
      );
    });

    this.props.processWord(data, word.word_id, this.props.dossier.dossier_id).then((res) => {
      this.setState({
        idPdf: null,
        documentWord: null,
        documentPdf: res,
        modalPdf: true,
        loading: false,
        strQuittance: false,
      });
    });
  };

  getDateFin = (location) => {
    let dateFin = location.location_date_fin;
    if (location.location_type_contrat === 1 || location.location_type_contrat === 2) {
      dateFin = dayjs(location.location_date_fin).isAfter(
        dayjs(location.location_date_debut).endOf("month")
      )
        ? dayjs(location.location_date_debut).endOf("month").format("YYYY-MM-DD")
        : location.location_date_fin;
    }
    return dayjs(dateFin).format("DD/MM/YYYY");
  };

  getLocationLigneMontant = (locationLignes, id) => {
    const ligne = locationLignes.find((ligne) => ligne.location_ligne_rubrique.rubrique_id === id);
    return ligne ? parseFloat(ligne.location_ligne_montant) : parseFloat(0);
  };

  getQuittanceLigneMontant = (quittanceLignes, id) => {
    const ligne = quittanceLignes.find(
      (ligne) => ligne.quittance_ligne_rubrique.rubrique_id === id
    );
    return ligne ? parseFloat(ligne.quittance_ligne_montant) : parseFloat(0);
  };

  getMontantHT = (montant, tauxTVA) => {
    return parseFloat(montant / (1 + tauxTVA));
  };

  getBlocGroupes = (lot, id) => {
    let champ_perso_groupe = "";
    lot.lot_elements.forEach((element) => {
      if (
        element.lot_champ_perso_champ_perso.champ_perso_groupe === id &&
        element.lot_champ_perso_valeur !== "" &&
        element.lot_champ_perso_valeur !== "false"
      ) {
        champ_perso_groupe +=
          element.lot_champ_perso_champ_perso.champ_perso_type === 1
            ? `${element.lot_champ_perso_champ_perso.champ_perso_libelle}\n`
            : `${
                element.lot_champ_perso_champ_perso.champ_perso_libelle
              }: ${this.getChampPersoValue(element.lot_champ_perso_valeur)}\n`;
      }
    });
    return champ_perso_groupe;
  };

  getChampPersoValue = (input) => {
    let value = "";
    switch (input) {
      case "true":
        value = "Oui";
        break;
      case "false":
        value = "Non";
        break;
      default:
        value = input;
        break;
    }
    return value;
  };

  getFileFromUrl = (id, quittance = false) => {
    const { url, params } = initFetch(
      `pdf/document_b.php?num=${id}&name=document`,
      HttpMethod.GET,
      { Authorization: true, formData: false, pdf: true }
    );
    fetch(url, params)
      .then((e) => {
        return e.blob();
      })
      .then((blob) => {
        let b = blob;
        b.lastModifiedDate = new Date();
        b.name = `${quittance ? "Facture" : "Contrat"}-${id}.pdf`;

        if (this.props.tags.length > 0) {
          this.props
            .saveDocument({ file: b }, this.props.dossier, this.props.tags[0])
            .then((res) => this.setState({ documentPdf: res, loading: false }));
        }
      });
  };

  closeModal = (document = null, deleteDoc = false) => {
    document && deleteDoc && this.props.deleteDocument(document.document_id);
    this.setState({ modalPdf: false });
  };

  createPdfQuittance = () => {
    const facture = !(
      this.props.location.location_type_contrat === 1 ||
      this.props.location.location_type_contrat === 2
    );
    const reglements = this.props.reglements.filter(
      (reg) => reg.reglement_quittance.quittance_id === this.state.quittance.quittance_id
    );
    const html = ReactDOMServer.renderToStaticMarkup(
      <QuittancePDF
        location={this.props.location}
        quittance={this.state.quittance}
        rubriques={this.props.rubriques}
        reglements={reglements}
        utilisateur={this.props.utilisateur}
      />
    );
    const societe = this.props.utilisateur.utilisateur_etablissement.etablissement_societe;
    const pdf = {
      titre: facture ? "Facture" : "Quittance",
      destinataire: `${societe.societe_zone_libre_1 ? societe.societe_zone_libre_1 + "<br/>" : ""}${
        societe.societe_zone_libre_2 ? societe.societe_zone_libre_2 + "<br/>" : ""
      }${societe.societe_zone_libre_3 ? societe.societe_zone_libre_3 + "<br/>" : ""}${
        societe.societe_zone_libre_4 ? societe.societe_zone_libre_4 + "<br/>" : ""
      }${societe.societe_zone_libre_5 ? societe.societe_zone_libre_5 + "<br/>" : ""}`,
      pdf_page: [],
    };
    const pdfPage = {
      html: html,
    };
    pdf.pdf_page.push(pdfPage);
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (result) {
          this.setState(
            {
              idPdf: result.pdf.id,
              modalPdf: true,
              loading: true,
              strQuittance: true,
            },
            () => this.getFileFromUrl(result.pdf.id, true)
          );
        }.bind(this)
      );
  };

  handleUpdateQuittance = (quittance) => {
    this.annulationEcriture(quittance);
    this.updateQuittance(quittance);
    this.setState({ lignes: [], modification: false });
    toast.success(`La quittance a été modifiée`, {
      containerId: "A",
    });
  };

  updateQuittance = (quittance) => {
    let montant = 0;
    // find prev quittance
    const prevQuittance = this.props.locationQuittances.find(
      (prev) => prev.quittance_id === quittance.quittance_id
    );
    quittance.quittance_ligne.forEach((ligne) => {
      const prevQuittanceLigne = prevQuittance.quittance_ligne.find(
        (prev) =>
          prev.quittance_ligne_rubrique.rubrique_id === ligne.quittance_ligne_rubrique.rubrique_id
      );
      if (!prevQuittanceLigne) {
        // save new ligne quittance
        const newQuittanceLigne = {
          quittance_ligne_quantite: 1,
          quittance_ligne_libelle: ligne.quittance_ligne_libelle
            ? ligne.quittance_ligne_libelle
            : ligne.quittance_ligne_rubrique.rubrique_libelle,
          quittance_ligne_montant: round(ligne.quittance_ligne_montant, 2),
          quittance_ligne_quittance_id: quittance.quittance_id,
          quittance_ligne_rubrique_id: ligne.quittance_ligne_rubrique.rubrique_id,
        };
        this.props.saveQuittanceLigne(newQuittanceLigne);
      } else if (
        ligne.quittance_ligne_montant !== prevQuittanceLigne.quittance_ligne_montant ||
        ligne.quittance_ligne_libelle !== prevQuittanceLigne.quittance_ligne_libelle
      ) {
        // update ligne quittance
        this.props.updateQuittanceLigne(
          {
            quittance_ligne_libelle: ligne.quittance_ligne_libelle
              ? ligne.quittance_ligne_libelle
              : ligne.quittance_ligne_rubrique.rubrique_libelle,
            quittance_ligne_montant: round(ligne.quittance_ligne_montant, 2),
            quittance_ligne_quantite: prevQuittanceLigne.quittance_ligne_quantite,
            quittance_ligne_quittance_id: prevQuittanceLigne.quittance_ligne_quittance_id,
          },
          prevQuittanceLigne.quittance_ligne_id
        );
      }
      montant += round(ligne.quittance_ligne_montant, 2);
    });
    prevQuittance.quittance_ligne.forEach((ligne) => {
      const quittanceLigne = quittance.quittance_ligne.find(
        (curr) =>
          curr.quittance_ligne_rubrique.rubrique_id === ligne.quittance_ligne_rubrique.rubrique_id
      );
      if (!quittanceLigne) {
        this.props.deleteQuittanceLigne(ligne.quittance_ligne_id);
      }
    });
    this.createEcriture(
      quittance,
      this.props.location.location_requete.requete_locataire.locataire_affectations[0]
        .locataire_affectation_compte_id,
      round(montant, 2)
    );
  };

  createEcriture = (quittance, idCompte, montant) => {
    const ecritureLignes = [];
    const location = this.props.location;
    const ligneDebit = {
      ecriture_ligne_libelle: `Modification Contrat n°${location.location_id} du ${dayjs(
        location.location_date_debut
      ).format("DD/MM/YYYY")} au ${dayjs(location.location_date_fin).format("DD/MM/YYYY")} (${
        location.location_requete.requete_locataire.locataire_personne.personne_nom
      })`,
      ecriture_ligne_montant_debit: montant,
      ecriture_ligne_montant_credit: 0,
      ecriture_ligne_compte_id: idCompte,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    const ligneCredit = {
      ecriture_ligne_libelle: `Modification Contrat n°${location.location_id} du ${dayjs(
        location.location_date_debut
      ).format("DD/MM/YYYY")} au ${dayjs(location.location_date_fin).format("DD/MM/YYYY")} (${
        location.location_requete.requete_locataire.locataire_personne.personne_nom
      })`,
      ecriture_ligne_montant_debit: 0,
      ecriture_ligne_montant_credit: montant,
      ecriture_ligne_compte_id: this.props.comptes.find(
        (compte) => compte.compte_compte_general === "480000"
      ).compte_id,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    ecritureLignes.push(ligneDebit);
    ecritureLignes.push(ligneCredit);
    const ecriture = {
      ecriture_journal_id: this.props.journals.find((journal) => journal.journal_code === "48")
        .journal_id,
      ecriture_date_ecriture: dayjs().format("DD/MM/YYYY"),
      ecriture_libelle: `Modification Contrat n°${location.location_id} du ${dayjs(
        location.location_date_debut
      ).format("DD/MM/YYYY")} au ${dayjs(location.location_date_fin).format("DD/MM/YYYY")} (${
        location.location_requete.requete_locataire.locataire_personne.personne_nom
      })`,
      ecriture_action: `QUI${quittance.quittance_id}`,
      ecriture_ligne: ecritureLignes,
    };
    this.props
      .saveEcriture(ecriture)
      .then((res) => this.props.resync())
      .catch((err) => toast.error("Erreur création écriture !", { containerId: "A" }));
  };

  annulationEcriture = (quittance) => {
    const ecrituresFiltered = this.props.ecritures
      .sort((a, b) => a.ecriture_id - b.ecriture_id)
      .filter((ecriture) =>
        ecriture.ecriture_libelle.includes(`Contrat n°${quittance.quittance_location_id}`)
      );
    const ecriture = ecrituresFiltered[ecrituresFiltered.length - 1];
    ecriture && this.createEcritureAnnulation(ecriture);
  };

  createEcritureAnnulation = (ecriture) => {
    const ecritureLignes = [];
    ecriture.ecriture_ligne.forEach((ligne) => {
      const ligneAnnulation = {
        ecriture_ligne_libelle: "Annulation " + ligne.ecriture_ligne_libelle,
        ecriture_ligne_montant_debit: ligne.ecriture_ligne_montant_credit,
        ecriture_ligne_montant_credit: ligne.ecriture_ligne_montant_debit,
        ecriture_ligne_compte_id: ligne.ecriture_ligne_compte_id,
        ecriture_ligne_locataire_code: "",
        ecriture_ligne_en_reddition: false,
      };
      ecritureLignes.push(ligneAnnulation);
    });
    const ecritureAnnulation = {
      ecriture_journal_id: ecriture.ecriture_journal.journal_id,
      ecriture_date_ecriture: ecriture.ecriture_date_ecriture,
      ecriture_libelle: "Annulation " + ecriture.ecriture_libelle,
      ecriture_action: ecriture.ecriture_action,
      ecriture_ligne: ecritureLignes,
    };
    this.props
      .saveEcriture(ecritureAnnulation)
      .catch((err) => toast.error("Erreur création écriture d'annulation !", { containerId: "A" }));
  };

  updateDatePaiementQuittance = (date) => {
    // console.log(date, "date");
  };

  handleDeleteQuittance = (id) => {
    const reglements = this.props.reglements.filter((reg) => reg.reglement_quittance_id === id);
    if (!reglements.length) {
      const ecriture = this.props.ecritures.find(
        (ecriture) =>
          ecriture.ecriture_action === `QUI${id}` &&
          !ecriture.ecriture_libelle.includes("Annulation")
      );
      ecriture && this.createEcritureAnnulation(ecriture);
      this.props
        .deleteQuittance(id)
        .then(() => {
          this.formRef.current.resetFields(["quittances"]);
          this.setState({ quittance: null });
          toast.success("Quittance annulée !", { containerId: "A" });
        })
        .catch((err) => console.log("err", err));
    } else {
      toast.error(
        "Il existe un règlement affecté à cette quittance, annulation impossible. Veuillez annuler cet encaissement avant de supprimer la quittance",
        { containerId: "A" }
      );
    }
  };

  handleSubmitAddRubriques = () => {
    const lignes = [];
    this.state.selectedRows.forEach((rubrique) => {
      lignes.push({
        quittance_ligne_id: `a${rubrique.rubrique_id}`,
        quittance_ligne_libelle: rubrique.rubrique_libelle,
        quittance_ligne_montant: 0,
        quittance_ligne_rubrique: rubrique,
      });
    });
    this.setState((prevState) => ({
      quittance: update(prevState.quittance, {
        quittance_ligne: { $push: lignes },
      }),
      lignes: update(prevState.lignes, {
        $push: lignes.map((l) => l.quittance_ligne_id),
      }),
      modification: true,
    }));
    this.closeModalRubrique();
  };

  handleDelete = (id) => {
    const reglements = this.props.reglements.filter(
      (reg) => reg.reglement_quittance.quittance_id === this.state.quittance.quittance_id
    );
    const montantRegle = reglements
      .flatMap((reglement) => reglement.reglement_ligne)
      .filter((ligne) => ligne.reglement_ligne_quittance_ligne_id.quittance_ligne_id === id)
      .reduce((total, ligne) => total + parseFloat(ligne.reglement_ligne_montant), 0);
    if (montantRegle > 0) {
      toast.error(
        "Attention un encaissement existe sur cette quittance, suppression de la rubrique impossible (Faire une annulation encaissement avant de procéder à la modification de la quittance)",
        { containerId: "A" }
      );
    } else {
      const indexToDelete = this.state.quittance.quittance_ligne.findIndex(
        (ligne) => ligne.quittance_ligne_id === id
      );
      if (indexToDelete !== -1) {
        this.setState((prevState) => ({
          quittance: update(prevState.quittance, {
            quittance_ligne: { $splice: [[indexToDelete, 1]] },
          }),
          modification: true,
        }));
      }
    }
  };

  handleSave = (row) => {
    const reglements = this.props.reglements.filter(
      (reg) => reg.reglement_quittance.quittance_id === this.state.quittance.quittance_id
    );
    const montantRegle = reglements
      .flatMap((reglement) => reglement.reglement_ligne)
      .filter(
        (ligne) =>
          ligne.reglement_ligne_quittance_ligne_id.quittance_ligne_id === row.quittance_ligne_id
      )
      .reduce((total, ligne) => total + parseFloat(ligne.reglement_ligne_montant), 0);
    if (row.quittance_ligne_montant < montantRegle) {
      toast.error(
        "Attention un encaissement existe sur cette quittance, diminution de la rubrique impossible (Faire une annulation encaissement avant de procéder à la modification de la quittance)",
        { containerId: "A" }
      );
    } else {
      const indexToUpdate = this.state.quittance.quittance_ligne.findIndex(
        (ligne) => ligne.quittance_ligne_id === row.quittance_ligne_id
      );
      if (indexToUpdate !== -1) {
        this.setState((prevState) => ({
          quittance: update(prevState.quittance, {
            quittance_ligne: {
              [indexToUpdate]: { $set: row },
            },
          }),
          lignes: [...this.state.lignes, row.quittance_ligne_id],
          modification: true,
        }));
      }
    }
  };

  setBackgroundColor = (item) => {
    if (this.state.lignes.includes(item.quittance_ligne_id)) {
      return "editable-row row-edited-bg";
    } else {
      return "editable-row";
    }
  };

  openModalRubrique = () => {
    this.setState({ modalRubrique: true });
  };

  closeModalRubrique = () => {
    this.setState({ modalRubrique: false });
  };

  getRubriques = (value) => {
    this.getRubriquesAvailable(this.state.quittance, value);
  };

  getRubriquesAvailable = (quittance, value) => {
    let rubriques = this.props.rubriques;
    rubriques = rubriques.sort((a, b) => a.rubrique_code - b.rubrique_code);
    quittance &&
      rubriques &&
      quittance.quittance_ligne.forEach((ligne) => {
        rubriques = rubriques.filter(
          (i) => i.rubrique_id !== ligne.quittance_ligne_rubrique.rubrique_id
        );
      });

    if (!value || (value && value.target.checked === false)) {
      rubriques = rubriques.filter((i) => i.rubrique_contrat === true);
    }

    this.setState({ rubriques });
  };

  goTo = (idPane, location) => {
    switch (idPane) {
      case 1:
        this.props.addPanesReglement(location.location_requete.requete_locataire.locataire_id);
        break;
      case 2:
        this.props.addPanesQuittancement(location.location_id);
        break;
      case 3:
        this.props.addPanesSuppressionReglement(
          location.location_requete.requete_locataire.locataire_id
        );
        break;
      default:
        break;
    }
  };

  render() {
    const { home, location, locationQuittances, isArchive, words } = this.props;
    const {
      loading,
      quittance,
      rubriques,
      modalRubrique,
      modalPdf,
      idPdf,
      documentPdf,
      strQuittance,
      modification,
    } = this.state;

    const facture = !(location.location_type_contrat === 1 || location.location_type_contrat === 2);

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRows });
      },
    };

    let columnsA = [
      {
        title: "Code",
        dataIndex: "quittance_ligne_rubrique",
        key: "quittance_ligne_rubrique",
        width: "20%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) =>
          a.quittance_ligne_rubrique.rubrique_code.localeCompare(
            b.quittance_ligne_rubrique.rubrique_code
          ),
        render: (rubrique) => rubrique.rubrique_code,
      },
      {
        title: "Libellé",
        dataIndex: "quittance_ligne_libelle",
        key: "quittance_ligne_libelle",
        width: "40%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) =>
          a.quittance_ligne_rubrique.rubrique_libelle.localeCompare(
            b.quittance_ligne_rubrique.rubrique_libelle
          ),
        render: (libelle, quittance_ligne) =>
          libelle ? libelle : quittance_ligne.quittance_ligne_rubrique.rubrique_libelle,
        editable: true,
      },
      {
        title: "Type",
        dataIndex: "quittance_ligne_rubrique",
        key: "quittance_ligne_rubrique",
        width: "5%",
        render: (rubrique) => rubrique.rubrique_type,
      },
      {
        title: "Montant",
        dataIndex: "quittance_ligne_montant",
        key: "quittance_ligne_montant",
        width: "30%",
        align: "right",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.quittance_ligne_montant - b.quittance_ligne_montant,
        editable: true,
      },
      {
        title: "Supprimer",
        dataIndex: "quittance_ligne_id",
        key: "quittance_ligne_id",
        width: "5%",
        render: (quittance_ligne_id) => (
          <Button
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={() => this.handleDelete(quittance_ligne_id)}
          />
        ),
      },
    ];

    const panes = [
      {
        id: 1,
        title: "Encaissement",
        description: "Encaissement client",
      },
      {
        id: 2,
        title: "Quittancement",
        description: "Générer quittance locataire",
      },
      {
        id: 3,
        title: "Détail des encaissements",
        description: "Liste des encaissement client",
      },
    ];

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    const columns = columnsA.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <>
        <Modal
          footer={null}
          width="600px"
          title="Rubriques"
          open={modalRubrique}
          onCancel={this.closeModalRubrique}
        >
          <>
            <Row>
              <Col offset={20} span={4}>
                <Checkbox onChange={(value) => this.getRubriques(value)}>Voir toutes</Checkbox>
              </Col>
            </Row>
            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              rowKey="rubrique_code"
              dataSource={rubriques}
            >
              <Column title="Code" dataIndex="rubrique_code" key="code" />
              <Column title="Libelle" dataIndex="rubrique_libelle" key="libelle" />
            </Table>

            <Row style={{ marginTop: "10px" }}>
              <Button
                type="primary"
                onClick={this.handleSubmitAddRubriques}
                style={{ float: "right" }}
              >
                Valider
              </Button>
            </Row>
          </>
        </Modal>
        <Modal
          width={800}
          footer={null}
          title={strQuittance ? (facture ? "Facture" : "Quittance") : "Contrat"}
          open={modalPdf}
          onCancel={() => this.closeModal(documentPdf, true)}
        >
          <Spin spinning={loading} tip="Création pdf...">
            <Row gutter={12}>
              {idPdf && (
                <Col span={6}>
                  <Button
                    style={{ width: "100%", marginBottom: 10 }}
                    type="text"
                    onClick={() => openPdf(idPdf, "b")}
                  >
                    Télécharger PDF
                  </Button>
                </Col>
              )}
              {!idPdf && documentPdf && (
                <Col span={6}>
                  <Button
                    style={{ width: "100%", marginBottom: 10 }}
                    href={`${Constants.directory}documents/${documentPdf.document_dossier.dossier_url}${documentPdf.document_tag.tag_libelle}/${documentPdf.document_nom}`}
                    target="_blank"
                  >
                    Télécharger Contrat PDF
                  </Button>
                </Col>
              )}
              {!idPdf && documentPdf && (
                <Col span={6}>
                  <Button
                    style={{ width: "100%", marginBottom: 10 }}
                    href={`${Constants.directory}documents/${
                      documentPdf.document_dossier.dossier_url
                    }${documentPdf.document_tag.tag_libelle}/${documentPdf.document_nom.replace(
                      ".pdf",
                      ".docx"
                    )}`}
                    target="_blank"
                  >
                    Télécharger Contrat Word
                  </Button>
                </Col>
              )}
              <Col span={6}>
                <Button
                  style={{ width: "100%", marginBottom: 10 }}
                  onClick={() => this.handleEmail(documentPdf)}
                >
                  Envoyer par mail
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  style={{ width: "100%", marginBottom: 10 }}
                  onClick={() => this.handleSignature(documentPdf)}
                >
                  Envoyer avec YouSign
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  style={{ width: "100%", marginBottom: 10 }}
                  onClick={() => this.closeModal(documentPdf, true)}
                >
                  Annuler
                </Button>
              </Col>
            </Row>
          </Spin>
        </Modal>

        <Form autoComplete="off" name="form-pdf" ref={this.formRef} size={home ? "small" : "large"}>
          <Row gutter={12}>
            <Col span={16}>
              <Row gutter={10}>
                <Col span={21}>
                  <Form.Item name="quittances">
                    <Select
                      showSearch
                      optionFilterProp="label"
                      optionLabelProp="label"
                      allowClear
                      placeholder="Appels de loyer"
                      onSelect={(id) => this.onSelectQuittance(id, locationQuittances)}
                      onClear={this.onClearQuittance}
                      // value={quittance && quittance.quittance_id}
                    >
                      <OptGroup label="Appels">
                        {locationQuittances &&
                          locationQuittances
                            .sort((a, b) => b.quittance_id - a.quittance_id)
                            .map((item, i) => {
                              return (
                                <Option
                                  key={i}
                                  value={item.quittance_id}
                                  label={`Appel n°${item.quittance_id} pour la période du 
                              ${formatDateToAPP(item.quittance_date_debut)} au 
                              ${formatDateToAPP(item.quittance_date_fin)}`}
                                >
                                  <Row>
                                    <Col sm={8}>
                                      {`Appel n°${item.quittance_id} pour la période du 
                                  ${formatDateToAPP(item.quittance_date_debut)} au 
                                  ${formatDateToAPP(item.quittance_date_fin)}`}
                                    </Col>
                                  </Row>
                                </Option>
                              );
                            })}
                      </OptGroup>
                    </Select>
                  </Form.Item>
                </Col>
                {quittance && (
                  <>
                    <Col span={1}>
                      <Button
                        size="middle"
                        icon={<DownloadOutlined />}
                        type="primary"
                        onClick={() => this.createPdfQuittance()}
                      />
                    </Col>
                    <Col span={1}>
                      <Popconfirm
                        disabled={!modification}
                        title={`Confirmez vous la modification de cette quittance ?`}
                        onConfirm={() => this.handleUpdateQuittance(quittance)}
                      >
                        <Button
                          disabled={!modification}
                          size="middle"
                          icon={<EditOutlined />}
                          type="primary"
                        />
                      </Popconfirm>
                    </Col>
                    <Col span={1}>
                      <Popconfirm
                        title={`Confirmez vous la suppression de cette quittance ?`}
                        onConfirm={() => this.handleDeleteQuittance(quittance.quittance_id)}
                      >
                        <Button size="middle" icon={<DeleteOutlined />} type="primary" />
                      </Popconfirm>
                    </Col>
                  </>
                )}
              </Row>
              <Row gutter={10}>
                <Col span={21}>
                  <Form.Item name="pdf_template">
                    <Select
                      autoComplete="new-password"
                      disabled={isArchive}
                      showSearch
                      allowClear
                      optionFilterProp="label"
                      placeholder="Choix modèle contrat"
                      onSelect={this.onSelectModele}
                      onClear={this.onClearModele}
                    >
                      <OptGroup label="Modèle">
                        {words &&
                          words
                            .filter((item) => item.word_type === 1)
                            .map((item, i) => {
                              return (
                                <Option key={i} value={item.word_id} label={`${item.word_nom}`}>
                                  <Row>
                                    <Col sm={8}>{`${item.word_nom}`}</Col>
                                  </Row>
                                </Option>
                              );
                            })}
                      </OptGroup>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Button
                    size="middle"
                    type="primary"
                    loading={loading}
                    onClick={() => this.handleCreatePdf()}
                  >
                    Générer PDF
                  </Button>
                </Col>
              </Row>
              {quittance && (
                <>
                  <Row>
                    <Col span={4}>
                      <Button type="primary" disabled={isArchive} onClick={this.openModalRubrique}>
                        Ajout Rubrique
                      </Button>
                    </Col>
                  </Row>
                  <Table
                    dataSource={quittance.quittance_ligne}
                    columns={columns}
                    components={components}
                    style={{ marginTop: 10 }}
                    rowClassName={(record) => this.setBackgroundColor(record)}
                    rowKey="quittance_ligne_id"
                    pagination={false}
                    summary={(pageData) => {
                      let total = 0;

                      pageData.forEach(({ quittance_ligne_montant }) => {
                        total += parseFloat(quittance_ligne_montant);
                      });

                      return (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell>Total</Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <Text style={{ float: "right" }}>
                                <strong>
                                  {parseFloat(total).toLocaleString("fr-FR", {
                                    style: "currency",
                                    currency: "EUR",
                                  })}
                                </strong>
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                    }}
                  />
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Paiement attendu le"
                        name="quittance_date_paiement"
                        style={{ marginTop: 10, float: "right" }}
                      >
                        <DatePicker
                          onChange={(e) => this.updateDatePaiementQuittance(e)}
                          disabled={isArchive}
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
            <Col offset={2} span={6}>
              <List
                itemLayout="horizontal"
                dataSource={panes}
                renderItem={(pane) => (
                  <List.Item
                    actions={[
                      <Button onClick={() => this.goTo(pane.id, location)}>
                        <ArrowRightOutlined />
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta title={pane.title} description={pane.description} />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

export default FormPdf;
