import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";

import { loadLots } from "../../store/actions/lotAction";
import { loadLocations } from "../../store/actions/locationAction";
import { loadQuittances } from "../../store/actions/quittanceAction";
import { loadReglements } from "../../store/actions/reglementAction";

import { columns } from "./columns";

import ListSperone from "../../components/ListSperone";

class Sperone extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadLots(societe_id);
    this.props.loadLocations(groupement_id);
    this.props.loadQuittances(groupement_id);
    this.props.loadReglements(societe_id);
  }

  render() {
    const { lots, locations, quittances, reglements } = this.props;

    return (
      <Spin
        size="large"
        tip="Chargement en cours..."
        spinning={!lots || !locations || !quittances || !reglements}
      >
        <Card title="" bordered={false} className="card-panel">
          <ListSperone
            listData={
              lots &&
              lots
                .filter((item) => !item.lot_fin)
                .sort((a, b) => a.lot_reference.localeCompare(b.lot_reference))
            }
            tableOption={columns}
            locations={locations}
            quittances={quittances}
            reglements={reglements}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLots: (societe_id) => dispatch(loadLots(societe_id)),
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadQuittances: (groupement_id) => dispatch(loadQuittances(groupement_id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    lots: state.lots.data,
    locations: state.locations.data,
    quittances: state.quittances.data,
    reglements: state.reglements.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sperone);
