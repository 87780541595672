import { utilisateurToAdapted } from "./utilisateurAdapter";

export function parametresToAdapted(items) {
  return items
    ? items.map((item) => {
        return parametreToAdapted(item);
      })
    : null;
}

export function parametreToAdapted(item) {
  //Resource
  return item
    ? {
        parametre_id: item.id,
        parametre_util: item.util,
        parametre_email: item.email,
        parametre_email_copie: item.email_copie,
        parametre_mdp: item.mdp,
        parametre_smtp: item.smtp,
        parametre_port: item.port,
        parametre_ssl: item.ssl,
        parametre_verifier_identite: item.verifier_identite,
        parametre_tls: item.tls,
        parametre_authentification: item.authentification,
        parametre_copie_agence: item.copie_agence,
        parametre_quittancement: item.quittancement,
        parametre_parcours_client: item.parcours_client,
        parametre_cacher_inactive: item.cacher_inactive,
        parametre_nombre_etapes: item.nombre_etapes,
        parametre_pointe_max: item.pointe_max,
        parametre_cacher_titre: item.cacher_titre,
        parametre_pointage_auto: item.pointage_auto,
        parametre_rapprochement_compte_id: item.rapprochement_compte_id,
        parametre_date_releve: item.date_releve,
        parametre_montant_releve: item.montant_releve,
        parametre_signature: item.signature,
        parametre_scheduler_row_height: item.scheduler_row_height,
        parametre_scheduler_row_margin: item.scheduler_row_margin,
        parametre_scheduler_day_before: item.scheduler_day_before,
        parametre_scheduler_day_after: item.scheduler_day_after,
        parametre_scheduler_afficher_reference: item.scheduler_afficher_reference,
        parametre_calcul_arrondi: item.calcul_arrondi,
        parametre_reddition_detaillee: item.reddition_detaillee,
        parametre_depenses_proprietaire: item.depenses_proprietaire,
        parametre_blocage_option: item.blocage_option,
        parametre_reddition_tva: item.reddition_tva,
        parametre_utilisateur: utilisateurToAdapted(item.utilisateur),
        parametre_langue_id: item.langue_id,
        parametre_type_contrat: item.type_contrat,
        parametre_provenance_id: item.provenance_id,
        parametre_groupe_id: item.groupe_id,
      }
    : null;
}

export function adaptedToParametre(item) {
  //Model
  return item
    ? {
        util: item.parametre_util,
        email: item.parametre_email,
        email_copie: item.parametre_email_copie,
        mdp: item.parametre_mdp,
        smtp: item.parametre_smtp,
        port: item.parametre_port,
        ssl: item.parametre_ssl,
        verifier_identite: item.parametre_verifier_identite,
        tls: item.parametre_tls,
        authentification: item.parametre_authentification,
        copie_agence: item.parametre_copie_agence,
        quittancement: item.parametre_quittancement,
        parcours_client: item.parametre_parcours_client,
        cacher_inactive: item.parametre_cacher_inactive,
        cacher_titre: item.parametre_cacher_titre,
        nombre_etapes: item.parametre_nombre_etapes,
        pointe_max: item.parametre_pointe_max,
        pointage_auto: item.parametre_pointage_auto,
        rapprochement_compte_id: item.parametre_rapprochement_compte_id,
        date_releve: item.parametre_date_releve,
        montant_releve: item.parametre_montant_releve,
        signature: item.parametre_signature,
        scheduler_row_height: item.parametre_scheduler_row_height,
        scheduler_row_margin: item.parametre_scheduler_row_margin,
        scheduler_day_before: item.parametre_scheduler_day_before,
        scheduler_day_after: item.parametre_scheduler_day_after,
        scheduler_afficher_reference: item.parametre_scheduler_afficher_reference,
        calcul_arrondi: item.parametre_calcul_arrondi,
        reddition_detaillee: item.parametre_reddition_detaillee,
        depenses_proprietaire: item.parametre_depenses_proprietaire,
        blocage_option: item.parametre_blocage_option,
        reddition_tva: item.parametre_reddition_tva,
        langue_id: item.parametre_langue_id,
        type_contrat: item.parametre_type_contrat,
        provenance_id: item.parametre_provenance_id,
        groupe_id: item.parametre_groupe_id,
      }
    : null;
}
