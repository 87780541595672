module.exports = {
  // DEV (LOCAL)
  // domain: "localhost",
  // port: 8000,
  // mode: 'http',
  // directory: "http://localhost/storage/",
  // subfolder: "",

  // DEV (SERVER)
  // domain: "178.33.1.123",
  // port: 9000,
  // mode: "http",
  // directory: "http://178.33.1.123:9000/storage/",
  // subfolder: "app",

  // ASTERVM1 (SERVER)
  domain: "api.aster-soft.fr",
  port: 443,
  mode: "https",
  directory: "https://api.aster-soft.fr/storage/",
  subfolder: "",

  // -----CONTACT-----
  CONTACT: {
    CUSTOM: {
      FIELD: {
        TYPE: [
          { name: "Oui ou Non", value: 1 },
          { name: "Numérique", value: 2 },
          { name: "Texte", value: 3 },
          { name: "Bloc Texte", value: 4 },
        ],
      },
    },
  },

  // -----DISPONIBILITÉ LOTS-----
  LOT_DISPONIBLE: 1,
  LOT_OPTION: 2,
  LOT_NON_DISPONIBLE: 3,
  LOT_SANS_CORRESPONDANCE: 4,

  // -----TYPE WORD-----
  TYPE: [
    { name: "CONTRAT", value: 1 },
    { name: "APPARTEMENT", value: 2 },
    { name: "PROPRIETAIRE", value: 3 },
    { name: "LOCATAIRE", value: 4 },
    { name: "AUTRES", value: 5 },
    { name: "COMPTABILITÉ", value: 6 },
  ],

  // -----TYPE TAG-----
  TYPE_TAG: [
    { name: "PROPRIETAIRE", value: 1 },
    { name: "LOT", value: 2 },
    { name: "REQUETE", value: 3 },
  ],

  // -----ARRIVEE PREVUE-----
  ARRIVEE_PREVUE: [
    { name: "À l'heure", value: false, icon: "CheckOutlined", color: "#69db7c" },
    { name: "Arrivée tardive", value: true, icon: "CloseCircleOutlined", color: "#ffb38a" },
  ],

  // -----ETAT CLIENT-----
  ETAT_CLIENT: [
    { name: "Arrivé", value: 1, icon: "LoginOutlined", color: "#69db7c" },
    { name: "Reparti", value: 2, icon: "LogoutOutlined", color: "#2890c0" },
    { name: "Pas arrivé", value: 3, icon: "CloseCircleOutlined", color: "#ffb38a" },
  ],

  // -----ETAT LOGEMENT-----
  ETAT_LOGEMENT: [
    { name: "À préparer", value: 1, icon: "ClearOutlined", color: "#ffb38a" },
    { name: "Prêt", value: 2, icon: "CheckOutlined", color: "#69db7c" },
  ],

  // -----SIDEBAR MENU-----
  Menu: [
    // TAB 1
    { key: "1", activeKey: "17", label: "Propriétaires" },
    { key: "2", activeKey: "14", label: "Relevés de gestion" },
    { key: "3", activeKey: "19", label: "Liste Pro." },
    { key: "4", activeKey: "25", label: "Lots" },
    { key: "5", activeKey: "31", label: "Liste Lot" },
    { key: "6", activeKey: "18", label: "Locataires" },
    { key: "7", activeKey: "20", label: "Liste Loc." },
    { key: "8", activeKey: "37", label: "Immeubles" },
    { key: "50", activeKey: "47", label: "Agents" },

    // TAB 2
    { key: "9", activeKey: "26", label: "Contrats / Disponibilités" },
    { key: "10", activeKey: "40", label: "Propositions" },
    { key: "11", activeKey: "28", label: "Options" },
    { key: "12", activeKey: "29", label: "Contrats" },
    { key: "52", activeKey: "49", label: "Quittancement" },
    { key: "57", activeKey: "54", label: "Liste Quittance" },
    { key: "13", activeKey: "30", label: "Réservations propriétaire" },
    { key: "14", activeKey: "27", label: "Entrées / Sorties" },
    { key: "58", activeKey: "55", label: "Suivi des séjours" },
    { key: "15", activeKey: "42", label: "Statut des documents" },
    { key: "16", activeKey: "4", label: "Planning" },
    { key: "56", activeKey: "53", label: "Ménages" },
    { key: "17", activeKey: "43", label: "Historique" },
    { key: "18", activeKey: "44", label: "Timers" },
    { key: "55", activeKey: "52", label: "Demandes" },
    { key: "19", activeKey: "", label: "" },
    { key: "20", activeKey: "", label: "" },
    { key: "21", activeKey: "", label: "" },

    // TAB 3
    { key: "22", activeKey: "16", label: "Interrogation de compte" },
    { key: "59", activeKey: "56", label: "Détail des encaissement" },
    { key: "61", activeKey: "58", label: "Récapitulatif C.A. par lots" },
    { key: "23", activeKey: "38", label: "Pointe" },
    { key: "24", activeKey: "7", label: "Encaissement locataires" },
    { key: "25", activeKey: "39", label: "Validation de bordereau" },
    { key: "26", activeKey: "41", label: "Prestations Supplémentaires" },
    { key: "27", activeKey: "6", label: "Saisie OD" },
    { key: "28", activeKey: "36", label: "Annulation Encaissement" },
    { key: "60", activeKey: "57", label: "Versement honoraires" },
    { key: "29", activeKey: "32", label: "Journaux" },
    { key: "30", activeKey: "10", label: "Plan Comptable" },
    { key: "31", activeKey: "8", label: "Balance" },
    { key: "32", activeKey: "9", label: "Grand Livre" },
    { key: "33", activeKey: "23", label: "Recherche écritures" },
    { key: "54", activeKey: "51", label: "Lettrage de comptes" },
    { key: "34", activeKey: "45", label: "Écarts Encaissement" },
    { key: "51", activeKey: "48", label: "Écarts Reddition" },
    { key: "35", activeKey: "33", label: "Rapprochement" },

    // TAB 4
    { key: "36", activeKey: "", label: "" },
    { key: "37", activeKey: "", label: "" },
    { key: "38", activeKey: "12", label: "Paramètres" },
    { key: "39", activeKey: "", label: "" },
    { key: "40", activeKey: "21", label: "Société" },
    { key: "41", activeKey: "22", label: "Établissement" },
    { key: "42", activeKey: "15", label: "Imputations" },
    { key: "43", activeKey: "11", label: "Templates" },
    { key: "44", activeKey: "13", label: "Champs Perso." },
    { key: "45", activeKey: "2", label: "Étapes" },
    { key: "46", activeKey: "3", label: "Groupes" },
    { key: "47", activeKey: "5", label: "Rubriques" },
    { key: "48", activeKey: "34", label: "Migration" },
    { key: "49", activeKey: "46", label: "Requêtes archivées" },
    { key: "53", activeKey: "50", label: "Types de paiements" },
  ],
};
