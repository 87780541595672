import "./App.css";

import React, { Component } from "react";
import { Layout, Spin } from "antd";

import Accueil from "./containers/Accueil";
import Actions from "./containers/Actions";
import Altimmo from "./containers/Altimmo";
import Agents from "./containers/Agents";
import Balance from "./containers/Comptabilite/Balance";
import ChampPerso from "./containers/ChampPerso";
import Comptes from "./containers/Comptabilite/Comptes";
import Demandes from "./containers/Demandes";
import Documents from "./containers/Documents";
import EcartReddition from "./containers/EcartReddition";
import EcartReglement from "./containers/EcartReglement";
import ErrorBoundary from "./containers/ErrorBoundary";
import Etapes from "./containers/Etapes";
import GrandLivre from "./containers/Comptabilite/GrandLivre";
import Groupes from "./containers/Groupes";
import Historique from "./containers/Historique";
import HomeLarge from "./containers/HomeLarge";
import Honoraires from "./containers/Comptabilite/Honoraires";
import Immeubles from "./containers/Immeubles";
import Imputations from "./containers/Imputations";
import Journals from "./containers/Comptabilite/Journals";
import Locations from "./containers/Locations";
import Lots from "./containers/Lots";
import LotsListe from "./containers/Lots/list";
import Menages from "./containers/Menages";
import Mouvements from "./containers/Mouvements";
import MyHeader from "./components/Header";
import MyToast from "./components/Toast";
import Parametre from "./containers/Parametre";
import Personnes from "./containers/Personnes";
import PersonnesListe from "./containers/Personnes/list";
import Quittances from "./containers/Quittances";
import Scheduler from "./containers/Scheduler";
import Pointes from "./containers/Comptabilite/Pointes";
import PrestationsSupplementaires from "./containers/PrestationsSupplementaires";
import Propositions from "./containers/Propositions";
import Quittancement from "./containers/Quittancement";
import Operation from "./containers/Comptabilite/Operation";
import Organisation from "./containers/Organisation";
import RapprochementBancaire from "./containers/Comptabilite/RapprochementBancaire";
import Recherche from "./containers/Comptabilite/Recherche";
import Reddition from "./containers/Reddition";
import Reglement from "./containers/Reglement";
import Requetes from "./containers/Requetes";
import Rubriques from "./containers/Rubriques";
import Sidebar from "./components/Sidebar";
import Sperone from "./containers/Sperone";
import SuppressionReglement from "./containers/SuppressionReglement";
import Template from "./containers/Template";
import TypesPaiements from "./containers/TypesPaiements";
import ValidationBordereau from "./containers/ValidationBordereau";

import { connect } from "react-redux";
import { getNomLocataireRequete } from "./utils/tools";
import { initTree } from "./store/actions/treeAction";
import { loadCautions } from "./store/actions/cautionAction";
import { loadChampsPersos } from "./store/actions/champPersoAction";
import { loadChampsPersosGroupes } from "./store/actions/champPersoGroupeAction";
import { loadCodesCouleurs } from "./store/actions/codeCouleurAction";
import { loadDocuments } from "./store/actions/documentAction";
import { loadEquipements } from "./store/actions/equipementAction";
import { loadEtablissements } from "./store/actions/etablissementAction";
import { loadEtapes } from "./store/actions/etapeAction";
import { loadEtapesTemplates } from "./store/actions/etapeTemplateAction";
import { loadFraisAgences } from "./store/actions/fraisAgenceAction";
import { loadGroupes } from "./store/actions/groupeAction";
import { loadJournals } from "./store/actions/journalAction";
import { loadLangues } from "./store/actions/langueAction";
import { loadParametres } from "./store/actions/parametreAction";
import { loadParcoursClients } from "./store/actions/parcoursClientAction";
import { loadPieces } from "./store/actions/pieceAction";
import { loadProvenances } from "./store/actions/provenanceAction";
import { loadQualites } from "./store/actions/qualiteAction";
import { loadRenduAs } from "./store/actions/renduAction";
import { loadRenduAsHistorique } from "./store/actions/renduAHistoriqueAction";
import { loadRequetes } from "./store/actions/requeteAction";
import { loadRubriques } from "./store/actions/rubriqueAction";
import { loadSocietes } from "./store/actions/societeAction";
import { loadTags } from "./store/actions/tagAction";
import { loadTemplates } from "./store/actions/templateAction";
import { loadTemplatesLangues } from "./store/actions/templateLangueAction";
import { loadTypesLots } from "./store/actions/typeLotAction";
import { loadTypesPaiements } from "./store/actions/typePaiementAction";
import { loadWords } from "./store/actions/wordAction";
import { loadTypesEmails } from "./store/actions/typeEmailAction";

class Router extends Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 1;
    this.intervalId = null; // pour stocker l'ID de l'intervalle

    this.state = {
      requete: null,
      panes: [{ key: "1", title: "Accueil", content: "" }],
      activeKey: "1",
      personneProps: null,
      tabCompta: false,
      lotProps: null,
      ecritureProps: null,
      requeteProps: null,
      tabContrat: false,
      size: 52,
      status: "",
      loadingTree: true,
      loadingRequete: true,
      loadingApplication: true,
    };
  }

  componentDidMount() {
    this.globalInit();

    // Définir l'intervalle pour répéter l'appel toutes les cinq minutes (300000 ms)
    // this.intervalId = setInterval(this.globalInit, 300000);
  }

  // componentWillUnmount() {
  //   // Nettoyer l'intervalle lorsque le composant est démonté
  //   clearInterval(this.intervalId);
  // }

  globalInit = async () => {
    const { utilisateur } = this.props;

    this.props
      .initTree(utilisateur.utilisateur_id)
      .then((res) => this.setState({ loadingTree: false }));

    Promise.all([
      this.props.loadParametres(utilisateur.utilisateur_id),
      this.props.loadFraisAgences(),
      this.props.loadCautions(),
      this.props.loadSocietes(),
      this.props.loadEtablissements(),
      this.props.loadJournals(),
      this.props.loadRubriques(),
      // Parcours Client
      this.props.loadEtapes(),
      this.props.loadGroupes(),
      this.props.loadProvenances(),
      this.props.loadRequetes(),
      this.props.loadRenduAs(),
      // this.props.loadRenduAsHistorique(),
      this.props.loadParcoursClients(),
    ]).then(() => {
      this.setState({ loadingRequete: false });
      Promise.all([
        this.props.loadCodesCouleurs(),
        this.props.loadQualites(),
        this.props.loadLangues(),
        this.props.loadTypesEmails(),
        this.props.loadTypesPaiements(),
        this.props.loadTypesLots(),
        this.props.loadEquipements(),
        this.props.loadChampsPersos(),
        this.props.loadChampsPersosGroupes(),
        this.props.loadPieces(),
        this.props.loadDocuments(),
        this.props.loadTags(),
        this.props.loadTemplates(),
        this.props.loadTemplatesLangues(),
        this.props.loadEtapesTemplates(),
        this.props.loadWords(),
      ]).then(() => {
        this.setState({ loadingApplication: false });
      });
    });
  };

  syncChampsPersosGroupes = () => {
    const { utilisateur } = this.props;
    const groupement_id = utilisateur.groupement_id;
    this.props.loadChampsPersosGroupes(groupement_id);
  };

  addPanes = (pane, key) => {
    const newPanes = this.state.panes;
    const isPaneExist = this.isKeyExists(this.state.panes, key);

    if (pane !== null && !isPaneExist) newPanes.push(pane);

    this.setState({
      panes: newPanes,
      activeKey: key,
      personneProps: null,
      tabCompta: false,
      lotProps: null,
      ecritureProps: null,
      ...(key === "26" && !isPaneExist && { requeteProps: null }),
      ...(key === "26" && !isPaneExist && { tabContrat: false }),
      requete: null,
    });
  };

  addPanesPersonne = (personneProps, proprietaire = true, tabCompta = false) => {
    this.setState({ personneProps, tabCompta, requete: null }, () =>
      this.addPersonne(
        personneProps
          ? `${
              proprietaire
                ? personneProps.proprietaire_personne.personne_nom
                : personneProps.locataire_personne.personne_nom
            } ${proprietaire ? "(P)" : "(L)"}`
          : proprietaire
          ? "Nouveau propriétaire"
          : "Nouveau locataire",
        proprietaire,
        personneProps ? true : false
      )
    );
  };

  addPanesLot = (lotProps) => {
    this.setState({ lotProps, requete: null }, () =>
      this.addLot(lotProps ? `Ref ${lotProps.lot_reference}` : "Nouveau Lot")
    );
  };

  addPanesImmeuble = (immeubleProps) => {
    this.setState({ immeubleProps }, () =>
      this.addImmeuble(immeubleProps ? `Ref ${immeubleProps.immeuble_nom}` : "Nouvel Immeuble")
    );
  };

  addPanesReglement = (idLocataire) => {
    this.addReglement("Encaissement locataires", idLocataire);
  };

  addPanesQuittancement = (idLocation) => {
    this.addQuittancement("Quittancement", idLocation);
  };

  addPanesEcriture = (pane, key, ecritureProps) => {
    let newPanes = this.state.panes;
    if (pane !== null) {
      if (this.isKeyExists(this.state.panes, key)) {
        newPanes = newPanes.filter((item) => item.key !== key);
        this.setState({ panes: newPanes }, () => this.addPanesEcriture(pane, key, ecritureProps));
      } else newPanes.push(pane);
    }
    this.setState({ panes: newPanes, activeKey: key, ecritureProps, requete: null });
  };

  addPanesRequete = (pane, key, requeteProps, tabContrat = false) => {
    this.setState({ requeteProps }, () => this.addRequete(`Req`, tabContrat));
  };

  addPanesSuppressionReglement = (idLocataire) => {
    this.addSuppressionReglement("Détail des encaissements", idLocataire);
  };

  isKeyExists = (panes, key) => {
    let result = panes.some((pane) => {
      return pane.key === key;
    });
    return result;
  };

  isOpen = (panes, key) => {
    let result = panes.some((obj) => {
      return obj.key === key;
    });
    return result;
  };

  isVisible = (activeKey, key) => {
    let visible = "none";
    if (key === activeKey) visible = "block";

    return visible;
  };

  handleSelectRequete = (id, e) => {
    if (!e.selected || !e.node.requete_id) return;

    const requete = this.props.requetes.find((i) => i.requete_id === e.node.requete_id);
    const pane = { key: "26", title: "Contrats / Disponibilités", content: "" };
    this.addPanesRequete(pane, "26", requete, false);
  };

  handleSelectRequeteArchivee = (requete) => {
    const key = requete.requete_id.toString();
    this.managePane(requete, key);
  };

  handleClickRequete = (requete) => {
    const key = requete.requete_id.toString();
    this.managePane(requete, key);
  };

  syncRequete = (requeteProps, key) => {
    this.setState({ requeteProps, tabContrat: false });
    this.changeTabTitle(
      requeteProps ? getNomLocataireRequete(requeteProps) : "Contrats / Disponibilités",
      key
    );
  };

  changeTabTitle = (title, key) => {
    const panes = [];
    this.state.panes.forEach((item) => {
      if (item.key === key) item.title = title;
      panes.push(item);
    });
    this.setState({ panes: panes });
  };

  managePane = (requete, key) => {
    if (!this.isOpen(this.state.panes, key)) {
      this.addPanes(
        {
          key: key,
          title: getNomLocataireRequete(requete),
          content: this.createTab(key, requete),
        },
        key
      );
    }

    this.setState({ requete, activeKey: key });
  };

  closeActiveTab = () => {
    let activeKey = this.state.activeKey;
    let targetKey = this.state.activeKey;
    let lastIndex;

    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const panes = this.state.panes.filter((pane) => pane.key !== targetKey);
    if (panes.length && activeKey === targetKey) {
      if (lastIndex >= 0) {
        activeKey = panes[lastIndex].key;
      } else {
        activeKey = panes[0].key;
      }
    }

    this.setState({ panes, activeKey });
    this.syncState(panes, activeKey);
  };

  addPersonne = (title, proprietaire, readOnly) => {
    const { panes } = this.state;
    const activeKey = `${title}-${this.newTabIndex++}`;

    panes.push({
      title,
      content: this.createTabPersonne(activeKey, proprietaire, false),
      key: activeKey,
    });

    this.setState({ panes, activeKey });
  };

  addLot = (title) => {
    const { panes } = this.state;
    const activeKey = `${title}-${this.newTabIndex++}`;

    panes.push({
      title,
      content: this.createTabLot(activeKey),
      key: activeKey,
    });

    this.setState({ panes, activeKey });
  };

  addImmeuble = (title) => {
    const { panes } = this.state;
    const activeKey = `${title}-${this.newTabIndex++}`;

    panes.push({
      title,
      content: this.createTabImmeuble(activeKey),
      key: activeKey,
    });

    this.setState({ panes, activeKey });
  };

  addReglement = (title, idLocataire) => {
    const { panes } = this.state;
    const activeKey = `${title}-${this.newTabIndex++}`;

    panes.push({
      title,
      content: this.createTabReglement(activeKey, idLocataire),
      key: activeKey,
    });

    this.setState({ panes, activeKey });
  };

  addQuittancement = (title, idLocation) => {
    const { panes } = this.state;
    const activeKey = `${title}-${this.newTabIndex++}`;

    panes.push({
      title,
      content: this.createTabQuittancement(activeKey, idLocation),
      key: activeKey,
    });

    this.setState({ panes, activeKey });
  };

  addRequete = (title, tabContrat) => {
    const { panes } = this.state;
    const activeKey = `${title}-${this.newTabIndex++}`;

    panes.push({
      title,
      content: this.createTabRequete(activeKey, tabContrat),
      key: activeKey,
    });

    this.setState({ panes, activeKey });
  };

  addSuppressionReglement = (title, idLocataire) => {
    const { panes } = this.state;
    const activeKey = `${title}-${this.newTabIndex++}`;

    panes.push({
      title,
      content: this.createTabSuppressionReglement(activeKey, idLocataire),
      key: activeKey,
    });

    this.setState({ panes, activeKey });
  };

  createTab = (key) => {
    return (
      <div style={{ display: "block" }}>
        <Accueil key={key} />
      </div>
    );
  };

  createTabPersonne = (key, proprietaire, readOnly) => {
    return (
      <div className="adb-content" style={{ display: "block" }}>
        <Personnes
          key={key}
          readOnly={readOnly}
          proprietaire={proprietaire}
          personneProps={this.state.personneProps}
          tabCompta={this.state.tabCompta}
        />
      </div>
    );
  };

  createTabLot = (key) => {
    return (
      <div className="adb-content" style={{ display: "block" }}>
        <Lots
          key={key}
          readOnly
          lotProps={this.state.lotProps}
          addPanesPersonne={this.addPanesPersonne}
          addPanesRequete={this.addPanesRequete}
          addPanesImmeuble={this.addPanesImmeuble}
        />
      </div>
    );
  };

  createTabImmeuble = (key) => {
    return (
      <div className="adb-content" style={{ display: "block" }}>
        <Immeubles key={key} immeubleProps={this.state.immeubleProps} />
      </div>
    );
  };

  createTabReglement = (key, idLocataire) => {
    return (
      <div className="adb-content" style={{ display: "block" }}>
        <Reglement key={key} idLocataire={idLocataire} />
      </div>
    );
  };

  createTabQuittancement = (key, idLocation) => {
    return (
      <div className="adb-content" style={{ display: "block" }}>
        <Quittancement key={key} idLocation={idLocation} />
      </div>
    );
  };

  createTabRequete = (key, tabContrat) => {
    return (
      <div className="adb-content" style={{ display: "block" }}>
        <HomeLarge
          tabKey={key}
          requeteProps={this.state.requeteProps}
          addPanesPersonne={this.addPanesPersonne}
          addPanesLot={this.addPanesLot}
          addPanesReglement={this.addPanesReglement}
          addPanesQuittancement={this.addPanesQuittancement}
          addPanesSuppressionReglement={this.addPanesSuppressionReglement}
          syncRequeteRouter={this.syncRequete}
          tabContrat={tabContrat}
        />
      </div>
    );
  };

  createTabSuppressionReglement = (key, idLocataire) => {
    return (
      <div className="adb-content" style={{ display: "block" }}>
        <SuppressionReglement key={key} idLocataire={idLocataire} />
      </div>
    );
  };

  syncState = (panes, activeKey) => this.setState({ panes, activeKey });

  syncSizeStatus = (size, status) => this.setState({ size, status });

  render() {
    const {
      panes,
      activeKey,
      personneProps,
      tabCompta,
      lotProps,
      immeubleProps,
      ecritureProps,
      loadingTree,
      loadingRequete,
      loadingApplication,
    } = this.state;

    return (
      <ErrorBoundary>
        <div className="App">
          <Layout style={{ minHeight: "100vh" }}>
            <Sidebar
              panes={panes}
              loadingTree={loadingTree}
              loadingApplication={loadingApplication}
              addPanes={this.addPanes}
              addPanesPersonne={this.addPanesPersonne}
              addPanesLot={this.addPanesLot}
              handleSelectRequete={this.handleSelectRequete}
              handleSelectRequeteArchivee={this.handleSelectRequeteArchivee}
            />

            <Layout>
              <MyHeader
                panes={panes}
                add={this.add}
                activeKey={activeKey}
                syncState={this.syncState}
                addPanesRequete={this.addPanesRequete}
                className="adb-content"
              />
              {this.isOpen(panes, "1") && (
                <Spin size="large" spinning={loadingRequete} tip="Chargement application...">
                  <div style={{ display: this.isVisible(activeKey, "1") }}>
                    <Accueil />
                  </div>
                </Spin>
              )}

              {this.isOpen(panes, "2") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "2") }}>
                  <Etapes />
                </div>
              )}

              {this.isOpen(panes, "3") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "3") }}>
                  <Groupes />
                </div>
              )}

              {this.isOpen(panes, "4") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "4") }}>
                  <Scheduler addPanesRequete={this.addPanesRequete} />
                </div>
              )}

              {this.isOpen(panes, "5") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "5") }}>
                  <Rubriques />
                </div>
              )}

              {this.isOpen(panes, "6") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "6") }}>
                  <Operation edit={false} ecritureProps={null} />
                </div>
              )}

              {this.isOpen(panes, "7") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "7") }}>
                  <Reglement idLocataire={null} />
                </div>
              )}

              {this.isOpen(panes, "8") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "8") }}>
                  <Balance />
                </div>
              )}

              {this.isOpen(panes, "9") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "9") }}>
                  <GrandLivre grandLivre={true} />
                </div>
              )}

              {this.isOpen(panes, "10") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "10") }}>
                  <Comptes />
                </div>
              )}

              {this.isOpen(panes, "11") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "11") }}>
                  <Template />
                </div>
              )}

              {this.isOpen(panes, "12") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "12") }}>
                  <Parametre />
                </div>
              )}

              {this.isOpen(panes, "13") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "13") }}>
                  <ChampPerso syncChampsPersosGroupes={this.syncChampsPersosGroupes} />
                </div>
              )}

              {this.isOpen(panes, "14") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "14") }}>
                  <Reddition addPanesPersonne={this.addPanesPersonne} />
                </div>
              )}

              {this.isOpen(panes, "15") && (
                <div style={{ display: this.isVisible(activeKey, "15") }}>
                  <Imputations />
                </div>
              )}

              {this.isOpen(panes, "16") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "16") }}>
                  <GrandLivre grandLivre={false} />
                </div>
              )}

              {this.isOpen(panes, "17") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "17") }}>
                  <Personnes
                    proprietaire={true}
                    personneProps={personneProps}
                    tabCompta={tabCompta}
                    addPanesLot={this.addPanesLot}
                  />
                </div>
              )}

              {this.isOpen(panes, "18") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "18") }}>
                  <Personnes
                    proprietaire={false}
                    personneProps={personneProps}
                    tabCompta={tabCompta}
                    addPanesRequete={this.addPanesRequete}
                  />
                </div>
              )}

              {this.isOpen(panes, "19") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "19") }}>
                  <PersonnesListe proprietaire={true} />
                </div>
              )}

              {this.isOpen(panes, "20") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "20") }}>
                  <PersonnesListe proprietaire={false} />
                </div>
              )}

              {this.isOpen(panes, "21") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "21") }}>
                  <Organisation societe={true} />
                </div>
              )}

              {this.isOpen(panes, "22") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "22") }}>
                  <Organisation societe={false} />
                </div>
              )}

              {this.isOpen(panes, "23") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "23") }}>
                  <Recherche addPanesEcriture={this.addPanesEcriture} />
                </div>
              )}

              {this.isOpen(panes, "25") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "25") }}>
                  <Lots
                    lotProps={lotProps}
                    addPanesPersonne={this.addPanesPersonne}
                    addPanesRequete={this.addPanesRequete}
                    addPanesImmeuble={this.addPanesImmeuble}
                  />
                </div>
              )}

              {this.isOpen(panes, "26") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "26") }}>
                  <HomeLarge
                    requeteProps={null}
                    addPanesPersonne={this.addPanesPersonne}
                    addPanesLot={this.addPanesLot}
                    addPanesReglement={this.addPanesReglement}
                    addPanesQuittancement={this.addPanesQuittancement}
                    addPanesSuppressionReglement={this.addPanesSuppressionReglement}
                    syncRequeteRouter={this.syncRequete}
                    tabContrat={false}
                  />
                </div>
              )}

              {this.isOpen(panes, "27") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "27") }}>
                  <Mouvements />
                </div>
              )}

              {this.isOpen(panes, "28") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "28") }}>
                  <Locations etat="o" addPanesRequete={this.addPanesRequete} />
                </div>
              )}

              {this.isOpen(panes, "29") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "29") }}>
                  <Locations etat="r" addPanesRequete={this.addPanesRequete} />
                </div>
              )}

              {this.isOpen(panes, "30") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "30") }}>
                  <Locations etat="b" />
                </div>
              )}

              {this.isOpen(panes, "31") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "31") }}>
                  <LotsListe />
                </div>
              )}

              {this.isOpen(panes, "32") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "32") }}>
                  <Journals />
                </div>
              )}

              {this.isOpen(panes, "33") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "33") }}>
                  <RapprochementBancaire pointage={false} />
                </div>
              )}

              {/* {this.isOpen(panes, "34") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "34") }}>
                  <Migration />
                </div>
              )} */}

              {this.isOpen(panes, "35") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "35") }}>
                  <Operation edit={true} ecritureProps={ecritureProps} />
                </div>
              )}

              {this.isOpen(panes, "36") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "36") }}>
                  <SuppressionReglement annulation={true} />
                </div>
              )}

              {this.isOpen(panes, "37") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "37") }}>
                  <Immeubles immeubleProps={immeubleProps} addPanesLot={this.addPanesLot} />
                </div>
              )}

              {this.isOpen(panes, "38") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "38") }}>
                  <Pointes />
                </div>
              )}

              {this.isOpen(panes, "39") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "39") }}>
                  <ValidationBordereau />
                </div>
              )}

              {this.isOpen(panes, "40") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "40") }}>
                  <Propositions addPanesRequete={this.addPanesRequete} />
                </div>
              )}

              {this.isOpen(panes, "41") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "41") }}>
                  <PrestationsSupplementaires />
                </div>
              )}

              {this.isOpen(panes, "42") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "42") }}>
                  <Documents />
                </div>
              )}

              {this.isOpen(panes, "43") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "43") }}>
                  <Historique />
                </div>
              )}

              {this.isOpen(panes, "44") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "44") }}>
                  <Actions />
                </div>
              )}

              {this.isOpen(panes, "45") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "45") }}>
                  <EcartReglement />
                </div>
              )}

              {this.isOpen(panes, "46") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "46") }}>
                  <Requetes addPanesRequete={this.addPanesRequete} />
                </div>
              )}

              {this.isOpen(panes, "47") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "47") }}>
                  <Agents />
                </div>
              )}

              {this.isOpen(panes, "48") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "48") }}>
                  <EcartReddition />
                </div>
              )}

              {this.isOpen(panes, "49") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "49") }}>
                  <Quittancement idLocation={null} addPanesRequete={this.addPanesRequete} />
                </div>
              )}

              {this.isOpen(panes, "50") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "50") }}>
                  <TypesPaiements />
                </div>
              )}

              {this.isOpen(panes, "51") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "51") }}>
                  <RapprochementBancaire pointage={true} />
                </div>
              )}

              {this.isOpen(panes, "52") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "52") }}>
                  <Demandes addPanesRequete={this.addPanesRequete} />
                </div>
              )}

              {this.isOpen(panes, "53") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "53") }}>
                  <Menages />
                </div>
              )}

              {this.isOpen(panes, "54") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "54") }}>
                  <Quittances addPanesRequete={this.addPanesRequete} />
                </div>
              )}

              {this.isOpen(panes, "55") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "55") }}>
                  <Altimmo addPanesRequete={this.addPanesRequete} />
                </div>
              )}

              {this.isOpen(panes, "56") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "56") }}>
                  <SuppressionReglement annulation={false} />
                </div>
              )}

              {this.isOpen(panes, "57") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "57") }}>
                  <Honoraires />
                </div>
              )}

              {this.isOpen(panes, "58") && (
                <div className="adb-content" style={{ display: this.isVisible(activeKey, "58") }}>
                  <Sperone />
                </div>
              )}
            </Layout>
          </Layout>
        </div>
        <MyToast />
      </ErrorBoundary>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadParametres: (utilisateur_id) => dispatch(loadParametres(utilisateur_id)),
    loadFraisAgences: () => dispatch(loadFraisAgences()),
    loadCautions: () => dispatch(loadCautions()),
    loadSocietes: () => dispatch(loadSocietes()),
    loadEtablissements: () => dispatch(loadEtablissements()),
    loadJournals: () => dispatch(loadJournals()),
    loadRubriques: () => dispatch(loadRubriques()),
    loadEtapes: () => dispatch(loadEtapes()),
    loadGroupes: () => dispatch(loadGroupes()),
    loadProvenances: () => dispatch(loadProvenances()),
    loadRequetes: () => dispatch(loadRequetes()),
    loadRenduAs: () => dispatch(loadRenduAs()),
    loadRenduAsHistorique: () => dispatch(loadRenduAsHistorique()),
    loadParcoursClients: () => dispatch(loadParcoursClients()),
    loadCodesCouleurs: () => dispatch(loadCodesCouleurs()),
    loadQualites: () => dispatch(loadQualites()),
    loadLangues: () => dispatch(loadLangues()),
    loadTypesEmails: () => dispatch(loadTypesEmails()),
    loadTypesPaiements: () => dispatch(loadTypesPaiements()),
    loadTypesLots: () => dispatch(loadTypesLots()),
    loadEquipements: () => dispatch(loadEquipements()),
    loadChampsPersos: () => dispatch(loadChampsPersos()),
    loadChampsPersosGroupes: () => dispatch(loadChampsPersosGroupes()),
    loadPieces: () => dispatch(loadPieces()),
    loadDocuments: () => dispatch(loadDocuments()),
    loadTags: () => dispatch(loadTags()),
    loadTemplates: () => dispatch(loadTemplates()),
    loadTemplatesLangues: () => dispatch(loadTemplatesLangues()),
    loadEtapesTemplates: () => dispatch(loadEtapesTemplates()),
    loadWords: () => dispatch(loadWords()),
    initTree: (utilisateur_id) => dispatch(initTree(utilisateur_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    requetes: state.requetes.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
