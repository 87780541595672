import React, { Component } from "react";

import CustomInput from "../CustomFormItem/Input";

import Calendar from "rc-year-calendar";
import "rc-year-calendar/locales/rc-year-calendar.fr";

import * as Add2Calendar from "add2calendar";

import { Spin, Modal, Row, Col, Input, Tag } from "antd";

import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

import Constants from "../../utils/constants";

import FormContratRapide from "../FormContratRapide";

import dayjs from "dayjs";

class Planning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lot: null,
      currentEvent: null,
      dataSource: [],
      icalEvents: [],
      ical: null,
      clicks: [],
      loading: false,
      error: "",
    };
  }

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    const icalEvents = [];
    const dataSource = props.locations
      ? props.locations.filter(
          (item) => item.location_lot.lot_id === props.lot.lot_id && item.location_etat !== "a"
        )
      : [];

    dataSource.forEach((location) => {
      const calendrierBloque = location.location_calendrier_bloque;
      if (calendrierBloque || location.location_etat !== "o") {
        let start = new Date(location.startDate.getTime() + 12 * 60 * 60 * 1000);
        let end = new Date(location.endDate.getTime() + 12 * 60 * 60 * 1000);
        const icalEvent = {
          title: location.name,
          description: location.description,
          start: start,
          end: end,
          location: location.location_lot.lot_adresse.adresse_gmaps_adresse,
        };
        icalEvents.push(icalEvent);
      }
      // Check metier / compta
      if (location.location_etat === "r") {
        const quittancesFiltered = props.quittances.filter(
          (quittance) => quittance.quittance_location_id === location.location_id
        );
        let quittancesLignes = [];
        if (quittancesFiltered.length > 0) {
          quittancesFiltered.forEach((quittance) => {
            quittance.quittance_ligne.forEach((ligne) => {
              quittancesLignes.push(ligne);
            });
          });
        }
        const quittancesFilteredId = quittancesFiltered.map((quittance) => quittance.quittance_id);
        const reglementsFiltered = props.reglements?.filter((reglement) =>
          quittancesFilteredId.includes(reglement.reglement_quittance.quittance_id)
        );
        if (reglementsFiltered?.length > 0) {
          const totalQuittances = quittancesLignes.reduce(
            (a, b) => a + parseFloat(b.quittance_ligne_montant),
            0
          );
          const totalReglements = reglementsFiltered.reduce(
            (a, b) => a + parseFloat(b.reglement_montant),
            0
          );
          if (
            parseFloat(totalQuittances).toFixed(2) - parseFloat(totalReglements).toFixed(2) <=
            0
          ) {
            location.color = "#B6E2A1";
          } else {
            location.color = "#FEBE8C";
          }
        } else {
          location.color = "#FD8A8A";
          // Cas particulier plateforme
          const requete = location.location_requete;
          if (
            requete &&
            requete.requete_provenance &&
            requete.requete_provenance.provenance_plateforme === true
          ) {
            location.color = "#C991B4";
          }
        }
      }
    });
    if (icalEvents.length === 0) {
      const icalEvent = {
        title: "ANTERIEUR",
        description: "ANTERIEUR",
        start: new Date(Date.now() - 86400000),
        end: new Date(Date.now() - 86400000),
        location: "ANTERIEUR",
      };
      icalEvents.push(icalEvent);
    }
    this.setState({ dataSource, icalEvents, ical: null, lot: props.lot });
    !this.props.readOnly && this.getIcal(icalEvents, props.lot);
  };

  handleSelectEvt = (evt) => {
    evt.location_requete ? this.selectLocation(evt) : this.selectBlocage(evt);
  };

  selectLocation = (evt) => {
    const pane = {
      key: "26",
      title: "Contrats / Disponibilités",
      content: "",
    };
    this.props.addPanesRequete(pane, "26", evt.location_requete);
  };

  selectBlocage = (evt) => {
    this.setState({ currentEvent: evt });
  };

  deleteCurrentEvent = (evt) => {
    this.props
      .deleteLocation(evt.id)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  handleDayEnter(e) {
    // if (!this.props.isArchive) {
    if (e.events.length > 0) {
      var content = "";

      for (var i in e.events) {
        content +=
          '<div class="event-tooltip-content">' +
          '<div class="event-name" style="color:' +
          e.events[i].color +
          '">' +
          e.events[i].name +
          "</div>" +
          '<div class="event-details">' +
          e.events[i].description +
          "</div>" +
          "</div>";
      }

      if (this.tooltip != null) {
        this.tooltip.destroy();
        this.tooltip = null;
      }

      this.tooltip = tippy(e.element, {
        content: content,
        placement: "right",
        animation: "shift-away",
        arrow: true,
        allowHTML: true,
        animateFill: false,
      });
      this.tooltip.show();
    }
    // }
  }

  handleDayLeave() {
    // if (!this.props.isArchive) {
    if (this.tooltip) {
      this.tooltip.destroy();
      this.tooltip = null;
    }
    // }
  }

  onChangeDatePicker = (e, start) => {
    let error = "";

    // Est-ce que la range sélectionnée contient déjà une location
    if (
      this.state.dataSource.find(
        (item) =>
          this.state.currentEvent.id !== item.location_id &&
          (dayjs(item.location_date_debut).isBetween(
            new Date(e),
            this.state.currentEvent.endDate
          ) ||
            dayjs(item.location_date_fin).isBetween(new Date(e), this.state.currentEvent.endDate) ||
            dayjs(item.location_date_debut).isBetween(
              this.state.currentEvent.startDate,
              new Date(e)
            ) ||
            dayjs(item.location_date_fin).isBetween(this.state.currentEvent.startDate, new Date(e)))
      ) !== undefined
    )
      error = "Location existante";

    this.setState({
      error,
      currentEvent: {
        ...this.state.currentEvent,
        startDate: start ? new Date(e) : this.state.currentEvent.startDate,
        endDate: !start ? new Date(e) : this.state.currentEvent.endDate,
      },
    });
  };

  afficher = (location) => {
    if (location.location_requete) this.props.handleClickRequete(location.location_requete);
  };

  doubleClick = (date) => {
    this.afficher(date.events[0]);
  };

  clickHandler = (date) => {
    let clicks = this.state.clicks;
    let timeout;
    clicks.push(new Date().getTime());
    window.clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      if (clicks.length > 1 && clicks[clicks.length - 1] - clicks[clicks.length - 2] < 250) {
        this.doubleClick(date);
      }
    }, 250);
    this.setState({ click: [] });
  };

  getIcal = (icalEvents, lot) => {
    const url = `${Constants.mode}://${Constants.domain}/api/ical/${lot.lot_reference}.ics`;
    const iCal = new Add2Calendar(icalEvents);
    const file = new Blob(
      [decodeURIComponent(iCal.getICalUrl()).replace("data:text/calendar;charset=utf8,", "")],
      {
        type: "text/calendar;charset=utf-8",
      }
    );
    this.props.uploadICal(file, lot);
    // navigator.clipboard.writeText(url).then(function() {
    //   toast.success("Lien ICal copié dans le presse papier !", { containerId: "A" });
    // });
    this.setState({ ical: url });
  };

  closeModal = () => {
    this.setState({ currentEvent: null });
  };

  render() {
    const { lot, loading, dataSource, ical, currentEvent } = this.state;
    const {
      readOnly,
      lots,
      locataires,
      qualites,
      utilisateur,
      parametre,
      groupes,
      provenances,
      year,
    } = this.props;

    return (
      <Spin spinning={loading} size="large" tip="Chargement...">
        <Row gutter={12} style={{ padding: 5 }}>
          {ical && (
            <Col span={8}>
              <Input value={ical} />
            </Col>
          )}
          <Col span={2}>
            <CustomInput
              label={`Nuités ${year}`}
              inputName="nbNuites"
              formItemName="nbNuites"
              type="number"
              objectValue={"1"}
              readOnly={true}
            />
          </Col>
        </Row>

        <Row>
          <Calendar
            onDayClick={!readOnly && this.clickHandler}
            dataSource={dataSource}
            language="fr"
            // eslint-disable-next-line react/style-prop-object
            style={"background"}
            allowOverlap={false}
            alwaysHalfDay={true}
            enableContextMenu={true}
            enableRangeSelection={true}
            onDayEnter={!readOnly && this.handleDayEnter}
            onDayLeave={!readOnly && this.handleDayLeave}
            onYearChanged={this.props.handleYearChanged}
            contextMenuItems={
              !readOnly
                ? [
                    // {
                    //   text: "Afficher requête",
                    //   click: (evt) => this.afficher(evt),
                    // },
                    {
                      text: "Consulter",
                      click: (evt) => !readOnly && this.handleSelectEvt(evt),
                    },
                    // {
                    //   text: "Supprimer",
                    //   click: (evt) => !readOnly && this.handleSelectEvt(evt),
                    // },
                  ]
                : null
            }
            onRangeSelected={(e) =>
              this.setState({
                currentEvent: { startDate: e.startDate, endDate: e.endDate },
              })
            }
          />

          {!readOnly && (
            <Modal
              width={800}
              open={currentEvent}
              className={
                currentEvent &&
                dayjs(currentEvent.startDate).isBefore(dayjs(), "day") &&
                "dates-warning-bg"
              }
              onCancel={() => this.setState({ error: "", currentEvent: null })}
              footer={null}
            >
              <FormContratRapide
                utilisateur={utilisateur}
                parametre={parametre}
                groupes={groupes}
                provenances={provenances}
                currentEvent={currentEvent}
                lots={lots}
                lot={lot}
                locataires={locataires}
                qualites={qualites}
                parcoursClients={this.props.parcoursClients}
                saveRenduA={this.props.saveRenduA}
                nuites={this.props.nuites}
                saveLocataire={this.props.saveLocataire}
                saveLocation={this.props.saveLocation}
                updateLocation={this.props.updateLocation}
                deleteLocation={this.props.deleteLocation}
                saveDossier={this.props.saveDossier}
                updateDossier={this.props.updateDossier}
                saveRequete={this.props.saveRequete}
                saveRequeteLot={this.props.saveRequeteLot}
                addPanesRequete={this.props.addPanesRequete}
                closeModal={this.closeModal}
                saveEmail={this.props.saveEmail}
              />
            </Modal>
          )}
        </Row>
        <Row>
          <Tag color="#FFE15D">Option</Tag>
          <Tag color="#6D8299">Blocage proprietaire</Tag>
          <Tag color="#D1D1D1">Non disponible</Tag>
          <Tag color="#C991B4">Plateforme</Tag>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Tag color="#B6E2A1">Réservé et soldé</Tag>
          <Tag color="#FEBE8C">Réservé avec acompte</Tag>
          <Tag color="#FD8A8A">Réservé sans acompte</Tag>
        </Row>
      </Spin>
    );
  }
}

export default Planning;
